import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { IconModule } from '@ds-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DraftSuccessDialogComponent } from './draft-success-dialog.component';

@NgModule({
  declarations: [DraftSuccessDialogComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, IconModule],
  exports: [DraftSuccessDialogComponent],
})
export class DraftSuccessDialogModule {}
