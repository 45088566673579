import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { DsFormFieldExtComponent } from '@ds-form';
import { getUniqueId } from '@ds-helpers';
import { SidebarService } from '@ds-services';
import { DsCalendar, DsCalendarDay, DsOrderSubmitStatus } from '@ds-types';
import { OrderFormService } from '@ds-ui/order-form/services';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  getWeek,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isToday,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { chunk, uniq } from 'lodash-es';

@Component({
  selector: 'ds-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  public activeSidebar?: DsCalendar;

  public readonly weekdays = Array.from({ length: 7 }, (_, i) => i);

  public activeMonth = new Date();
  public selectedDate: Date | null = null;

  public status: DsOrderSubmitStatus = 'pending';

  private subscriptions = new Subscription();

  public uid = getUniqueId();

  @Input() mode: 'date' | 'week' = 'date';
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input({ required: true }) buttonLabel!: string;
  @Input({ required: true }) sidebarHeadline!: string;

  constructor(
    private sidebarService: SidebarService,
    private cdRef: ChangeDetectorRef,
    public orderFormService: OrderFormService
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenForActiveSidebar();
    this.listenForOrderSubmitStatusChanges();

    this.selectedDate =
      this.formControl.value instanceof Date ? this.formControl.value : null;

    this.formControl.valueChanges.subscribe((value) => {
      this.selectedDate = value instanceof Date ? value : null;
    });

    this.activeMonth =
      this.formControl.value instanceof Date
        ? this.formControl.value
        : new Date();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onOpenClick(): void {
    this.sidebarService.setActiveSidebar({
      htmlId: this.uid,
      type: 'calendar',
      date:
        this.formControl.value instanceof Date
          ? this.formControl.value
          : undefined,
      headlineLabel: 'Calendar',
    });
  }

  private listenForActiveSidebar(): void {
    this.subscriptions.add(
      this.sidebarService.activeSidebar$.subscribe((activeSidebar) => {
        this.activeSidebar =
          activeSidebar?.type === 'calendar' ? activeSidebar : undefined;
        this.cdRef.detectChanges();
      })
    );
  }

  public goToPrevMonth(): void {
    this.activeMonth = subMonths(this.activeMonth, 1);
  }

  public goToNextMonth(): void {
    this.activeMonth = addMonths(this.activeMonth, 1);
  }

  public goToCurrentMonth(): void {
    this.activeMonth = new Date();
  }

  public getWeeksAndDaysOfActiveMonth(): DsCalendarDay[][] {
    const calendarDays: DsCalendarDay[] = [];
    const dayStart = startOfWeek(startOfMonth(this.activeMonth));
    const dayEnd = addDays(endOfWeek(endOfMonth(this.activeMonth)), 1);

    let day = dayStart;

    while (!isSameDay(day, dayEnd)) {
      calendarDays.push({
        date: day,
      });
      day = addDays(day, 1);
    }

    return chunk(calendarDays, 7);
  }

  public getDayClasses(day: DsCalendarDay): string[] {
    const classes: string[] = [];
    let textClasses: string[] = [];

    if (!isSameMonth(day.date, this.activeMonth)) {
      textClasses.push('text-gray-400');
    } else {
      textClasses.push('text-black');
    }

    if (this.mode === 'week') {
      textClasses.push('group-hover:bg-gray-200');
    }

    if (isToday(day.date)) {
      classes.push('border', 'border-black');
    }

    let isSelected = false;
    if (this.mode === 'date') {
      if (this.selectedDate && isSameDay(day.date, this.selectedDate)) {
        isSelected = true;
      }
    } else if (this.mode === 'week') {
      if (this.selectedDate && isSameWeek(day.date, this.selectedDate)) {
        isSelected = true;
      }
    }

    if (this.isDateDisabled(day.date)) {
      classes.push('cursor-not-allowed', 'text-gray-400', 'line-through');
    } else {
      textClasses.push('hover:bg-gray-200');
    }

    if (isSelected) {
      textClasses = ['bg-green-500', 'text-white'];
    }

    return classes.concat(textClasses);
  }

  public isDateDisabled(date: Date): boolean {
    if (isWeekend(date)) {
      return true;
    }

    const minDate = this.minDate
      ? this.mode === 'week'
        ? startOfWeek(this.minDate)
        : this.minDate
      : undefined;
    const maxDate = this.maxDate
      ? this.mode === 'week'
        ? endOfWeek(this.maxDate)
        : this.maxDate
      : undefined;

    const minDateOkay = !minDate || date >= minDate;
    const maxDateOkay = !maxDate || date <= maxDate;

    return !minDateOkay || !maxDateOkay;
  }

  private listenForOrderSubmitStatusChanges(): void {
    this.subscriptions.add(
      this.orderFormService.orderSubmitStatusChange$.subscribe((status) => {
        this.status = status;
        if (this.status === 'pending') {
          this.sidebarService.close();
        }
        this.cdRef.detectChanges();
      })
    );
  }

  public onDayClick(day: DsCalendarDay): void {
    if (this.isDateDisabled(day.date)) {
      return;
    }

    if (this.mode === 'week') {
      // patch friday of this week
      this.selectedDate = addDays(startOfWeek(day.date), 4);
    } else {
      this.selectedDate = day.date;
    }
  }

  public trackByWeekday(index: number): number {
    return index;
  }

  public trackByDay(index: number, day: DsCalendarDay): string {
    return day.date.toISOString();
  }

  public trackByWeek(index: number): string {
    return index.toString();
  }

  public getWeekNrs(): string[] {
    const days = this.getWeeksAndDaysOfActiveMonth().flat();
    const weeks = uniq(days.map((day) => getWeek(day.date)));

    return weeks.map((weekNr) => weekNr.toString());
  }

  public async onSubmitClick(): Promise<void> {
    if (!this.selectedDate) {
      return;
    }

    this.formControl.patchValue(this.selectedDate);

    if (this.orderFormService.isViewMode) {
      this.orderFormService.saveCurrentStep();
    }

    this.sidebarService.close();
  }

  public onCancelClick(): void {
    this.sidebarService.close();
    this.selectedDate =
      this.formControl.value instanceof Date ? this.formControl.value : null;
  }
}
