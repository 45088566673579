import { Pipe, PipeTransform } from '@angular/core';
import { convertBytesToMegabytes } from '@ds-helpers';

@Pipe({
  name: 'dsFileSize',
})
export class DsFileSizePipe implements PipeTransform {
  public transform(size: number): string {
    return `${convertBytesToMegabytes(size).toFixed(2)} MB`;
  }
}
