import { Injectable } from '@angular/core';
import { AuthenticationService } from '@ds-services';
import { DsUser, DsUserToken } from '@ds-types';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private authenticationService: AuthenticationService) {}

  private async getDecodedToken(): Promise<DsUserToken> {
    const token: string = await this.authenticationService.getAuthToken();
    return jwtDecode<DsUserToken>(token);
  }

  public async getUser(): Promise<DsUser> {
    const token = await this.getDecodedToken();
    const userType = token.groups?.includes(
      '/Intern/Strasser/ECP_User/ABAS User'
    )
      ? 'intern'
      : 'extern';

    return {
      customerName: token.name,
      sales_RefNr: token['ID Abas Kundenkontakt'],
      customerRefNr: token['ID Abas Kunde'],
      firstname: token.given_name,
      lastname: token.family_name,
      type: userType,
      username: token.username,
      warehouseAddress: token.warehouse_address,
      warehouseZip: token.warehouse_zip,
      warehouseCity: token.warehouse_city,
      warehouseCountry: token.warehouse_country,
      warehouseEmailRetail: token.warehouse_email_retail,
      warehouseMobileRetail: token.warehouse_mobile_retail,
      warehouseTelRetail: token.warehouse_tel_retail,
      isPriceAllowed: token.preis,
      email: token.email,
      roles: token.resource_access['DigiSTONE-frontend'].roles,
    };
  }
}
