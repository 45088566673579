export function convertToFormData(data: object): FormData {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
}

export function convertBytesToMegabytes(bytes: number): number {
  return bytes / 1000000; // bytes to megabytes
}
