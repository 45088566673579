import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DsAutocompleteOption,
  DsFormFieldAutocompleteCustomer,
  DsFormFieldExtComponent,
} from '@ds-form';
import { DcuplService } from '@ds-services';
import { OrderFormService } from '@ds-ui/order-form/services';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'ds-autocomplete-customer',
  templateUrl: './autocomplete-customer.component.html',
  styleUrls: ['./autocomplete-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteCustomerComponent<K extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  public options: DsAutocompleteOption[] = [];
  private customerRefNrField$ = new BehaviorSubject<string>('');
  private searchTerm$ = new BehaviorSubject('');
  @Input({ required: true }) formField!: DsFormFieldAutocompleteCustomer<K>;

  private subscriptions = new Subscription();

  public setSearchTerm$ = new Subject<string>();

  constructor(
    private dcuplService: DcuplService,
    private orderFormService: OrderFormService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private init(): void {
    this.listenForOptions();
    this.listenForFormFieldChanges();
  }

  private listenForFormFieldChanges(): void {
    this.subscriptions.add(
      this.orderFormService.customerRefNrField.valueChanges.subscribe(
        (customerRefNr) => {
          this.customerRefNrField$.next(customerRefNr);
        }
      )
    );
    this.customerRefNrField$.next(
      this.orderFormService.customerRefNrField.value
    );
  }

  private listenForOptions(): void {
    this.subscriptions.add(
      combineLatest([this.customerRefNrField$, this.searchTerm$]).subscribe(
        ([customerRefNr, searchTerm]) => {
          this.loadDataByCustomerRefNumberAndSearchTerm(
            customerRefNr,
            searchTerm
          );
        }
      )
    );

    this.subscriptions.add(
      this.customerRefNrField$.subscribe(() => {
        this.setSearchTerm$.next('');
      })
    );
  }

  private async loadDataByCustomerRefNumberAndSearchTerm(
    customerRefNumber: string,
    searchTerm: string
  ): Promise<void> {
    const customer =
      await this.dcuplService.getCustomerByNumber(customerRefNumber);

    const customerContacts = customer
      ? await this.dcuplService.getCustomerContactsByCustomerNumberAndName(
          customer.number,
          searchTerm
        )
      : [];

    this.options = customerContacts.map((customer) => ({
      key: customer.id,
      label: customer.name,
      sublabel: customer.number,
    }));

    this.cdRef.detectChanges();
  }

  public onSearchTermChanged(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
  }
}
