import { last } from 'lodash-es';

export function getFilePathByFullPath(fullPath: string): string {
  const splittedPath = fullPath.split('/');
  splittedPath.shift();
  return `/${splittedPath.join('/')}`;
}

export function getFileNameByFullPath(fullPath: string): string | undefined {
  const splittedPath = fullPath.split('/');
  return last(splittedPath);
}
