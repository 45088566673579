import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@ds-helpers';
import { DS_DATE_FORMAT } from '@ds-types';
import { addDays, startOfWeek } from 'date-fns';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'dsDate',
})
export class DsDatePipe implements PipeTransform {
  public transform(
    date: Date | undefined | null | number,
    format?: keyof typeof DS_DATE_FORMAT
  ): string | undefined {
    if (date === undefined || date === null) {
      return undefined;
    }

    if (typeof date === 'number') {
      date = addDays(startOfWeek(new Date()), date);
    }

    return formatDate(
      date,
      format ? DS_DATE_FORMAT[format] : DS_DATE_FORMAT.DATE
    );
  }
}
