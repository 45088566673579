import { DsBoardType, DsFileOnError, DsOrderType } from '@ds-types';

// Sizes

export enum DS_DIALOG_SIZE {
  M = '46rem',
}

export enum DS_DIALOG_MAX_SIZE {
  M = '94vw',
}

// Form Cancel Dialog

export type DsFormCancelDialogInput = null;

export type DsFormCancelDialogOutput = {
  action: 'save-draft' | 'cancel-without-save';
};
// Form Save Dialog

export type DsFormSaveDialogInput = null;

export type DsFormSaveDialogOutput = {
  action: 'save' | 'cancel';
};

// Draft Success Dialog

export type DsDraftSuccessDialogInput = {
  isOverviewButtonVisible: boolean;
};

export type DsDraftSuccessDialogOutput = {
  action: 'create-new-order' | 'to-overview';
};

// Material Apply Dialog

export type DsMaterialApplyDialogInput = {
  changedBoardType: DsBoardType;
  toApplyBoardTypes: DsBoardType[];
};

export type DsMaterialApplyDialogOutput = {
  action: 'apply' | 'cancel';
};

// File Error Dialog

type DsFileErrorDialogInputFileLimitReached = {
  type: 'fileLimitReached';
};

type DsFileErrorDialogInputFiles = {
  type: 'files';
  files: DsFileOnError[];
};

export type DsFileErrorDialogInput =
  | DsFileErrorDialogInputFileLimitReached
  | DsFileErrorDialogInputFiles;

// Error Dialog

export type DsErrorDialogInput = {
  title: string;
  text: string;
};

export type DsOrderDeleteDialogInput = null;
export type DsOrderDeleteDialogOutput = {
  status: 'delete' | 'cancel';
};

export type DsInfoDialogInput = {
  headlineLabel: string;
  contentLabel: string;
};

export type DsContactFormDialogInput = {
  order_number: string;
};

export type DsContactSuccessDialogInput = {
  headlineLabel: string;
  contentLabel: string;
  buttonLabel: string;
};

export type DsFormDeactivateDialogInput = {
  orderType: DsOrderType;
};
export type DsFormDeactivateDialogOutput = {
  action: 'save' | 'cancel' | 'discard';
};
