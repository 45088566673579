import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DS_API_ENDPOINTS,
  DsApiWarehouseAddressResponse,
  DsUser,
} from '@ds-types';
import { OrderFormService } from '@ds-ui/order-form/services';
import { ApiService } from '@ds-services';

@Injectable({
  providedIn: 'root',
})
export class WarehouseAddressService {
  public isWarehouseAddressEditableSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isWarehouseAddressEditableSubject$: Observable<boolean> =
    this.isWarehouseAddressEditableSubject.asObservable();
  private customerRefNr: string = '';
  public warehouseAddresses: DsApiWarehouseAddressResponse[] | undefined;

  constructor(
    private orderFormService: OrderFormService,
    private apiService: ApiService
  ) {}

  public async loadWarehouseAddresses(user: DsUser): Promise<void> {
    try {
      if (user) {
        this.customerRefNr =
          user.type === 'intern'
            ? this.orderFormService.customerRefNrField.value
            : user.customerRefNr;
        const endpoint = DS_API_ENDPOINTS.warehouseAddresses.replace(
          '{customerId}',
          this.customerRefNr
        );
        this.warehouseAddresses = await this.apiService.get<
          DsApiWarehouseAddressResponse[]
        >(endpoint as DS_API_ENDPOINTS);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
