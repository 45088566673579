import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DsTooltipColorBg } from '@ds-types';
import { createPopper } from '@popperjs/core';
import { Instance, Options } from '@popperjs/core/lib/popper-lite';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ds-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() placement?: Options['placement'];
  @Input() classes = '';
  @Input() bgColor: DsTooltipColorBg = 'dark';
  @Input() forceOpen$?: Observable<boolean | undefined>;
  @Input() shouldHideOnTooltipHover = true;

  @ViewChild('tooltip', { static: true })
  private readonly tooltipRef?: ElementRef<HTMLElement>;
  @ViewChild('target', { static: true })
  private readonly targetRef?: ElementRef<HTMLElement>;
  @ViewChild('targetAll', { static: true })
  private readonly targetAllRef?: ElementRef<HTMLElement>;

  private popperInstance?: Instance;
  private isInitialized = false;
  private subscriptions = new Subscription();

  constructor(private zone: NgZone, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.listenForForceOpen();
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.init();
    });
  }

  private listenForForceOpen(): void {
    this.subscriptions.add(
      this.forceOpen$?.subscribe((shouldOpen) => {
        if (typeof shouldOpen !== 'undefined') {
          if (shouldOpen === true) {
            setTimeout(() => {
              this.show();

              this.popperInstance?.update();
            }, 0);
          }
          if (shouldOpen === false) {
            this.hide();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.popperInstance?.destroy();
  }

  private init(): void {
    if (!this.tooltipRef || !this.targetRef) {
      console.warn('tooltipRef or targetRef undefined');
      return;
    }

    this.popperInstance = createPopper(
      this.targetRef.nativeElement,
      this.tooltipRef.nativeElement,
      {
        placement: this.placement ?? 'auto',
        strategy: 'fixed',
      }
    );
    this.addListeners();
    this.isInitialized = true;
    this.cdRef.detectChanges();
  }

  private addListeners(): void {
    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    const eventElement = this.shouldHideOnTooltipHover
      ? this.targetRef?.nativeElement
      : this.targetAllRef?.nativeElement;

    if (!eventElement) {
      return;
    }

    showEvents.forEach((event) => {
      eventElement.addEventListener(event, () => {
        this.show();
      });
    });

    hideEvents.forEach((event) => {
      eventElement.addEventListener(event, () => {
        this.hide();
      });
    });
  }

  private show(): void {
    this.tooltipRef?.nativeElement.setAttribute('data-show', '');
    this.setEventListeners(true);
    this.popperInstance?.update();
  }

  private hide(): void {
    this.tooltipRef?.nativeElement.removeAttribute('data-show');
    this.setEventListeners(false);
  }

  private setEventListeners(enabled: boolean): void {
    this.popperInstance?.setOptions((options) => ({
      ...options,
      modifiers: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(options.modifiers as any),
        { name: 'eventListeners', enabled },
      ],
    }));
  }

  public getWrapperClasses(): string[] {
    const classes: string[] = [];

    classes.push('popper--bg-' + this.bgColor, this.classes);

    if (!this.isInitialized) {
      classes.push('absolute', 'invisible');
    }

    return classes;
  }
}
