import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DsOrder, DsOrderOrDraftOverview } from '@ds-types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private translateService: TranslateService
  ) {}

  public getPriceText(
    order: DsOrder | DsOrderOrDraftOverview,
    isPriceAllowed: boolean
  ): string {
    if (isPriceAllowed && order.sales_price) {
      const formattedPrice = this.currencyPipe.transform(
        order.sales_price,
        'EUR'
      );
      return formattedPrice ?? '';
    }

    if (order.type === 'order') {
      return this.translateService.instant('orders.table.price-see-order');
    }

    return this.translateService.instant('orders.table.price-see-offer');
  }
}
