import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DsFormFieldExtComponent,
  DsFormFieldText,
  DsFormFieldTextType,
  DsInputValue,
  getFloatAsFormattedString,
} from '@ds-form';
import { DsTheme } from '@ds-types';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'ds-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent<K extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() formField?: DsFormFieldText<K>;
  @Input() isDisabled = false;
  @Input() theme: DsTheme = 'on-white';
  @Input() placeholder?: string;
  private subscriptions = new Subscription();

  public type: DsFormFieldTextType = 'text';
  public outputType: DsFormFieldTextType = 'text';

  public value?: DsInputValue;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.subscriptions.add(
        this.formControl.valueChanges.subscribe((value) => {
          if (value && this.type === 'number') {
            this.value = getFloatAsFormattedString(value);
          } else {
            this.value = value;
          }
          this.cdRef.detectChanges();
        })
      );
    }

    if (this.formField?.textType) {
      this.type = this.formField.textType;
      if (this.formField.textType === 'number') {
        this.outputType = 'text';
      } else {
        this.outputType = this.formField.textType;
      }
    }

    const inputValue = this.formControl.value;
    if (inputValue && this.type === 'number') {
      this.value = getFloatAsFormattedString(inputValue);
    } else {
      this.value = inputValue;
    }

    this.subscriptions.add(
      this.formControl.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onChange(value: DsInputValue): void {
    let newValue: DsInputValue = value ?? '';

    if (this.type === 'number') {
      if (newValue !== '-') {
        newValue =
          typeof newValue === 'string' ? newValue.replace(/,/g, '.') : newValue;
        newValue = parseFloat(newValue.toString());
        if (isNaN(newValue)) {
          newValue = '';
        }
      }
    }

    if (this.type === 'date') {
      if (newValue !== '') {
        newValue = new Date(newValue);
        if (isNaN(newValue.getTime())) {
          newValue = undefined;
        }
      }
    }

    this.formControl.patchValue(newValue);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  public onBlur(): void {
    this.formControl.markAsTouched();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    if (this.theme === 'on-gray') {
      classes.push('on-gray');
    }
    return classes;
  }

  public getValue(): DsInputValue | undefined {
    if (this.type === 'date') {
      if (this.value) {
        // convert date to format y-MM-dd
        return new Date(this.value).toISOString().split('T')[0];
      }
    }

    return this.value;
  }
}
