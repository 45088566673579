import { Component, HostBinding, Input } from '@angular/core';
import {
  DsButtonIconPos,
  DsButtonRoundedStatus,
  DsButtonSize,
  DsButtonVariant,
  DsIcon,
  DsIconSize,
} from '@ds-types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ds-button], a[ds-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() variant: DsButtonVariant = 'flat';
  @Input() onDark = false;
  @Input() icon?: DsIcon;
  @Input() rounded?: boolean;
  @Input() roundedLabel?: string;
  @Input() roundedStatus?: DsButtonRoundedStatus;
  @Input() iconPos?: DsButtonIconPos = 'left';
  @Input() size: DsButtonSize = 'M';
  @Input() isLoading = false;
  @Input() showAsNotDisabled = false;

  @HostBinding() @Input() isDisabled = false;
  @HostBinding('class.is-elastic') @Input() elastic = false;
  @HostBinding('class.is-full') @Input() full = false;
  @HostBinding('attr.disabled') get disabled(): boolean | null {
    return this.isDisabled || null;
  }

  public getIcon(): DsIcon | undefined {
    if (this.icon) {
      return this.icon;
    }

    if (this.rounded && this.roundedStatus === 'success') {
      return 'check-24';
    }

    return undefined;
  }

  public isIconContainerVisible(): boolean {
    return this.getIcon() !== undefined || this.isLoading;
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      if (this.showAsNotDisabled) {
        classes.push('cursor-default', 'opacity-50');
      } else {
        classes.push('cursor-not-allowed');
      }
    } else {
      classes.push('cursor-pointer');
    }

    if (this.elastic) {
      classes.push('flex', 'sm:inline-flex');
    } else if (this.full) {
      classes.push('w-full');
    } else {
      classes.push('inline-flex');
    }

    return classes;
  }

  public getButtonClasses(): string[] {
    const classes = ['transition-all', 'duration-200', 'ease-in-out'];

    if (this.elastic) {
      classes.push('flex', 'w-full', 'sm:inline-flex');
      if (this.variant === 'link') {
        classes.push('py-2.5', 'sm:py-0');
      }
    } else if (this.full) {
      classes.push('flex');
    } else {
      classes.push('inline-flex');
    }

    if (this.variant !== 'link') {
      classes.push('border', 'uppercase');

      if (this.onDark) {
        classes.push('border-white');
      } else {
        classes.push('border-gray-800');
      }
    }

    if (this.variant === 'link') {
      classes.push('border-b', 'border-transparent');
      if (!this.isDisabled) {
        if (this.elastic) {
          classes.push('sm:group-hover:border-black');
        } else {
          classes.push('group-hover:border-black');
        }
      }
    }

    if (
      !this.rounded &&
      (this.variant === 'flat' || this.variant === 'outline')
    ) {
      classes.push('px-6', 'py-2.5', 'leading-none');
    }

    if (this.rounded && this.roundedStatus === 'success') {
      classes.push('bg-green-500', 'text-white', 'border-green-500');
    } else if (this.rounded && this.roundedStatus === 'active') {
      classes.push('bg-gray-800', 'text-white', 'border-gray-800');
    } else {
      switch (this.variant) {
        case 'flat':
          if (this.onDark) {
            classes.push('bg-white', 'text-gray-800');
            if (!this.isDisabled) {
              classes.push(
                'group-hover:bg-gray-200',
                'group-hover:text-gray-900'
              );
            }
          } else {
            classes.push('bg-gray-800', 'text-white');
            if (!this.isDisabled) {
              classes.push(
                'group-hover:bg-gray-900',
                'group-hover:border-gray-900',
                'group-hover:shadow-base'
              );
            }
          }

          break;
        case 'outline':
          if (this.onDark) {
            classes.push('bg-transparent', 'text-white');
            if (!this.isDisabled) {
              classes.push('group-hover:bg-gray-800/[.6]');
            }
          } else {
            classes.push('bg-transparent', 'text-gray-800');
            if (!this.isDisabled) {
              classes.push(
                'group-hover:bg-gray-200/[.6]',
                'group-hover:border-gray-900',
                'group-hover:text-gray-900'
              );
            }
          }

          break;

        case 'link':
          if (this.onDark) {
            classes.push('text-white');
            if (!this.isDisabled) {
              classes.push('group-hover:border-white');
            }
          }
      }

      if (this.variant !== 'link' && this.icon === 'chevron-left-16') {
        classes.push('pl-4', 'pr-6');
      }

      if (this.variant !== 'link' && this.icon === 'chevron-right-16') {
        classes.push('pr-4', 'pl-6');
      }
    }

    if (this.rounded) {
      classes.push('rounded-full', 'p-1');
    }

    return classes;
  }

  public getContentClasses(): string[] {
    const classes = [];

    if (this.iconPos === 'right') {
      classes.push('flex-row-reverse');
    }
    if (this.rounded) {
      classes.push('w-8', 'h-8', 'flex', 'items-center', 'justify-center');
    }

    if (this.size === 'S') {
      classes.push('text-sm');
    }

    return classes;
  }

  public getIconClasses(): string[] {
    const classes = [];

    if (!this.rounded) {
      if (this.variant === 'link') {
        if (this.iconPos === 'right') {
          classes.push('pl-1.5');
        } else {
          classes.push('pr-1.5');
        }
      } else {
        if (this.iconPos === 'right') {
          if (this.icon === 'chevron-right-16') {
            classes.push('pl-1.5');
          } else {
            classes.push('pl-2.5');
          }
        } else {
          if (this.icon === 'chevron-left-16') {
            classes.push('pr-1.5');
          } else {
            classes.push('pr-2.5');
          }
        }
      }
    }

    return classes;
  }

  public getIconSize(): DsIconSize {
    return this.rounded ? 24 : 16;
  }

  public getRoundedLabelClasses(): string[] {
    const classes = [
      'uppercase',
      'transition',
      'duration-200',
      'ease-in-out',
      'text-left',
    ];

    if (this.onDark) {
      classes.push('text-white');
    } else {
      classes.push('text-gray-800');

      if (!this.isDisabled) {
        classes.push('group-hover:text-black');
      }
    }

    return classes;
  }

  public getTextClasses(): string[] {
    const classes = [];
    if (this.variant !== 'link') {
      classes.push('mt-0.5');
    }

    if (this.rounded) {
      classes.push('flex', 'items-center', 'justify-center');
    }

    return classes;
  }
}
