import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private isFileUploading$$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  get isFileUploading$(): Observable<boolean> {
    return this.isFileUploading$$.asObservable();
  }

  public setFileUploadingState(value: boolean): void {
    this.isFileUploading$$.next(value);
  }
}
