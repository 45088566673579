export enum DS_DATE_FORMAT {
  DAY = 'd',
  DATESHORT = 'dd. MMM',
  DATE = 'dd. MMM yyyy',
  MONTH_YEAR = 'MMMM yyyy',
  WEEK = 'w',
  WEEKDAY_SHORT = 'EEEEEE',
  WEEKYEAR = 'w / yyyy',
  WEEKSTARTEND = 'EEEEsEE',
}

export type DsDateApi = string & { _brand: 'date_api' };
