<div class="relative z-30">
  <div class="absolute top-0 right-0 mt-8 mr-8">
    <button type="button" (click)="onCloseClick()">
      <ds-icon name="close-24" [size]="24"></ds-icon>
    </button>
  </div>
</div>
<div>
  <div class="relative p-8 flex flex-col items-center space-y-4">
    <div class="text-green-500">
      <ds-icon [size]="56" name="success-56"></ds-icon>
    </div>

    <p class="typo-h4">{{ 'order-form.draft-success-dialog.title' | translate }}</p>

    <p class="text-gray-800" [innerHTML]="getDraftSuccessDialogText()"></p>

    <button ds-button type="button" variant="flat" (click)="onCreateNewOrderClick()" [elastic]="true">{{ 'order-form.draft-success-dialog.create-new-order' | translate }}</button>

    <button ds-button type="button" variant="link" (click)="onContinueClick()" [elastic]="true">{{ 'order-form.draft-success-dialog.continue' | translate }}</button>
  </div>
</div>
