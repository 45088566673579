import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DsFormDeactivateDialogInput,
  DsFormDeactivateDialogOutput,
} from '@ds-types';
import { OrderFormService } from '@ds-ui/order-form/services';

@Component({
  selector: 'ds-form-deactivate-dialog',
  templateUrl: './form-deactivate-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDeactivateDialogComponent implements OnInit {
  public status: 'saving' | 'pending' = 'pending';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsFormDeactivateDialogInput,
    private dialogRef: MatDialogRef<
      DsFormDeactivateDialogInput,
      DsFormDeactivateDialogOutput
    >,
    private orderFormService: OrderFormService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  public onDiscardClick(): void {
    this.orderFormService.resetOverall();
    this.dialogRef.close({ action: 'discard' });
  }

  public onCancelClick(): void {
    this.dialogRef.close({ action: 'cancel' });
  }

  public async onSaveClick(): Promise<void> {
    this.status = 'saving';
    this.cdRef.detectChanges();

    await this.orderFormService.saveOrder();

    this.status = 'pending';

    this.orderFormService.resetOverall();
    this.cdRef.detectChanges();

    this.dialogRef.close({ action: 'save' });
  }
}
