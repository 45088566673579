import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { ButtonModule } from '@ds-ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@ds-ui/icon';

@NgModule({
  imports: [CommonModule, ButtonModule, TranslateModule, IconModule],
  exports: [SidebarComponent],
  declarations: [SidebarComponent],
})
export class SidebarModule {}
