import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutocompleteModule } from '@ds-ui/autocomplete/autocomplete.module';
import { AutocompleteCustomerComponent } from './autocomplete-customer.component';

@NgModule({
  declarations: [AutocompleteCustomerComponent],
  imports: [CommonModule, AutocompleteModule],
  exports: [AutocompleteCustomerComponent],
})
export class AutocompleteCustomerModule {}
