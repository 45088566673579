import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DsAutocompleteOption, DsFormFieldExtComponent } from '@ds-form';
import { getUniqueId } from '@ds-helpers';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  Observable,
  Subscription,
} from 'rxjs';
import { AutocompleteService } from '@ds-services';

@Component({
  selector: 'ds-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;

  public isListboxVisible = false;
  public selectedOption: DsAutocompleteOption | null = null;

  @Input({ required: true }) options!: DsAutocompleteOption[];
  @Input() setSearchTerm$?: Observable<string>;
  public visibleOptions: DsAutocompleteOption[] = [];

  @Output() searchTermChanged = new EventEmitter<string>();
  @Output() selected = new EventEmitter<DsAutocompleteOption | null>();

  public inputValue = '';
  private subscriptions = new Subscription();
  private options$ = new BehaviorSubject<DsAutocompleteOption[]>([]);
  private optionsInitialized = false;

  private autocompleteId = getUniqueId();

  constructor(
    private cdRef: ChangeDetectorRef,
    private autocompleteService: AutocompleteService
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenForClickOutside();
    this.listenForKeyboard();
    this.listenForForm();
    this.listenForActiveAutocomplete();
    this.listenForOptions();
    this.listenForSetSearchTerm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.options$.next(this.options);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForActiveAutocomplete(): void {
    this.subscriptions.add(
      this.autocompleteService.activeAutocomplete$
        .pipe(distinctUntilChanged())
        .subscribe((id) => {
          this.isListboxVisible = id === this.autocompleteId;
          this.cdRef.detectChanges();
        })
    );
  }

  private listenForSetSearchTerm(): void {
    this.subscriptions.add(
      this.setSearchTerm$?.subscribe((searchTerm) => {
        this.inputValue = searchTerm;
        this.searchTermChanged.emit(searchTerm);
        this.cdRef.detectChanges();
      })
    );
  }

  private listenForOptions(): void {
    this.subscriptions.add(
      this.options$.subscribe((options) => {
        const newOptions = options.slice(0, 50);

        if (
          newOptions.length !== this.visibleOptions.length ||
          newOptions.some(
            (option, i) => option.key !== this.visibleOptions[i].key
          )
        ) {
          this.visibleOptions = newOptions;

          // If only one option is available, preselect it automatically
          if (
            options.length === 1 &&
            this.formControl.value !== options[0].key
          ) {
            const singleOption = options[0];
            this.formControl.patchValue(singleOption.key, { emitEvent: false });
            this.selected.emit(singleOption);
            this.selectedOption = singleOption;
            this.inputValue = singleOption.label;
          }
        }

        if (!this.optionsInitialized && options.length > 0) {
          this.optionsInitialized = true;
          this.selectOption(this.formControl.value);
        }

        this.cdRef.detectChanges();
      })
    );
  }

  private listenForForm(): void {
    this.subscriptions.add(
      this.formControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((optionKey) => {
          this.selectOption(optionKey);
          this.cdRef.detectChanges();
        })
    );

    this.subscriptions.add(
      this.formControl.parent?.statusChanges
        .pipe(debounceTime(50))
        .subscribe(() => {
          setTimeout(() => {
            this.cdRef.detectChanges();
          });
        })
    );
  }

  public onInputChange(value: string): void {
    this.autocompleteService.setActiveAutocomplete(this.autocompleteId);
    this.searchTermChanged.emit(value);
  }

  public onInputFocus(): void {
    this.autocompleteService.setActiveAutocomplete(this.autocompleteId);
  }

  public trackByOption(index: number, option: DsAutocompleteOption): string {
    return option.key;
  }

  public onOptionClick(option: DsAutocompleteOption): void {
    this.formControl.patchValue(option.key);
    this.autocompleteService.close();
    this.selected.emit(option);
  }

  public onListboxButtonClick(): void {
    if (this.isResetAvailable()) {
      this.reset();
    } else {
      this.toggleListbox();
    }
  }

  private reset(): void {
    this.selectedOption = null;
    this.formControl.patchValue(null);
    this.inputValue = '';
    this.searchTermChanged.emit('');
    this.selected.emit(null);
  }

  private toggleListbox(): void {
    if (this.isListboxVisible) {
      this.autocompleteService.close();
    } else {
      this.inputRef?.nativeElement.focus();
    }
  }

  private handleBlur(): void {
    this.inputValue = this.selectedOption?.label ?? '';

    this.searchTermChanged.emit(this.inputValue);
    this.autocompleteService.close();
    this.cdRef.detectChanges();
  }

  private listenForClickOutside(): void {
    this.subscriptions.add(
      fromEvent(window, 'click').subscribe((e) => {
        if (this.isListboxVisible) {
          const event = e as MouseEvent;
          const target = event.target as HTMLElement;

          if (!target.closest('.js-autocomplete-container')) {
            this.handleBlur();
          }
        }
      })
    );
  }

  private listenForKeyboard(): void {
    this.subscriptions.add(
      fromEvent(window, 'keydown').subscribe((e) => {
        if (this.isListboxVisible) {
          const event = e as KeyboardEvent;

          if (event.key === 'Escape') {
            this.inputRef?.nativeElement.blur();
            this.handleBlur();
          }

          if (
            event.key === 'Tab' &&
            this.isListboxVisible &&
            this.inputRef?.nativeElement === document.activeElement
          ) {
            this.handleBlur();
          }
        }
      })
    );
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public isResetAvailable(): boolean {
    return !!this.selectedOption || !!this.inputValue;
  }

  private selectOption(optionKey: string): void {
    const option = this.options.find((o) => o.key === optionKey);
    this.selectedOption = option || null;
    this.inputValue = option?.label || '';
  }
}
