import { Component, Input, OnInit } from '@angular/core';
import { DsIcon, DsInfoboxSize, DsInfoboxVariant } from '@ds-types';

@Component({
  selector: 'ds-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
})
export class InfoboxComponent implements OnInit {
  @Input() icon?: DsIcon;
  @Input() variant: DsInfoboxVariant = 'default';
  @Input() size: DsInfoboxSize = 'M';
  public _icon: DsIcon = 'info-16';

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon(): void {
    if (this.variant === 'approved') {
      this._icon = 'check-16';
    }

    if (this.variant === 'error') {
      this._icon = 'error-16';
    }

    if (this.variant === 'success') {
      this._icon = 'success-16';
    }

    if (this.variant === 'warning') {
      this._icon = 'warning-16';
    }

    if (this.icon) {
      this._icon = this.icon;
    }
  }

  public getBackgroundClasses(): string[] {
    const classes = [];

    if (this.variant === 'background') {
      classes.push('bg-gray-200', 'text-gray-700', 'p-2.5');
    } else if (this.variant === 'approved') {
      classes.push('text-gray-800');
    } else if (this.variant === 'success') {
      classes.push('bg-green-100', 'text-gray-800', 'p-2.5');
    } else if (this.variant === 'warning') {
      classes.push('bg-warning-100', 'text-gray-800', 'p-2.5');
    } else if (this.variant === 'error') {
      classes.push('bg-error-100', 'text-gray-800', 'p-2.5', 'js-error-scroll');
    } else {
      classes.push('text-gray-600');
    }

    return classes;
  }

  public getIconClasses(): string[] {
    const classes = [];

    if (this.variant === 'approved' || this.variant === 'success') {
      classes.push('text-green-500');
    }

    if (this.variant === 'error') {
      classes.push('text-error-500');
    }

    if (this.variant === 'warning') {
      classes.push('text-warning-500');
    }

    return classes;
  }

  public getTextClasses(): string[] {
    const classes = [];

    if (this.size === 'S') {
      classes.push('text-sm', 'mt-0.5');
    }

    return classes;
  }
}
