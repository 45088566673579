<div class="bg-cover bg-center bg-fixed h-full" style="background-image: url(/assets/images/strasser-background.jpg)">
  <div class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="typo-h4">{{ 'not-found.headline' | translate }}</p>
      <h1 class="mt-6 typo-h2">{{ 'not-found.subline' | translate }}</h1>
      <p class="mt-6 typo-md">{{ 'not-found.description' | translate }}</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a href="/" ds-button>{{ 'not-found.back-to-home' | translate }}</a>
      </div>
    </div>
  </div>
</div>
