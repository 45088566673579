<button ds-button (click)="onOpenClick()" type="button" variant="link" icon="chevron-right-16" iconPos="right">{{ buttonLabel | translate }}</button>

<ds-sidebar type="calendar" [htmlId]="uid">
  <ng-container id="headline">{{ sidebarHeadline | translate }}</ng-container>
  <div class="px-8 flex h-full flex-col justify-between pb-6">
    <div class="max-w-md">
      <div class="overflow-hidden rounded bg-white text-black">
        <div class="bg-white">
          <div class="flex items-center justify-between">
            <button class="px-3 py-6 m-1" (click)="goToPrevMonth()" type="button">
              <ds-icon name="chevron-right-24" class="rotate-180" [size]="24"></ds-icon>
            </button>
            <div class="flex grow items-center justify-between">
              <div class="typo-h5 whitespace-nowrap">
                {{ activeMonth | dsDate: 'MONTH_YEAR' }}
              </div>
              <button ds-button variant="outline" size="S" (click)="goToCurrentMonth()">{{ 'calendar-today' | translate }}</button>
            </div>

            <button class="px-3 py-6 m-1" (click)="goToNextMonth()" type="button">
              <ds-icon name="chevron-right-24" [size]="24"></ds-icon>
            </button>
          </div>
        </div>
        <div class="flex py-4 typo-sm">
          <div class="pl-4 border-r pr-4">
            <div class="h-6 text-gray-600">KW</div>
            <div class="h-10 flex items-center justify-center" *ngFor="let weekNr of getWeekNrs()">{{ weekNr }}</div>
          </div>
          <div class="grow px-4 text-center uppercase">
            <div class="grid grid-cols-7">
              <div class="text-gray-600 h-6" *ngFor="let weekday of weekdays; trackBy: trackByWeekday">
                {{ weekday | dsDate: 'WEEKDAY_SHORT' }}
              </div>
            </div>

            <div class="grid grid-cols-7 group" *ngFor="let week of getWeeksAndDaysOfActiveMonth(); trackBy: trackByWeek">
              <div *ngFor="let day of week; trackBy: trackByDay">
                <button [ngClass]="getDayClasses(day)" class="flex h-10 w-full items-center justify-center transition-colors duration-200" type="button" (click)="onDayClick(day)">
                  <div>
                    <div class="leading-none">{{ day.date | dsDate: 'DAY' }}</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <button (click)="onCancelClick()" type="button" ds-button variant="link">{{ 'actions.cancel' | translate }}</button>
      <button variant="flat" ds-button type="button" (click)="onSubmitClick()" [isLoading]="status === 'submitting'" [isDisabled]="status === 'submitting'" icon="save-16">
        <span *ngIf="orderFormService.isViewMode">{{ 'actions.save-active-step' | translate }}</span>
        <span *ngIf="!orderFormService.isViewMode">{{ 'actions.save' | translate }}</span>
      </button>
    </div>
  </div>
</ds-sidebar>
