import { DsDcuplConverter, DsMaterial, DsMaterialDcupl } from '@ds-types';

export const materialConverter: DsDcuplConverter<DsMaterialDcupl, DsMaterial> =
  {
    fromDcupl: (data) => {
      return {
        number: data.sn,
        name: data.yvbez,
        groupname: data.ymatgrp,
      };
    },
  };
