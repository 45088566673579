import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialApplyDialogComponent } from './material-apply-dialog.component';

@NgModule({
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  declarations: [MaterialApplyDialogComponent],
  exports: [MaterialApplyDialogComponent],
})
export class MaterialApplyDialogModule {}
