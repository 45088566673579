import { FormControl, FormGroup } from '@angular/forms';
import { DsFormField } from '@ds-form';
import {
  DsArticleDcupl,
  DsArticleView,
  DsBoardType,
  DsCutoutType,
  DsCutoutTypeSettingsFields,
  DsDateApi,
  DsFile,
  DsMaterialSettingsFields,
  DsTableColumn,
} from '@ds-types';

export type DsOrderFormStep = 1 | 2 | 3 | 4 | 5;

export const DS_ORDER_FORM_STEPS: DsOrderFormStep[] = [1, 2, 3, 4, 5];

export type DsOrderFormGroups = {
  1: FormGroup<{ [key in DsOrderFormStep1Fields]: FormControl }>;
  2: FormGroup<{ [key in DsOrderFormStep2Fields]: FormControl }>;
  3: FormGroup<{ [key in DsOrderFormStep3Fields]: FormControl }>;
  4: FormGroup<{ [key in DsOrderFormStep4Fields]: FormControl }>;
  5: FormGroup<{ [key in DsOrderFormStep5Fields]: FormControl }>;
};

export type DsOrderFormGroupValues = {
  1: { [key in DsOrderFormStep1Fields]?: unknown };
  2: { [key in DsOrderFormStep2Fields]?: unknown };
  3: { [key in DsOrderFormStep3Fields]?: unknown };
  4: { [key in DsOrderFormStep4Fields]?: unknown };
  5: { [key in DsOrderFormStep5Fields]?: unknown };
};

export type DsMaterialSettingsFormGroup = FormGroup<{
  [key in DsMaterialSettingsFields]: FormControl;
}>;

export type DsCutoutSettingsFormGroup = FormGroup<{
  [key in DsCutoutTypeSettingsFields]: FormControl;
}>;

export type DsCutoutSettingsValues = {
  [key in DsCutoutTypeSettingsFields]?: unknown;
};

export type DsOrderMaterialSettingsFormGroups = {
  [key in DsBoardType]: DsMaterialSettingsFormGroup;
};

export type DsOrderCutoutSettingsFormGroups = {
  [key in DsCutoutType]: DsCutoutSettingsFormGroup;
};

export type DsOrderFormFields<StepFields extends DsOrderFormStepFields> = {
  [key in StepFields]: DsFormField<key>;
};

export type DsOrderFormStep1Fields =
  | 'commission_name'
  | 'commission_number'
  | 'condition_date'
  | 'customerRefNr'
  | 'sales_RefNr'
  | 'service_type'
  | 'type';

export type DsOrderFormStep2Fields = never;
export type DsOrderFormStep3Fields = 'files' | 'installation_gap';
export type DsOrderFormStep4Fields =
  | 'customer_city_delivery'
  | 'customer_country_delivery'
  | 'specify_customer_data_later'
  | 'customer_email_delivery'
  | 'customer_mobile_delivery'
  | 'customer_name_delivery'
  | 'customer_street_delivery'
  | 'customer_floor_delivery'
  | 'customer_tel_delivery'
  | 'customer_zip_delivery'
  | 'delivery_type'
  | 'measure'
  | 'specify_plan_dimension_later'
  | 'measure_delivery'
  | 'montage_package_connection'
  | 'montage_package_connection_downdraft'
  | 'montage_package_connection_multi'
  | 'warehouse_address'
  | 'warehouse_address_retail'
  | 'warehouse_city'
  | 'warehouse_country'
  | 'warehouse_email_retail'
  | 'warehouse_mobile_retail'
  | 'warehouse_tel_retail'
  | 'warehouse_zip';

export type DsOrderFormStep5Fields =
  | DsOrderFormStep5Deadlines
  | 'comment'
  | 'agb'
  | 'data_protection'
  | 'data_ok'
  | 'material_selection_type'
  | 'photos_email_send'
  | 'customer_email_delivery';

export type DsOrderFormStep5Deadlines =
  | 'deadline_stencil'
  | 'deadline_changes'
  | 'deadline_address'
  | 'deadline_sinktype'
  | 'deadline_hobtype'
  | 'deadline_downdraft'
  | 'deadline_energybox'
  | 'deadline_material_inspection'
  | 'deadline_plan_dimension'
  | 'deadline_natural_measure'
  | 'nature_measure_date'
  | 'delivery_date';

export type DsOrderFormStepFields =
  | DsOrderFormStep1Fields
  | DsOrderFormStep2Fields
  | DsOrderFormStep3Fields
  | DsOrderFormStep4Fields
  | DsOrderFormStep5Fields;

export type DsOrderFormDeliveryType =
  | 'delivery_customer'
  | 'delivery_customer_montage'
  | 'delivery_retailer'
  | 'pickup';

export type DsOrderFormDeliveryTypeApi =
  | '(LALIEFERUNGHALA)'
  | '(LAMONTAGE)'
  | '(LALIEFZEK)'
  | '(LASELBSTABHOLUNG)';

export type DsOrderFormMeasure = 'none' | 'natural' | 'template';

export type DsOrderFormData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in DsOrderFormStepFields]?: any;
};

export type DsOrderType = 'order' | 'offer';
export type DsOrderTypeApi = '(SalesOrder)' | '(Quotation)' | null;

export type DsServiceType = '(VERKAUF)';

export type DsOrderArticle<ArticleNumberType> = {
  amount: number;
  max_length: boolean;
  position_number: string;
  position_type: null;
  article_number: ArticleNumberType;
  edge_treatment: string;
};

export type DsOrderMaterial = {
  material_id: string;
  material_name: string;
  material_type: string;
  material_number: string;
};

type DsOrderGeneric<
  DateType,
  DeliveryType,
  OrderType,
  OrderStatus,
  ArticleNumberType,
> = {
  key: string;
  // General
  order_number: string;

  // Step 1
  sales_RefNr: string;
  customerRefNr: string;
  commission_name: string;
  commission_number: string;
  service_type: DsServiceType;
  condition_date: DateType | null;
  type: OrderType;

  // Step 2
  articles: DsOrderArticle<ArticleNumberType>[];
  sink_brand: string | null;
  sink_installation_type: string | null;
  sink_type: string | null;
  sink_order_nr: string | null;
  specify_sink_later: boolean | null;
  ind_sink_text: string | null;
  hob_brand: string | null;
  hob_installation_type: string | null;
  hob_type: string | null;
  hob_order_nr: string | null;
  specify_hob_later: boolean | null;
  ind_hob_text: string | null;
  downdraft_brand: string | null;
  downdraft_installation_type: string | null;
  downdraft_type: string | null;
  downdraft_order_nr: string | null;
  ind_downdraft_text: string | null;
  specify_downdraft_later: boolean | null;
  energy_box_brand: string | null;
  energy_box_installation_type: string | null;
  energy_box_type: string | null;
  energy_box_order_nr: string | null;
  specify_energy_box_later: boolean | null;
  ind_energy_box_text: string | null;

  // Step 3
  // (files are uploaded directly to the server)
  installation_gap: boolean;

  // Step 4
  customer_city_delivery: string;
  customer_country_delivery: DsOrderCountryApi;
  specify_customer_data_later: boolean;
  customer_email_delivery: string;
  customer_mobile_delivery: string;
  customer_name_delivery: string;
  customer_street_delivery: string;
  customer_floor_delivery: number | null;
  customer_tel_delivery: string;
  customer_zip_delivery: string;
  delivery_type: DeliveryType;
  measure_nature: boolean;
  measure_stencil: boolean;
  measure_delivery: boolean;
  specify_plan_dimension_later: boolean;
  montage_package_connection: boolean;
  montage_package_connection_downdraft: boolean;
  montage_package_connection_multi: boolean;
  warehouse_address: string;
  warehouse_address_retail: string;
  warehouse_city: string;
  warehouse_country: DsOrderCountryApi;
  warehouse_email_retail: string;
  warehouse_mobile_retail: string;
  warehouse_tel_retail: string;
  warehouse_zip: string;

  // Step 5
  comment: string;
  status_clarification: boolean | null;
  agb: boolean;
  data_protection: boolean;

  material_selection_type: DsOrderMaterialSelectionTypeApi;
  material_selection_date: DateType | null; // todo feature not implemented yet
  material_selection_confirmed: null;
  material_selection_photos_sent: null;
  material_selection_customer_visit: null;

  deadline_stencil: DateType | null;
  deadline_changes: DateType | null;
  deadline_address: DateType | null;
  deadline_sinktype: DateType | null;
  deadline_hobtype: DateType | null;
  deadline_downdraft: DateType | null;
  deadline_energybox: DateType | null;
  deadline_material_inspection: DateType | null;
  deadline_plan_dimension: DateType | null;
  deadline_natural_measure: DateType | null;
  nature_measure_date: DateType | null;
  delivery_date: DateType | null;
  earliest_delivery_date: DateType | null;
  creation_date: DateType | null;
  digistoneRefNr: 'Digistone';
  buyer_info: string | null;
  sales_tel: string | null;
  lock: boolean | null;
  action_needed: boolean;
  purchase_price: number | null;
  sales_price: number | null;
  status: OrderStatus | null;
  abasID: null;
  versionn: string | null;
  is_digistone_order: true;
};

export type DsOrderCountryApi =
  | 'UNGARN'
  | 'ITALIEN'
  | 'LIECHTENSTEIN'
  | 'LUXEMBURG'
  | 'SLOWAKEI'
  | 'SCHWEIZ'
  | 'OESTERREICH'
  | 'TSCHECHIEN'
  | 'DEUTSCHLAND';

export type DsOrderApi = DsOrderGeneric<
  DsDateApi,
  DsOrderFormDeliveryTypeApi,
  DsOrderTypeApi,
  DsOrderStatusApi,
  string
>;
export type DsOrder = DsOrderGeneric<
  Date,
  DsOrderFormDeliveryType,
  DsOrderType,
  DsOrderStatus,
  string
>;

type DsOrderOverviewBaseGeneric<DateType, OrderType, OrderStatus> = {
  key: string;
  order_number: string;
  commission_name: string;
  commission_number: string;
  purchase_price: number;
  sales_price: number;
  status: OrderStatus;
  type: OrderType;
  delivery_date: DateType | null;
  deadline_changes: DateType | null;
  deadline_address: DateType | null;
  deadline_sinktype: DateType | null;
  deadline_hobtype: DateType | null;
  deadline_downdraft: DateType | null;
  deadline_energybox: DateType | null;
  deadline_stencil: DateType | null;
  status_clarification: boolean;
  specify_customer_data_later: boolean;
  specify_plan_dimension_later: boolean;
  specify_sink_later: boolean;
  specify_hob_later: boolean;
  specify_downdraft_later: boolean;
  specify_energy_box_later: boolean;
  action_needed: boolean;
  lock: boolean;
  is_digistone_order: boolean;
};

type DsOrderOverviewGeneric<DateType, OrderType, OrderStatus> =
  DsOrderOverviewBaseGeneric<DateType, OrderType, OrderStatus> & {
    articles: DsOrderMaterial[];
  };

export type DsOrderOverviewApi = DsOrderOverviewGeneric<
  DsDateApi,
  DsOrderTypeApi,
  DsOrderStatusApi
>;

export type DsOrderOverview = DsOrderOverviewGeneric<
  Date,
  DsOrderType,
  DsOrderStatus
>;

type DsDraftOverviewGeneric<DateType, OrderType, OrderStatus> =
  DsOrderOverviewBaseGeneric<DateType, OrderType, OrderStatus> & {
    articles: DsOrderArticle<DsArticleDcupl>[];
  };

export type DsDraftOverviewApi = DsDraftOverviewGeneric<
  DsDateApi,
  DsOrderTypeApi,
  DsOrderStatusApi
>;

export type DsDraftOverview = DsDraftOverviewGeneric<
  Date,
  DsOrderType,
  DsOrderStatus
>;

export type DsOrderOrDraftOverview = DsOrderOverview | DsDraftOverview;
export type DsOrderOrDraftOverviewApi = DsOrderOverviewApi | DsDraftOverviewApi;

export type DsOrderDefinition =
  | DsOrderColumns
  | 'sticky_column'
  | 'filler_start'
  | 'filler_between'
  | 'filler_end'
  | 'actions'
  | 'material';

export type DsOrderColumns = keyof DsOrder;

export type DsOrderStatus =
  | 'created'
  | 'confirmation_sent'
  | 'clarified'
  | 'in_production'
  | 'finished'
  | 'in_delivery'
  | 'delivery_done'
  | 'invoice_created'
  | 'cancelled'
  | 'offer_created'
  | 'offer_clarification'
  | 'offer_sent'
  | 'offer_not_realized'
  | 'offer_finished'
  | 'archived'
  | null;

export type DsOrderStatusApi =
  | '(AUSTERFASST)'
  | '(AUSTGEZABVERSAND)'
  | '(AUSTVOLLSTABGEKL)'
  | '(AUSTINPRODUKTION)'
  | '(AUSTFERTIGGESTELLT)'
  | '(AUSTINLIEFERUNG)'
  | '(AUSTRMLIEFERSCHEIN)'
  | '(AUSTFAKTURAERSTELLT)'
  | '(AUSTSTORNIERT)'
  | '(ANSTERFASST)'
  | '(ANSTINKLAERUNG)'
  | '(ANSTVERSCHICKT)'
  | '(ANSTNICHTREALISIERT)'
  | '(ANSTBESTELLT)'
  | '(ARCHIVIERT)'
  | null;

export type DsOrdersTableDefinition = DsTableColumn<DsOrderDefinition>[];

export type DsOrderEditData = {
  order: DsOrder;
  articles: DsArticleView[];
  files: DsFile[];
  dataOk: boolean;
};

export type DsOrderMaterialSelectionType =
  | 'photos_sent'
  | 'customer_visit'
  | 'none';
export type DsOrderMaterialSelectionTypeApi =
  | ''
  | '(_10)'
  | '(_20)'
  | '(_30)'
  | '(_40)';

export type DsOrderFormPhotosEmailSendType = 'me' | 'customer';

export const DS_CUTOUT_INDIVIDUAL_TEXT = 'INDIVIDUAL#';

export type DsOrderSubmitStatus = 'success' | 'submitting' | 'pending';

export type DsOrderSubmitMode = 'create' | 'update';

export type DsOrdersTabKey =
  | 'needs-action'
  | 'needs-no-action'
  | 'all'
  | 'archive';

export type DsOffersTabKey = 'in-clarification' | 'sent' | 'all';

export type DsOrdersAndOffersTabKey = DsOrdersTabKey | DsOffersTabKey;

export type DsOrderPreStore = {
  isOverallDirty: boolean;
  formGroupValues?: DsOrderFormGroupValues;
};
