export type DsHtmlInputEvent = Event & {
  target: HTMLInputElement & EventTarget;
};

export type DsFileError = 'duplicate' | 'fileSize' | 'fileFormat' | 'other';

type DsFileBase = {
  name: string;
  size: number;
};

export type DsFileOnSuccess = DsFileBase & {
  status: 'success';
  fullPath: string;
};

type DsFileOnLoading = DsFileBase & {
  status: 'loading';
};

export type DsFileOnError = DsFileBase & {
  status: 'error';
  error: DsFileError;
};

export type DsFile = DsFileOnSuccess | DsFileOnLoading | DsFileOnError;

export type DsFileUploadConfig = {
  validFileTypes: string[];
  maxFileSizeInMegaBytes: number;
  filesLimit: number;
};
