import { dateConverter, neverError } from '@ds-helpers';
import {
  DS_CUTOUT_INDIVIDUAL_TEXT,
  DsApiConverter,
  DsDateApi,
  DsDraftOverview,
  DsDraftOverviewApi,
  DsOrder,
  DsOrderApi,
  DsOrderArticle,
  DsOrderCutoutSettingsFormGroups,
  DsOrderFormData,
  DsOrderFormDeliveryType,
  DsOrderFormDeliveryTypeApi,
  DsOrderFormPhotosEmailSendType,
  DsOrderMaterialSelectionType,
  DsOrderMaterialSelectionTypeApi,
  DsOrderMaterialSettingsFormGroups,
  DsOrderOverview,
  DsOrderOverviewApi,
  DsOrderStatus,
  DsOrderStatusApi,
  DsOrderType,
  DsOrderTypeApi,
} from '@ds-types';

export const orderConverter: DsApiConverter<DsOrderApi, DsOrder> = {
  toApi: (order) => {
    return {
      ...order,
      condition_date: dateToApiNullConverter(order.condition_date),
      type: orderTypeConverter.toApi(order.type),
      status: order.status ? orderStatusConverter.toApi(order.status) : null,
      delivery_type: deliveryTypeConverter.toApi(order.delivery_type),
      deadline_changes: dateToApiNullConverter(order.deadline_changes),
      deadline_address: dateToApiNullConverter(order.deadline_address),
      deadline_sinktype: dateToApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateToApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateToApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateToApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateToApiNullConverter(order.deadline_stencil),
      deadline_material_inspection: dateToApiNullConverter(
        order.deadline_material_inspection
      ),
      deadline_plan_dimension: dateToApiNullConverter(
        order.deadline_plan_dimension
      ),
      deadline_natural_measure: dateToApiNullConverter(
        order.deadline_natural_measure
      ),
      nature_measure_date: dateToApiNullConverter(order.nature_measure_date),
      creation_date: dateToApiNullConverter(order.creation_date),
      earliest_delivery_date: dateToApiNullConverter(
        order.earliest_delivery_date
      ),
      delivery_date: dateToApiNullConverter(order.delivery_date),
      material_selection_date: dateToApiNullConverter(
        order.material_selection_date
      ),
    };
  },
  fromApi: (order) => {
    return {
      ...order,
      condition_date: dateFromApiNullConverter(order.condition_date),
      type: orderTypeConverter.fromApi(order.type),
      status: order.status ? orderStatusConverter.fromApi(order.status) : null,
      delivery_type: deliveryTypeConverter.fromApi(order.delivery_type),
      deadline_changes: dateFromApiNullConverter(order.deadline_changes),
      deadline_address: dateFromApiNullConverter(order.deadline_address),
      deadline_sinktype: dateFromApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateFromApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateFromApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateFromApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateFromApiNullConverter(order.deadline_stencil),
      deadline_material_inspection: dateFromApiNullConverter(
        order.deadline_material_inspection
      ),
      deadline_plan_dimension: dateFromApiNullConverter(
        order.deadline_plan_dimension
      ),
      deadline_natural_measure: dateFromApiNullConverter(
        order.deadline_natural_measure
      ),
      nature_measure_date: dateFromApiNullConverter(order.nature_measure_date),
      creation_date: dateFromApiNullConverter(order.creation_date),
      earliest_delivery_date: dateFromApiNullConverter(
        order.earliest_delivery_date
      ),
      delivery_date: dateFromApiNullConverter(order.delivery_date),
      material_selection_date: dateFromApiNullConverter(
        order.material_selection_date
      ),
    };
  },
};

export const orderOverviewConverter: DsApiConverter<
  DsOrderOverviewApi,
  DsOrderOverview
> = {
  toApi: (order) => {
    return {
      ...order,
      status: orderStatusConverter.toApi(order.status),
      type: orderTypeConverter.toApi(order.type),
      delivery_date: dateToApiNullConverter(order.delivery_date),
      deadline_changes: dateToApiNullConverter(order.deadline_changes),
      deadline_address: dateToApiNullConverter(order.deadline_address),
      deadline_sinktype: dateToApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateToApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateToApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateToApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateToApiNullConverter(order.deadline_stencil),
    };
  },
  fromApi: (order) => {
    return {
      ...order,
      status: orderStatusConverter.fromApi(order.status),
      type: orderTypeConverter.fromApi(order.type),
      delivery_date: dateFromApiNullConverter(order.delivery_date),
      deadline_changes: dateFromApiNullConverter(order.deadline_changes),
      deadline_address: dateFromApiNullConverter(order.deadline_address),
      deadline_sinktype: dateFromApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateFromApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateFromApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateFromApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateFromApiNullConverter(order.deadline_stencil),
    };
  },
};

export const draftOverviewConverter: DsApiConverter<
  DsDraftOverviewApi,
  DsDraftOverview
> = {
  toApi: (order) => {
    return {
      ...order,
      status: orderStatusConverter.toApi(order.status),
      type: orderTypeConverter.toApi(order.type),
      delivery_date: dateToApiNullConverter(order.delivery_date),
      deadline_changes: dateToApiNullConverter(order.deadline_changes),
      deadline_address: dateToApiNullConverter(order.deadline_address),
      deadline_sinktype: dateToApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateToApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateToApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateToApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateToApiNullConverter(order.deadline_stencil),
    };
  },
  fromApi: (order) => {
    return {
      ...order,
      status: orderStatusConverter.fromApi(order.status),
      type: orderTypeConverter.fromApi(order.type),
      delivery_date: dateFromApiNullConverter(order.delivery_date),
      deadline_changes: dateFromApiNullConverter(order.deadline_changes),
      deadline_address: dateFromApiNullConverter(order.deadline_address),
      deadline_sinktype: dateFromApiNullConverter(order.deadline_sinktype),
      deadline_hobtype: dateFromApiNullConverter(order.deadline_hobtype),
      deadline_downdraft: dateFromApiNullConverter(order.deadline_downdraft),
      deadline_energybox: dateFromApiNullConverter(order.deadline_energybox),
      deadline_stencil: dateFromApiNullConverter(order.deadline_stencil),
    };
  },
};

function dateToApiNullConverter(date: Date | null): DsDateApi | null {
  return date ? dateConverter.toApi(date) : null;
}

function dateFromApiNullConverter(date: DsDateApi | null): Date | null {
  return date ? dateConverter.fromApi(date) : null;
}

const orderStatusConverter: DsApiConverter<DsOrderStatusApi, DsOrderStatus> = {
  toApi: (status) => {
    switch (status) {
      case 'created':
        return '(AUSTERFASST)';
      case 'confirmation_sent':
        return '(AUSTGEZABVERSAND)';
      case 'clarified':
        return '(AUSTVOLLSTABGEKL)';
      case 'in_production':
        return '(AUSTINPRODUKTION)';
      case 'finished':
        return '(AUSTFERTIGGESTELLT)';
      case 'in_delivery':
        return '(AUSTINLIEFERUNG)';
      case 'delivery_done':
        return '(AUSTRMLIEFERSCHEIN)';
      case 'invoice_created':
        return '(AUSTFAKTURAERSTELLT)';
      case 'cancelled':
        return '(AUSTSTORNIERT)';
      case 'offer_created':
        return '(ANSTERFASST)';
      case 'offer_clarification':
        return '(ANSTINKLAERUNG)';
      case 'offer_sent':
        return '(ANSTVERSCHICKT)';
      case 'offer_not_realized':
        return '(ANSTNICHTREALISIERT)';
      case 'offer_finished':
        return '(ANSTBESTELLT)';
      case 'archived':
        return '(ARCHIVIERT)';
      case null:
        return null;
      default:
        throw neverError(status);
    }
  },
  fromApi: (status) => {
    switch (status) {
      case '(AUSTERFASST)':
        return 'created';
      case '(AUSTGEZABVERSAND)':
        return 'confirmation_sent';
      case '(AUSTVOLLSTABGEKL)':
        return 'clarified';
      case '(AUSTINPRODUKTION)':
        return 'in_production';
      case '(AUSTFERTIGGESTELLT)':
        return 'finished';
      case '(AUSTINLIEFERUNG)':
        return 'in_delivery';
      case '(AUSTRMLIEFERSCHEIN)':
        return 'delivery_done';
      case '(AUSTFAKTURAERSTELLT)':
        return 'invoice_created';
      case '(AUSTSTORNIERT)':
        return 'cancelled';
      case '(ANSTERFASST)':
        return 'offer_created';
      case '(ANSTINKLAERUNG)':
        return 'offer_clarification';
      case '(ANSTVERSCHICKT)':
        return 'offer_sent';
      case '(ANSTNICHTREALISIERT)':
        return 'offer_not_realized';
      case '(ANSTBESTELLT)':
        return 'offer_finished';
      case '(ARCHIVIERT)':
        return 'archived';
      case null:
        return null;
      default:
        throw neverError(status);
    }
  },
};

const deliveryTypeConverter: DsApiConverter<
  DsOrderFormDeliveryTypeApi,
  DsOrderFormDeliveryType
> = {
  toApi: (deliveryType) => {
    switch (deliveryType) {
      case 'pickup':
        return '(LASELBSTABHOLUNG)';
      case 'delivery_customer':
        return '(LALIEFZEK)';
      case 'delivery_customer_montage':
        return '(LAMONTAGE)';
      case 'delivery_retailer':
        return '(LALIEFERUNGHALA)';
      default:
        throw neverError(deliveryType);
    }
  },
  fromApi: (deliveryType) => {
    switch (deliveryType) {
      case '(LASELBSTABHOLUNG)':
        return 'pickup';
      case '(LALIEFZEK)':
        return 'delivery_customer';
      case '(LAMONTAGE)':
        return 'delivery_customer_montage';
      case '(LALIEFERUNGHALA)':
        return 'delivery_retailer';
      default:
        throw neverError(deliveryType);
    }
  },
};

export const materialSelectionTypeConverter = {
  fromForm: (
    material_selection_type: DsOrderMaterialSelectionType,
    photos_email_send: DsOrderFormPhotosEmailSendType
  ): DsOrderMaterialSelectionTypeApi => {
    switch (material_selection_type) {
      case 'none':
        return '(_10)';
      case 'photos_sent':
        if (photos_email_send === 'me') {
          return '(_20)';
        } else {
          return '(_30)';
        }
      case 'customer_visit':
        return '(_40)';
    }
  },
  toForm: (
    order: DsOrder
  ): {
    material_selection_type: DsOrderMaterialSelectionType;
    photos_email_send: DsOrderFormPhotosEmailSendType | null;
  } => {
    switch (order.material_selection_type) {
      case '':
        return {
          material_selection_type: 'none',
          photos_email_send: null,
        };
      case '(_10)':
        return {
          material_selection_type: 'none',
          photos_email_send: null,
        };
      case '(_20)':
        return {
          material_selection_type: 'photos_sent',
          photos_email_send: 'me',
        };
      case '(_30)':
        return {
          material_selection_type: 'photos_sent',
          photos_email_send: 'customer',
        };
      case '(_40)':
        return {
          material_selection_type: 'customer_visit',
          photos_email_send: null,
        };
      default:
        throw neverError(order.material_selection_type);
    }
  },
};

const orderTypeConverter: DsApiConverter<DsOrderTypeApi, DsOrderType> = {
  toApi: (orderType) => {
    switch (orderType) {
      case 'order':
        return '(SalesOrder)';
      case 'offer':
        return '(Quotation)';
      default:
        throw neverError(orderType);
    }
  },
  fromApi: (orderType) => {
    switch (orderType) {
      case '(SalesOrder)':
        return 'order';
      case '(Quotation)':
        return 'offer';
      case null:
        return 'offer';
      default:
        throw neverError(orderType);
    }
  },
};

function getArticlesByFormData(
  materialSettingsFormGroups: DsOrderMaterialSettingsFormGroups,
  additionalArticlesFromAbas: DsOrderArticle<string>[]
): DsOrderArticle<string>[] {
  const articles: DsOrderArticle<string>[] = [];

  Object.values(materialSettingsFormGroups).forEach((materialFormGroup) => {
    const enabled = materialFormGroup.value.enabled;
    const articleId = materialFormGroup.value.article_id;

    if (!!enabled && enabled === true && !!articleId) {
      articles.push({
        amount: materialFormGroup.value.amount ?? 1,
        max_length: materialFormGroup.value.article_max_length,
        position_number: '',
        position_type: null,
        article_number: articleId,
        edge_treatment: materialFormGroup.value.edge_treatment,
      });
    }
  });

  additionalArticlesFromAbas.forEach((article) => {
    articles.push(article);
  });

  return articles;
}

function getIndividualText(
  isIndividual: boolean,
  additionalText: string
): string {
  return `${isIndividual ? DS_CUTOUT_INDIVIDUAL_TEXT : ''}${additionalText}`;
}

export function convertOrderFormToOrder(param: {
  order: DsOrderFormData;
  orderNumber: string;
  materialSettingsFormGroups: DsOrderMaterialSettingsFormGroups;
  cutoutSettingsFormGroups: DsOrderCutoutSettingsFormGroups;
  orderVersion: string | null;
  additionalArticlesFromAbas: DsOrderArticle<string>[];
}): DsOrder {
  return {
    // General
    key: param.orderNumber,
    order_number: param.orderNumber,

    // Step 1
    sales_RefNr: param.order.sales_RefNr,
    customerRefNr: param.order.customerRefNr,
    commission_name: param.order.commission_name,
    commission_number: param.order.commission_number,
    service_type: param.order.service_type,
    condition_date: param.order.condition_date,
    type: param.order.type,
    delivery_date: param.order.delivery_date,

    // Step 2
    articles: getArticlesByFormData(
      param.materialSettingsFormGroups,
      param.additionalArticlesFromAbas
    ),

    // sink
    sink_brand: param.cutoutSettingsFormGroups.sink.value.enabled
      ? (param.cutoutSettingsFormGroups.sink.value.brand as string)
      : null,
    sink_installation_type: param.cutoutSettingsFormGroups.sink.value.enabled
      ? (param.cutoutSettingsFormGroups.sink.value.installation_type as string)
      : null,
    sink_type: param.cutoutSettingsFormGroups.sink.value.enabled
      ? (param.cutoutSettingsFormGroups.sink.value.type as string)
      : null,
    sink_order_nr: param.cutoutSettingsFormGroups.sink.value.enabled
      ? (param.cutoutSettingsFormGroups.sink.value.order_number as string)
      : null,
    ind_sink_text: param.cutoutSettingsFormGroups.sink.value.enabled
      ? getIndividualText(
          param.cutoutSettingsFormGroups.sink.value.is_ind,
          param.cutoutSettingsFormGroups.sink.value.ind_text
        )
      : null,
    specify_sink_later: param.cutoutSettingsFormGroups.sink.value.enabled
      ? param.cutoutSettingsFormGroups.sink.value.specify_later
      : null,

    // hob
    hob_brand: param.cutoutSettingsFormGroups.hob.value.enabled
      ? (param.cutoutSettingsFormGroups.hob.value.brand as string)
      : null,
    hob_installation_type: param.cutoutSettingsFormGroups.hob.value.enabled
      ? (param.cutoutSettingsFormGroups.hob.value.installation_type as string)
      : null,
    hob_type: param.cutoutSettingsFormGroups.hob.value.enabled
      ? (param.cutoutSettingsFormGroups.hob.value.type as string)
      : null,
    hob_order_nr: param.cutoutSettingsFormGroups.hob.value.enabled
      ? (param.cutoutSettingsFormGroups.hob.value.order_number as string)
      : null,
    ind_hob_text: param.cutoutSettingsFormGroups.hob.value.enabled
      ? getIndividualText(
          param.cutoutSettingsFormGroups.hob.value.is_ind,
          param.cutoutSettingsFormGroups.hob.value.ind_text
        )
      : null,
    specify_hob_later: param.cutoutSettingsFormGroups.hob.value.enabled
      ? param.cutoutSettingsFormGroups.hob.value.specify_later
      : null,

    // downdraft
    downdraft_brand: param.cutoutSettingsFormGroups.downdraft.value.enabled
      ? (param.cutoutSettingsFormGroups.downdraft.value.brand as string)
      : null,
    downdraft_installation_type: param.cutoutSettingsFormGroups.downdraft.value
      .enabled
      ? (param.cutoutSettingsFormGroups.downdraft.value
          .installation_type as string)
      : null,
    downdraft_type: param.cutoutSettingsFormGroups.downdraft.value.enabled
      ? (param.cutoutSettingsFormGroups.downdraft.value.type as string)
      : null,
    downdraft_order_nr: param.cutoutSettingsFormGroups.downdraft.value.enabled
      ? (param.cutoutSettingsFormGroups.downdraft.value.order_number as string)
      : null,
    ind_downdraft_text: param.cutoutSettingsFormGroups.downdraft.value.enabled
      ? getIndividualText(
          param.cutoutSettingsFormGroups.downdraft.value.is_ind,
          param.cutoutSettingsFormGroups.downdraft.value.ind_text
        )
      : null,
    specify_downdraft_later: param.cutoutSettingsFormGroups.downdraft.value
      .enabled
      ? param.cutoutSettingsFormGroups.downdraft.value.specify_later
      : null,

    // energy box
    energy_box_brand: param.cutoutSettingsFormGroups.energy_box.value.enabled
      ? (param.cutoutSettingsFormGroups.energy_box.value.brand as string)
      : null,
    energy_box_installation_type: param.cutoutSettingsFormGroups.energy_box
      .value.enabled
      ? (param.cutoutSettingsFormGroups.energy_box.value
          .installation_type as string)
      : null,
    energy_box_type: param.cutoutSettingsFormGroups.energy_box.value.enabled
      ? (param.cutoutSettingsFormGroups.energy_box.value.type as string)
      : null,
    energy_box_order_nr: param.cutoutSettingsFormGroups.energy_box.value.enabled
      ? (param.cutoutSettingsFormGroups.energy_box.value.order_number as string)
      : null,
    ind_energy_box_text: param.cutoutSettingsFormGroups.energy_box.value.enabled
      ? getIndividualText(
          param.cutoutSettingsFormGroups.energy_box.value.is_ind,
          param.cutoutSettingsFormGroups.energy_box.value.ind_text
        )
      : null,
    specify_energy_box_later: param.cutoutSettingsFormGroups.energy_box.value
      .enabled
      ? param.cutoutSettingsFormGroups.energy_box.value.specify_later
      : null,

    // Step 3
    // (files are uploaded directly to the server)
    installation_gap: param.order.installation_gap,

    // Step 4
    customer_city_delivery: param.order.customer_city_delivery,
    customer_country_delivery: param.order.customer_country_delivery,
    specify_customer_data_later: param.order.specify_customer_data_later,
    customer_email_delivery: param.order.customer_email_delivery,
    customer_mobile_delivery: param.order.customer_mobile_delivery,
    customer_name_delivery: param.order.customer_name_delivery,
    customer_street_delivery: param.order.customer_street_delivery,
    customer_floor_delivery: param.order.customer_floor_delivery,
    customer_tel_delivery: param.order.customer_tel_delivery,
    customer_zip_delivery: param.order.customer_zip_delivery,
    delivery_type: param.order.delivery_type,
    measure_nature: param.order.measure === 'natural',
    measure_stencil: param.order.measure === 'template',
    measure_delivery: param.order.measure_delivery,
    specify_plan_dimension_later: param.order.specify_plan_dimension_later,
    montage_package_connection: param.order.montage_package_connection,
    montage_package_connection_downdraft:
      param.order.montage_package_connection_downdraft,
    montage_package_connection_multi:
      param.order.montage_package_connection_multi,
    warehouse_address: param.order.warehouse_address,
    warehouse_address_retail: param.order.warehouse_address_retail,
    warehouse_city: param.order.warehouse_city,
    warehouse_country: param.order.warehouse_country,
    warehouse_email_retail: param.order.warehouse_email_retail,
    warehouse_mobile_retail: param.order.warehouse_mobile_retail,
    warehouse_tel_retail: param.order.warehouse_tel_retail,
    warehouse_zip: param.order.warehouse_zip,

    // Step 5
    comment: param.order.comment,
    status_clarification: null,
    agb: param.order.agb,
    data_protection: param.order.data_protection,

    material_selection_type: materialSelectionTypeConverter.fromForm(
      param.order.material_selection_type,
      param.order.photos_email_send
    ),
    material_selection_date: null, // todo feature not implemented yet
    material_selection_confirmed: null,
    material_selection_photos_sent: null,
    material_selection_customer_visit: null,

    deadline_stencil: param.order.deadline_stencil,
    deadline_changes: param.order.deadline_changes,
    deadline_address: param.order.deadline_address,
    deadline_sinktype: param.order.deadline_sinktype,
    deadline_hobtype: param.order.deadline_hobtype,
    deadline_downdraft: param.order.deadline_downdraft,
    deadline_energybox: param.order.deadline_energybox,
    deadline_material_inspection: param.order.deadline_material_inspection,
    deadline_plan_dimension: param.order.deadline_plan_dimension,
    deadline_natural_measure: param.order.deadline_natural_measure,
    nature_measure_date:
      param.order.measure === 'natural'
        ? param.order.nature_measure_date
        : null,
    earliest_delivery_date: null,
    creation_date: null,
    digistoneRefNr: 'Digistone',
    buyer_info: null,
    sales_tel: null,
    lock: null,
    action_needed: isActionNeeded(param.order, param.cutoutSettingsFormGroups),
    purchase_price: null,
    sales_price: null,
    status: null,
    abasID: null,
    versionn: param.orderVersion,
    is_digistone_order: true,
  };
}

// INFO: Keep properties in sync with transformer 'action_needed_transformer.js' in the dcupl repository
function isActionNeeded(
  order: DsOrderFormData,
  cutoutSettingsFormGroups: DsOrderCutoutSettingsFormGroups
): boolean {
  return !!(
    order.specify_customer_data_later ||
    order.specify_plan_dimension_later ||
    cutoutSettingsFormGroups.sink.value.specify_later ||
    cutoutSettingsFormGroups.hob.value.specify_later ||
    cutoutSettingsFormGroups.downdraft.value.specify_later ||
    cutoutSettingsFormGroups.energy_box.value.specify_later
  );
}
