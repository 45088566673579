import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@ds-guards';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'design-system',
    loadChildren: () =>
      import('./designsystem/designsystem.module').then(
        (m) => m.DesignsystemModule
      ),
    canActivate: [AuthenticationGuard], // Remove authentication for design system after go live
  },
  {
    path: '',
    loadChildren: () =>
      import('./digistone-app/digistone-app.module').then(
        (m) => m.DigistoneAppModule
      ),
  },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
