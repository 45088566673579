import { PipeService } from '@ds-pipes';
import { DsOrderDefinition, DsOrderOrDraftOverview } from '@ds-types';

export type DsTableItem = DsOrderOrDraftOverview;

export type DsTableColumnKey = DsOrderDefinition;

export type DsTableConstructor<TableColumnKey extends DsTableColumnKey> = {
  pipeService: PipeService;
};

export type DsTableColumn<Key extends DsTableColumnKey> = {
  key: Key;
  sticky?: boolean;
  stickyEnd?: boolean;
  label?: string;
  formatPipe?: 'date';
  width?: string;
  cssClasses?: string[];
  align?: DsTableColumnAlign;
};

export type DsTableColumnAlign = 'start' | 'center' | 'end';

export type DsDataSort<ColumnKey extends DsTableColumnKey> = {
  column: ColumnKey;
  direction: DsSortDirection;
};

export type DsSortDirection = 'ASC' | 'DESC';

export type DsSort =
  | 'delivery_date'
  | 'order_number_asc'
  | 'order_number_desc'
  | 'commission_name_asc'
  | 'commission_name_desc';
