<ds-dialog>
  <ng-container id="header">
    <div class="flex">
      <span>{{ 'order-form.contact-form-dialog.headline' | translate }}</span>
    </div>
  </ng-container>

  <ng-container>
    <div class="min-h-[10rem]">
      <ds-form-field [formField]="formFieldContactMessage" [form]="formGroup"></ds-form-field>
    </div>
  </ng-container>

  <ng-container id="footer">
    <div class="flex justify-between">
      <button ds-button type="button" variant="link" (click)="onCloseClick()">{{ 'actions.close' | translate }}</button>
      <button (click)="onSendClick()" ds-button type="button" [elastic]="true" [isLoading]="isEmailSending" [isDisabled]="isEmailSending">{{ 'order-form.contact-form-dialog.send' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
