import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DS_ENVIRONMENT } from '@ds-environment';
import { AuthenticationService } from '@ds-services';
import {
  DS_API_ENDPOINTS,
  DS_API_ENDPOINTS_FILE_UPLOAD,
  DsApiParams,
} from '@ds-types';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  private async _get<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams,
    authOff?: boolean,
    mode?: 'file'
  ): Promise<ResponseData> {
    const baseUrl =
      mode === 'file' ? DS_ENVIRONMENT.api.fileUrl : DS_ENVIRONMENT.api.baseUrl;

    const url = `${baseUrl}${endpoint}${params?.path ?? ''}`;
    const headers = new HttpHeaders(
      authOff
        ? {}
        : {
            Authorization: `Bearer ${await this.authenticationService.getAuthToken()}`,
          }
    );

    return firstValueFrom(
      this.httpClient.get<ResponseData>(url, { headers, responseType: 'json' })
    );
  }

  public async get<ResponseData>(
    endpoint: DS_API_ENDPOINTS,
    params?: DsApiParams,
    authOff?: boolean
  ): Promise<ResponseData> {
    return this._get(endpoint, params, authOff);
  }

  public async getF<ResponseData>(
    endpoint: DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams,
    authOff?: boolean
  ): Promise<ResponseData> {
    return this._get(endpoint, params, authOff, 'file');
  }

  private async _getBlob(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams,
    mode?: 'file'
  ): Promise<Blob> {
    const baseUrl =
      mode === 'file' ? DS_ENVIRONMENT.api.fileUrl : DS_ENVIRONMENT.api.baseUrl;
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.get(`${baseUrl}${endpoint}${params?.path ?? ''}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
    );
  }

  public async getBlob(
    endpoint: DS_API_ENDPOINTS,
    params?: DsApiParams
  ): Promise<Blob> {
    return this._getBlob(endpoint, params);
  }

  public async getBlobF(
    endpoint: DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams
  ): Promise<Blob> {
    return this._getBlob(endpoint, params, 'file');
  }

  private async _post<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    data: unknown,
    mode?: 'file'
  ): Promise<ResponseData> {
    const baseUrl =
      mode === 'file' ? DS_ENVIRONMENT.api.fileUrl : DS_ENVIRONMENT.api.baseUrl;
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.post<ResponseData>(`${baseUrl}${endpoint}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }

  public async post<ResponseData>(
    endpoint: DS_API_ENDPOINTS,
    data: unknown
  ): Promise<ResponseData> {
    return this._post(endpoint, data);
  }

  public async postF<ResponseData>(
    endpoint: DS_API_ENDPOINTS_FILE_UPLOAD,
    data: unknown
  ): Promise<ResponseData> {
    return this._post(endpoint, data, 'file');
  }

  private async _delete<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams,
    mode?: 'file'
  ): Promise<ResponseData> {
    const baseUrl =
      mode === 'file' ? DS_ENVIRONMENT.api.fileUrl : DS_ENVIRONMENT.api.baseUrl;
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.delete<ResponseData>(
        `${baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  }

  public async delete<ResponseData>(
    endpoint: DS_API_ENDPOINTS,
    params?: DsApiParams
  ): Promise<ResponseData> {
    return this._delete(endpoint, params);
  }

  public async deleteF<ResponseData>(
    endpoint: DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams
  ): Promise<ResponseData> {
    return this._delete(endpoint, params, 'file');
  }
}
