import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DsOrderDeleteDialogInput, DsOrderDeleteDialogOutput } from '@ds-types';

@Component({
  selector: 'ds-order-delete-dialog',
  templateUrl: './order-delete-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDeleteDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsOrderDeleteDialogInput,
    private dialogRef: MatDialogRef<
      DsOrderDeleteDialogInput,
      DsOrderDeleteDialogOutput
    >
  ) {}

  ngOnInit(): void {}

  public onDeleteClick(): void {
    this.dialogRef.close({ status: 'delete' });
  }

  public onCancelClick(): void {
    this.dialogRef.close({ status: 'cancel' });
  }
}
