<ds-dialog>
  <ng-container id="header">{{ data.headlineLabel | translate }}</ng-container>

  <p [innerHTML]="data.contentLabel | translate"></p>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button ds-button type="button" (click)="onCloseClick()">{{ 'actions.close' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
