import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dsHighlightText',
})
export class DsHighlightTextPipe implements PipeTransform {
  public transform(text: string, highlightedText: string): string {
    if (highlightedText.length === 0) {
      return text;
    }

    const highlightedTextRegex = new RegExp(highlightedText, 'ig');
    return text.replace(highlightedTextRegex, '<mark>$&</mark>');
  }
}
