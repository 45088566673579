import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SidebarService } from '@ds-services';
import { DsSidebarType } from '@ds-types';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'ds-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input({ required: true }) type!: DsSidebarType;
  @Input() htmlId?: string;

  public isOpen = false;

  private subscriptions = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.listenForOpen();
    this.listenForKeyboard();
    this.listenForClick();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForOpen(): void {
    this.subscriptions.add(
      this.sidebarService.activeSidebar$.subscribe((activeSidebar) => {
        this.isOpen =
          activeSidebar?.type === this.type &&
          (!this.htmlId || this.htmlId === activeSidebar.htmlId);
        this.cdRef.detectChanges();
      })
    );
  }

  private listenForKeyboard(): void {
    this.subscriptions.add(
      fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event) => {
        if (event.key === 'Escape') {
          this.sidebarService.close();
        }
      })
    );
  }

  private listenForClick(): void {
    this.subscriptions.add(
      fromEvent<MouseEvent>(document, 'click').subscribe((event) => {
        const target = event.target as HTMLElement;
        if (target.closest('.js-sidebar') || target.closest('button')) return;
        this.sidebarService.close();
      })
    );
  }

  public onCloseClick(): void {
    this.sidebarService.close();
  }
}
