import { Injectable } from '@angular/core';
import { DsSidebar } from '@ds-types';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private activeSidebar$$ = new BehaviorSubject<DsSidebar | null>(null);

  constructor() {}

  get activeSidebar$(): Observable<DsSidebar | null> {
    return this.activeSidebar$$.asObservable().pipe(distinctUntilChanged());
  }

  public setActiveSidebar(value: DsSidebar): void {
    document.body.classList.add('overflow-hidden');
    document.body.style.height = 'inherit';
    document.documentElement.style.height = 'inherit';
    this.activeSidebar$$.next(value);
  }

  public close(): void {
    document.body.classList.remove('overflow-hidden');
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';
    this.activeSidebar$$.next(null);
  }
}
