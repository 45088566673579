<ds-dialog>
  <ng-container id="header">
    <div class="flex">
      <ds-icon name="error-24" [size]="24" class="mr-2 text-error-500"></ds-icon>
      <span>{{ 'order-form.step3.file-error-dialog.headline' | translate }}</span>
    </div>
  </ng-container>

  <div *ngIf="data.type === 'fileLimitReached'">
    <p>{{ 'order-form.step3.file-error-dialog.errors.too-many-files' | translate }}</p>
  </div>
  <div *ngIf="data.type === 'files'" class="space-y-4">
    <div *ngFor="let fileError of getVisibleFileErrors(); trackBy: trackByFileError">
      <p class="font-bold mb-2">{{ getFileErrorLabelKey(fileError) | translate }}:</p>
      <ul class="ds-list">
        <li *ngFor="let file of getFilesByError(fileError); trackBy: trackByFile" class="flex">
          <span class="mr-1">{{ file.name }}</span>
          <span class="text-gray-600">({{ file.size | dsFileSize }})</span>
        </li>
      </ul>
    </div>
  </div>

  <ng-container id="footer">
    <div class="text-right">
      <button ds-button type="button" (click)="onContinueClick()" [elastic]="true">{{ 'actions.next' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
