import { DS_ENVIRONMENT } from '@ds-environment';

export function getMaterialImageUrl(materialNumber: string): string {
  return `${DS_ENVIRONMENT.assets.baseUrl}${DS_ENVIRONMENT.assets.materials}${materialNumber}.jpg`;
}

export function getDocumentUrl(documentName: string): string {
  return `${DS_ENVIRONMENT.assets.baseUrl}${DS_ENVIRONMENT.assets.documents}${documentName}`;
}

export function getEdgeTreatmentImageUrl(edgeTreatment: string): string {
  return `${DS_ENVIRONMENT.assets.baseUrl}${DS_ENVIRONMENT.assets.edgeTreatment}${edgeTreatment}.png`;
}
