import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ds-ui/icon';
import { TooltipModule } from '@ds-ui/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { RadioComponent } from './radio.component';

@NgModule({
  declarations: [RadioComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    TooltipModule,
    IconModule,
  ],
  exports: [RadioComponent],
})
export class RadioModule {}
