import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DsFormFieldExtComponent,
  DsFormFieldRadioGroup,
  DsFormValue,
  DsRadioOption,
} from '@ds-form';
import { getUniqueId } from '@ds-helpers';
import { DsIcon } from '@ds-types';

@Component({
  selector: 'ds-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent<K extends string, OK extends DsFormValue>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) formField!: DsFormFieldRadioGroup<K, OK>;

  public groupId = getUniqueId();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public trackByOption(index: number, option: DsRadioOption<OK>): string {
    return option.key.toString();
  }

  public getIcon(option: DsRadioOption<OK>): DsIcon | undefined {
    // check if prop icon exists
    if ('icon' in option) {
      return option.icon;
    }
    return undefined;
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.formField.variant === 'tile') {
      classes.push('flex', '-m-2', 'flex-wrap');
    } else if (this.formField.variant === 'chip') {
      classes.push('flex', '-m-1', 'flex-wrap');
    } else {
      classes.push('space-y-2');
    }

    return classes;
  }

  public getOptionClasses(): string[] {
    const classes = [];

    if (this.formField.variant === 'tile') {
      classes.push('flex-1', 'm-2');
    }
    if (this.formField.variant === 'chip') {
      classes.push('m-1');
    }

    return classes;
  }
}
