import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactFormDialogModule } from './contact-form-dialog';
import { ContactSuccessDialogModule } from './contact-success-dialog';
import { DeliveryInfoDialogModule } from './delivery-info-dialog';
import { DraftSuccessDialogModule } from './draft-success-dialog';
import { ErrorDialogModule } from './error-dialog';
import { FileErrorDialogModule } from './file-error-dialog';
import { FormCancelDialogModule } from './form-cancel-dialog';
import { FormDeactivateDialogModule } from './form-deactivate-dialog';
import { FormSaveDialogModule } from './form-save-dialog';
import { InfoDialogModule } from './info-dialog';
import { MaterialApplyDialogModule } from './material-apply-dialog';
import { OrderDeleteDialogModule } from './order-delete-dialog';

@NgModule({
  imports: [
    CommonModule,
    FormCancelDialogModule,
    MaterialApplyDialogModule,
    FileErrorDialogModule,
    DeliveryInfoDialogModule,
    ErrorDialogModule,
    DraftSuccessDialogModule,
    OrderDeleteDialogModule,
    InfoDialogModule,
    FormSaveDialogModule,
    ContactFormDialogModule,
    ContactSuccessDialogModule,
    FormDeactivateDialogModule,
  ],
})
export class DialogAllModule {}
