import { DsOrderApi } from './order.type';

export enum DS_API_ENDPOINTS {
  dateCalculation = '/date-calculation',
  drafts = '/api/drafts',
  orders = '/api/orders',
  sendEmail = '/api/utils/email',
  convertToOrder = '/api/orders/{orderNumber}/convert',
  warehouseAddresses = '/api/customers/{customerId}/warehouses',
  maintenance = '/maintenance',
}

export enum DS_API_ENDPOINTS_FILE_UPLOAD {
  fileUpload = '/s3',
  fileDelete = '/s3/delete',
  fileDownload = '/s3/download',
  maintenanceImport = '/maintenance/import',
}

export type DsApiParams = {
  path?: string;
};

export type DsApiDateCalculationResponse =
  | {
      status: 'OK';
      data: {
        title: string;
      };
    }
  | {
      status: 'ERROR';
    };

export type DsApiFileUploadPayload = {
  file: File;
  orderNumber?: string;
};

export type DsApiFile = {
  path: string;
  size: number;
  name: string;
};

export type DsApiFileRemoveResponse = {
  status: '200';
  message: string;
};

export type DsApiWarehouseAddressResponse = {
  default_address: boolean;
  customerId: string;
  warehouseId: string;
  fullName: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
  phone: string;
  mobile: string;
  email: string;
};

export type DsApiOrderResponse =
  | {
      status: 'success';
      data: DsOrderApi;
    }
  | {
      status: 'error';
      data?: DsOrderApi;
      error: {
        code:
          | 400 // Outdated order version
          | 403 // No edit possible (Abas lock)
          | 423 // Order lock is true (Internal lock)
          | 503 // File upload (from backend to abas) failed
          | 500; // General Error
        message: string;
      };
    };

// Contact Form Dialog
export type DsApiContactFormResponse = {
  code: '200' | '500';
  message: string;
};

// Maintenance Mode GET Response
export type DsApiMaintenanceResponse = {
  active: boolean;
  end: Date;
  show: boolean;
  start: Date;
};
