import { DsEnvironment } from '@ds-types';

export const DS_ENVIRONMENT: DsEnvironment = {
  env: 'dev',
  dcupl: {
    baseUrl: 'https://api.dev-digistone.strasser-steine.at/dcupl',
    apiUrl: 'https://api.dev-digistone.strasser-steine.at/api',
  },
  api: {
    baseUrl: 'https://api.dev-digistone.strasser-steine.at',
    fileUrl: 'https://api.dev-digistone.strasser-steine.at',
  },
  keycloak: {
    url: 'https://account-demo.strasser-steine.at/auth',
    realm: 'STRASSER-DEMO',
    clientId: 'DigiSTONE-frontend',
  },
  assets: {
    baseUrl: 'https://assets.digistone.strasser-steine.at',
    materials: '/images/materials/',
    documents: '/documents/',
    edgeTreatment: '/images/edge-treatment/',
  },
};
