import { Component, OnInit } from '@angular/core';
import { IconsRegistry, MaintenanceModeService } from '@ds-services';
import { completeIconSet } from '@ds-types';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';

@Component({
  selector: 'ds-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private iconRegistry: IconsRegistry,
    private maintenanceModeService: MaintenanceModeService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.maintenanceModeService.setMaintenanceData();
    this.iconRegistry.registerIcons(completeIconSet);

    setDefaultOptions({ locale: de });
  }
}
