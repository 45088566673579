import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { SelectMaterialComponent } from './select-material.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonModule],
  declarations: [SelectMaterialComponent],
  exports: [SelectMaterialComponent],
})
export class SelectMaterialModule {}
