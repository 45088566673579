<ds-dialog>
  <ng-container id="header">{{ 'dialog.delivery.headline' | translate }}</ng-container>

  <p class="mb-4">
    {{ 'dialog.delivery.subline' | translate }}
  </p>
  <p class="mb-4">
    {{ 'dialog.delivery.line1' | translate }}
  </p>
  <ul class="ds-list">
    <li>
      {{ 'dialog.delivery.line2' | translate }}
    </li>
    <li>
      {{ 'dialog.delivery.line3' | translate }}
    </li>
    <li>
      {{ 'dialog.delivery.line4' | translate }}
    </li>
  </ul>
  <p class="mt-4">
    {{ 'dialog.delivery.line5' | translate }}
  </p>
  <p class="mt-4">
    {{ 'dialog.delivery.line6' | translate }}
  </p>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button ds-button type="button" (click)="onCloseClick()">{{ 'actions.close' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
