import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { IconModule } from '@ds-ui/icon';
import { ToastComponent } from './toast.component';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, IconModule, ButtonModule],
  exports: [ToastComponent],
})
export class ToastModule {}
