import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { InfoboxModule } from '@ds-ui/infobox/infobox.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormDeactivateDialogComponent } from './form-deactivate-dialog.component';

@NgModule({
  declarations: [FormDeactivateDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    InfoboxModule,
  ],
  exports: [FormDeactivateDialogComponent],
})
export class FormDeactivateDialogModule {}
