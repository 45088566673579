import { DsBoardType } from '@ds-types';

export type DsMaterial = {
  number: string;
  name: string;
  groupname: string;
};

export type DsMaterialDcupl = {
  key: string;
  sn: string;
  nummer: string;
  name: string;
  ynoberfl: string;
  ynarth: string;
  yvbez: string;
  ymatgrp: string;
  ymatgrpn: string;
};

export type DsMaterialGroup = {
  name: string;
  materials: DsMaterial[];
};

export type DsMaterialChangedPayload = {
  boardType: DsBoardType;
  materialNumber: unknown;
};
