import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { IconModule } from '@ds-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DsPipesModule } from '@ds-pipes';
import { ContactFormDialogComponent } from './contact-form-dialog.component';
import { FormModule } from '@ds-form';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    IconModule,
    DsPipesModule,
    FormModule,
  ],
  declarations: [ContactFormDialogComponent],
  exports: [ContactFormDialogComponent],
})
export class ContactFormDialogModule {}
