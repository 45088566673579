<div *ngIf="!edgeTreatmentOption">
  <button icon="search-16" (click)="onSelectEdgeTreatmentClick()" variant="link" ds-button type="button">{{ 'order-form.step2.material-specifications-general.edge-treatment.choose-edge' | translate }}</button>
</div>
<div *ngIf="edgeTreatmentOption">
  <div class="md:grid md:grid-cols-2">
    <div class="md:col-span-2 flex justify-between">
      <div class="mr-4 mb-4 w-24 h-24 bg-[length:85%] bg-center bg-no-repeat shrink-0 bg-gray-200 p-6 rounded" [style]="'background-image: url(' + edgeTreatmentService.getEdgeTreatmentOptionImageURL(edgeTreatmentOption.key) + ')'"></div>
      <div>
        <div class="text-left md:text-right mt-4 md:mt-0">
          <button ds-button type="button" variant="link" iconPos="left" icon="edit-16" (click)="onChangeEdgeTreatmentClick()">{{ 'order-form.step2.material-specifications-general.edge-treatment.edit' | translate }}</button>
        </div>
      </div>
    </div>
    <div>
      <p class="font-bold text-gray-800 uppercase">{{ edgeTreatmentOption.name1 }}</p>
      <p>{{ edgeTreatmentService.getSanitizedEdgeTreatmentKey(edgeTreatmentOption.key) }}</p>
    </div>
  </div>
</div>
