import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DsFormFieldExtComponent, DsFormFieldToggle } from '@ds-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent<K extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) formField!: DsFormFieldToggle<K>;
  @Input() isDisabled = false;

  public isChecked = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.formControl.value;
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((value) => {
        this.isChecked = value;
        this.cdRef.markForCheck();
      })
    );
  }

  public onChange(): void {
    this.formControl.patchValue(!this.isChecked);
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (this.isChecked) {
      classes.push('font-bold');
    }

    if (this.isDisabled) {
      classes.push('text-gray-600');
    }

    return classes;
  }
}
