import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DsFormFieldExtComponent,
  DsFormFieldSelect,
  DsFormValue,
  DsSelectOption,
} from '@ds-form';
import { DsTheme } from '@ds-types';
import { compact, isArray } from 'lodash-es';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'ds-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<
    FieldKey extends string,
    OptionKey extends DsFormValue,
  >
  extends DsFormFieldExtComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() formField?: DsFormFieldSelect<FieldKey, OptionKey>;
  @Input() isDisabled = false;
  @Input() inputOptions?: DsSelectOption<OptionKey>[];
  @Input() theme: DsTheme = 'on-white';

  public selectedOption?: DsSelectOption<OptionKey>;

  public options: DsSelectOption<OptionKey>[] = [];

  public value?: DsFormValue;

  @Output() private selectedChange = new EventEmitter<
    DsSelectOption<OptionKey>
  >();

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((value) => {
        this.setSelectedOption(value);
      })
    );

    this.setSelectedOption(this.formControl.value);

    this.subscriptions.add(
      this.formControl.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newOptions: DsSelectOption<OptionKey>[] | undefined = undefined;
    if (
      changes['formField'] &&
      this.formField &&
      isArray(this.formField?.options)
    ) {
      newOptions = this.formField.options;
    } else if (changes['inputOptions'] && isArray(this.inputOptions)) {
      newOptions = this.inputOptions;
    }

    if (newOptions) {
      newOptions = compact([
        this.formField?.hasEmptyField
          ? {
              key: '' as OptionKey,
              label: 'please-choose',
            }
          : undefined,
        ...newOptions,
      ]);

      if (
        newOptions.length !== this.options.length ||
        newOptions.some((option, i) => option.key !== this.options[i].key)
      ) {
        this.options = newOptions;

        // If only one option is available, preselect it automatically
        if (
          newOptions.length === 1 &&
          this.formControl.value !== newOptions[0].key
        ) {
          const singleOption = newOptions[0];
          this.formControl.patchValue(singleOption.key, { emitEvent: false });
          this.selectedOption = singleOption;
          this.selectedChange.emit(singleOption);
          this.value = singleOption.key;
        }
      }

      this.setSelectedOption(this.formControl.value);
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSelectedOption(key: DsFormValue): void {
    this.selectedOption = this.options.find((item) => item.key === key ?? '');
    this.value = this.selectedOption?.key;
  }

  public onChange(option: DsFormValue): void {
    const selectedOption = this.options.find(
      (o) => o.key.toString() === option.toString()
    );

    this.selectedOption = selectedOption;

    this.selectedChange.emit(selectedOption);

    const value = this.selectedOption?.key;

    if (value === 'true') {
      this.formControl.patchValue(true);
    } else if (value === 'false') {
      this.formControl.patchValue(false);
    } else {
      this.formControl.patchValue(value);
    }

    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  public trackById(index: number, item: DsSelectOption<OptionKey>): OptionKey {
    return item.key;
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }

    if (this.theme === 'on-gray') {
      classes.push('on-gray');
    }

    return classes;
  }

  public onBlur(): void {
    this.formControl.markAsTouched();
  }
}
