import { Injectable } from '@angular/core';
import { DsDatePipe } from './date.pipe';

@Injectable({
  providedIn: 'root',
})
export class PipeService {
  private readonly datePipe: DsDatePipe;

  constructor() {
    this.datePipe = new DsDatePipe();
  }

  get datePipe$(): DsDatePipe {
    return this.datePipe;
  }
}
