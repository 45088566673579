<div class="flex items-start">
  <label [ngClass]="getRadioClasses()" [for]="htmlId">
    <div class="ds-radio-alignment">
      <input type="radio" name="radio_{{ groupId }}" [id]="htmlId" [value]="option.key" [checked]="isChecked()" (change)="onChange($event)" />
      <div>
        <div class="shrink-0" *ngIf="icon">
          <ds-icon class="ds-radio-icon" [name]="icon" [size]="36"></ds-icon>
        </div>
        <span class="ds-radio-label typo-md"
          ><span class="mr-1">{{ option.label | translate }}</span>
        </span>
      </div>
    </div>
  </label>
  <ds-tooltip placement="auto" *ngIf="option.tooltipLabel && formField.variant === 'default'">
    <ng-container id="target">
      <ds-icon class="p-0.5 mt-px" name="info-16"></ds-icon>
    </ng-container>
    <div [innerHTML]="option.tooltipLabel | translate"></div>
  </ds-tooltip>
</div>
