import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@ds-ui/button';
import { IconModule } from '@ds-ui/icon';
import { SidebarModule } from '@ds-ui/sidebar/sidebar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DsPipesModule } from '@ds-pipes';
import { CalendarComponent } from './calendar.component';

@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CommonModule,
    SidebarModule,
    TranslateModule,
    DsPipesModule,
    ButtonModule,
    IconModule,
  ],
  exports: [CalendarComponent],
})
export class CalendarModule {}
