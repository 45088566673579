import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DsFormField, DsFormFieldVariant, DsLabelSize } from '@ds-form';
import { getUniqueId } from '@ds-helpers';
import { DsTheme } from '@ds-types';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'ds-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent<K extends string> implements OnInit, OnDestroy {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) formField!: DsFormField<K>;
  @Input() labelSize: DsLabelSize = 'M';
  @Input() variant: DsFormFieldVariant = 'column';
  @Input() isDisabled = false;
  @Input() isReadonly = false;
  @Input() theme: DsTheme = 'on-white';
  public htmlId = getUniqueId();

  public isRequired = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isRequired = !!this.formField.required;

    this.subscriptions.add(
      this.form.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getFormControl(): FormControl | undefined {
    return this.form.get(this.formField.key) as FormControl | undefined;
  }

  private isTouched(): boolean {
    return !!this.getFormControl()?.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    const formControl = this.getFormControl();

    if (formControl?.errors) {
      return formControl?.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }

  public getCounter(): string | null {
    const maxLength =
      this.formField.type === 'text' || this.formField.type === 'textarea'
        ? this.formField.maxLength
        : undefined;

    if (!maxLength) {
      return null;
    }

    const formControl = this.getFormControl();

    if (formControl?.value) {
      return `${formControl.value.length}/${maxLength}`;
    }

    return `0/${maxLength}`;
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.variant === 'row' && this.formField.type !== 'textarea') {
      classes.push('flex', 'flex-col', 'sm:flex-row', 'sm:items-center');
    }

    if (this.variant === 'row' && this.formField.type === 'textarea') {
      classes.push('flex', 'flex-col', 'sm:flex-row', 'sm:items-start');
    }

    return classes;
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (this.variant === 'row') {
      classes.push('w-48');
    }

    return classes;
  }

  public getLabelInnerClasses(): string[] {
    const classes = [];

    if (this.variant === 'row') {
      classes.push('typo-md', 'font-bold', 'mr-4');
    } else if (this.labelSize === 'M') {
      classes.push('typo-label');
    } else if (this.labelSize === 'L') {
      classes.push('typo-h6');
    }

    if (this.isDisabled) {
      classes.push('text-gray-600');
    } else {
      classes.push('text-gray-800');
    }

    return classes;
  }
}
