export type DsAddress = {
  name: string;
  street: string;
  floor?: number;
  zip: string;
  city: string;
  country?: string;
  tel?: string;
  mobile?: string;
  email?: string;
};
