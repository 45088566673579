import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DsFormFieldExtComponent, DsFormFieldSelectCustomer } from '@ds-form';
import { DcuplService, SidebarService } from '@ds-services';
import { DsCustomer } from '@ds-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCustomerComponent<FieldKey extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() formField?: DsFormFieldSelectCustomer<FieldKey>;

  private subscriptions = new Subscription();

  public customer: DsCustomer | null = null;

  constructor(
    private sidebarService: SidebarService,
    private cdRef: ChangeDetectorRef,
    private dcuplService: DcuplService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async init(): Promise<void> {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe(async (key) => {
        this.customer =
          (await this.dcuplService.getCustomerByNumber(key)) ?? null;
        this.cdRef.detectChanges();
      })
    );

    if (this.formControl.value) {
      this.customer =
        (await this.dcuplService.getCustomerByNumber(this.formControl.value)) ??
        null;

      this.cdRef.detectChanges();
    }
  }

  public onChangeClick(): void {
    this.sidebarService.setActiveSidebar({
      type: 'customer',
    });
  }

  public onSelectClick(): void {
    this.sidebarService.setActiveSidebar({
      type: 'customer',
    });
  }
}
