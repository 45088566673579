<ds-dialog>
  <ng-container id="header">{{ 'order-form.step2.material-apply-dialog.headline' | translate }}</ng-container>

  <p>{{ 'order-form.step2.material-apply-dialog.text' | translate }}</p>

  <ul class="ds-list mt-4">
    <li *ngFor="let boardType of data.toApplyBoardTypes; trackBy: trackByBoardType">
      <p>{{ getBoardTypeLabelKey(boardType) | translate }}</p>
    </li>
  </ul>

  <ng-container id="footer">
    <div class="flex items-center justify-between flex-col-reverse sm:flex-row">
      <button ds-button type="button" variant="link" (click)="onApplyClick()" [elastic]="true">{{ 'order-form.step2.material-apply-dialog.apply' | translate }}</button>
      <button ds-button type="button" (click)="onCancelClick()" [elastic]="true">{{ getCancelButtonLabelKey() | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
