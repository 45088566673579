import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ds-delivery-info-dialog',
  templateUrl: './delivery-info-dialog.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryInfoDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<unknown>) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
