import { Component, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  template: '',
})
export abstract class DsFormFieldExtComponent {
  @Input({ required: true }) formControl!: FormControl;

  @Input() htmlId?: string;

  protected isTouched(): boolean {
    return this.formControl.touched;
  }

  protected getErrors(): ValidationErrors | undefined {
    if (this.formControl.errors) {
      return this.formControl.errors;
    }

    return undefined;
  }

  protected shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
