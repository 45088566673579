export type DsToastVariant = 'success' | 'warning' | 'error' | 'info';

export type DsToastPayload = {
  variant: DsToastVariant;
  title: string;
  message: string;
  cta1?: {
    label: string;
    callback: () => void;
  };
  cta2?: {
    label: string;
    callback: () => void;
  };
};

export type DsToastDuration = 7; // in seconds

export const DS_TOAST_DURATION_DEFAULT: DsToastDuration = 7;
