import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  private activeAutocomplete$$ = new BehaviorSubject<string | null>(null);

  constructor() {}

  get activeAutocomplete$(): Observable<string | null> {
    return this.activeAutocomplete$$
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public setActiveAutocomplete(value: string): void {
    this.activeAutocomplete$$.next(value);
  }

  public close(): void {
    this.activeAutocomplete$$.next(null);
  }
}
