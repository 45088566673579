export type DsCustomer = {
  id: string;
  number: string;
  billingName: string;
  billingStreet: string;
  billingZip: string;
  billingCity: string;
  billingCountry: string;
  billingCountrycode?: string;
  billingPhone?: string;
  billingMobile?: string;
  billingEmail?: string;
  warehouseName: string;
  warehouseStreet: string;
  warehouseZip: string;
  warehouseCity: string;
  warehouseCountry: string;
  warehouseCountrycode?: string;
  warehousePhone?: string;
  warehouseMobile?: string;
  warehouseEmail?: string;
};

export type DsCustomerDcupl = {
  key: string;
  id: string;
  sn: string;
  billing_fullName: string;
  billing_street: string;
  billing_zip: string;
  billing_city: string;
  billing_country: string;
  billing_countryCode: string;
  billing_phone: string;
  billing_mobile: string;
  billing_mail: string;
  warehouse_fullName: string;
  warehouse_street: string;
  warehouse_zip: string;
  warehouse_city: string;
  warehouse_country: string;
  warehouse_countryCode: string;
  warehouse_phone: string;
  warehouse_mobile: string;
  warehouse_mail: string;
};

export type DsCustomerContact = {
  id: string;
  number: string;
  name: string;
  email: string;
};

export type DsCustomerContactDcupl = {
  key: string;
  sn: string;
  name: string;
  email: string;
};
