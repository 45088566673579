import { DsBoardType } from '@ds-types';

export type DsArticleDcupl = {
  key: string;
  name: string;
  yvmaterial: {
    key: string;
    nummer: string;
    name: string;
  };
  ynoberfl: string;
  yartber: DsArticleDcuplYartber;
  ynarth: string;
  'Kantenoption 1': DsArticleEdgeOption;
  'Kantenoption 2': DsArticleEdgeOption;
  'Kantenoption 3': DsArticleEdgeOption;
  'Kantenoption 4': DsArticleEdgeOption;
  'Kantenoption 5': DsArticleEdgeOption;
  'Kantenoption 6': DsArticleEdgeOption;
  'Kantenoption 7': DsArticleEdgeOption;
  'Kantenoption 8': DsArticleEdgeOption;
  'Kantenoption 9': DsArticleEdgeOption;
  'Kantenoption 10': DsArticleEdgeOption;
  'Kantenoption 11': DsArticleEdgeOption;
  'Kantenoption 12': DsArticleEdgeOption;
  'Kantenoption 52': DsArticleEdgeOption;
  Defaultkantenoption: DsArticleEdgeOption;
  'Default Artikel'?: 'ja';
  amount: number | undefined;
};

export type DsArticle = {
  name: string;
  thickness: string;
  surface: string;
  materialNumber: string;
  materialName: string;
  edges: DsArticleEdgeOption[];
  boardType: DsBoardType;
  amount: number;
};

export type DsArticleEdgeOption =
  | {
      key: string;
      name1: string;
    }
  | undefined;

export type DsArticleDcuplYartber = '1711' | '1733' | '1731' | '1721' | '1720';

export const DS_ARTICLE_PROJECTION = {
  $: true,
  'Kantenoption 1': {
    $: false,
    name1: true,
  },
  'Kantenoption 2': {
    $: false,
    name1: true,
  },
  'Kantenoption 3': {
    $: false,
    name1: true,
  },
  'Kantenoption 4': {
    $: false,
    name1: true,
  },
  'Kantenoption 5': {
    $: false,
    name1: true,
  },
  'Kantenoption 6': {
    $: false,
    name1: true,
  },
  'Kantenoption 7': {
    $: false,
    name1: true,
  },
  'Kantenoption 8': {
    $: false,
    name1: true,
  },
  'Kantenoption 9': {
    $: false,
    name1: true,
  },
  'Kantenoption 10': {
    $: false,
    name1: true,
  },
  'Kantenoption 11': {
    $: false,
    name1: true,
  },
  'Kantenoption 12': {
    $: false,
    name1: true,
  },
  'Kantenoption 52': {
    $: false,
    name1: true,
  },
  yvmaterial: {
    $: false,
    key: true,
    nummer: true,
    name: true,
  },
};

export type DsArticleView = DsArticle & {
  edge_treatment: string;
  article_max_length: boolean;
  key: string;
  position_number: string;
};
