import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DsFormFieldCheckbox, DsFormFieldExtComponent } from '@ds-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent<K extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) formField!: DsFormFieldCheckbox<K>;
  @Input() isReadonly = false;

  public isChecked = false;
  public isDisabled = false;
  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.formControl.value;

    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((value) => {
        this.isChecked = value;
        this.cdRef.detectChanges();
      })
    );
  }

  public onChange(): void {
    this.formControl.patchValue(!this.isChecked);
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (!this.isReadonly) {
      classes.push('cursor-pointer');

      if (this.isChecked) {
        classes.push('font-bold');
      }
    }

    return classes;
  }
}
