import { FormControl } from '@angular/forms';
import { DsArticleDcuplYartber } from '@ds-types';

export type DsBoardType = 'worktop' | 'backwall' | 'wiper' | 'sidepanel';

export type DsBoardMaterialFormControls = {
  [key in DsBoardType]: {
    boardTypeFormControl: FormControl;
    materialNumberFormControl: FormControl;
  };
};

export const DS_BOARD_TYPES_MAP: {
  [key in DsArticleDcuplYartber]?: DsBoardType;
} = {
  '1711': 'worktop',
  '1733': 'backwall',
  '1731': 'sidepanel',
  '1721': 'wiper',
};

export const DS_BOARD_TYPES: DsBoardType[] = [
  'worktop',
  'backwall',
  'sidepanel',
  'wiper',
];

export function convertBoardTypeToYartber(
  boardType: DsBoardType
): DsArticleDcuplYartber | undefined {
  const yartber = (
    Object.keys(DS_BOARD_TYPES_MAP) as DsArticleDcuplYartber[]
  ).find((key) => DS_BOARD_TYPES_MAP[key] === boardType);

  if (!yartber) {
    return undefined;
  }

  return yartber as DsArticleDcuplYartber;
}
