import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  public downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const tempElement = document.createElement('a');
    tempElement.href = url;
    tempElement.download = fileName;
    tempElement.click();
    window.URL.revokeObjectURL(url);
  }
}
