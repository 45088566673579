import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSuccessDialogComponent } from './contact-success-dialog.component';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { IconModule } from '@ds-ui/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    IconModule,
    TranslateModule,
  ],
  declarations: [ContactSuccessDialogComponent],
})
export class ContactSuccessDialogModule {}
