<div #targetAll>
  <div #target>
    <ng-content select="#target"></ng-content>
  </div>
  <span class="popper-wrapper relative z-50 inline-flex" #tooltip [ngClass]="getWrapperClasses()">
    <span class="popper relative inline-block rounded-md p-2">
      <span class="popper__arrow" data-popper-arrow></span>
      <div class="typo-md">
        <ng-content></ng-content>
      </div>
    </span>
  </span>
</div>
