export function scrollToError(formContainer: HTMLElement): void {
  const errorElement = formContainer.querySelector('.js-error-scroll');
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

export function getFloatAsFormattedString(value: number | string): string {
  const strVal: string = typeof value === 'string' ? value : value.toString();
  if (isNaN(parseFloat(strVal))) {
    return '';
  }
  return strVal.replace('.', ',');
}
