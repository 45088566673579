import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmExitGuardService {
  public enableGuard(): void {
    window.onbeforeunload = function (event) {
      event.preventDefault();
      event.returnValue = '';
    };
  }

  public disableGuard(): void {
    window.onbeforeunload = null;
  }
}
