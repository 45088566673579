import { DsArticleEdgeOption, DsBoardType, DsIcon } from '@ds-types';

export type DsFormValue = string | number | boolean;

export type DsFormField<K> =
  | DsFormFieldHidden<K>
  | DsFormFieldTextarea<K>
  | DsFormFieldText<K>
  | DsFormFieldSelect<K>
  | DsFormFieldCheckbox<K>
  | DsFormFieldRadioGroup<K>
  | DsFormFieldToggle<K>
  | DsFormFieldCalendar<K>
  | DsFormCustomFields<K>;

type DsFormCustomFields<K> =
  | DsFormFieldSelectCustomer<K>
  | DsFormFieldAutocompleteCustomer<K>
  | DsFormFieldSelectEdgeTreatment<K>
  | DsFormFieldSelectMaterial<K>;

export type DsRadioOption<Key extends DsFormValue> =
  | DsSelectOption<Key>
  | DsSelectOptionWithIcon<Key>;

export type DsSelectOption<Key extends DsFormValue> = {
  key: Key;
  label: string;
  tooltipLabel?: string;
  labelTotal?: number;
};

type DsSelectOptionWithIcon<OptionKey extends DsFormValue> =
  DsSelectOption<OptionKey> & {
    icon: DsIcon;
  };

export type DsAutocompleteOption = DsSelectOption<string> & {
  sublabel?: string;
};

type DsFormFieldBase<K> = {
  key: K;
  label?: string;
  placeholder?: string;
  required?: boolean;
  info?: string;
};

type DsFormFieldTextBase = {
  maxLength?: number;
  autocomplete?: string;
};

export type DsFormFieldSelect<
  K,
  OptionKey extends DsFormValue = DsFormValue,
> = DsFormFieldBase<K> & {
  type: 'select';
  options?: DsSelectOption<OptionKey>[];
  hasEmptyField?: boolean;
};

export type DsFormFieldAutocompleteCustomer<K> = DsFormFieldBase<K> & {
  type: 'autocomplete-customer';
};

export type DsFormFieldSelectCustomer<K> = DsFormFieldBase<K> & {
  type: 'select-customer';
};

export type DsFormFieldSelectMaterial<K> = DsFormFieldBase<K> & {
  type: 'select-material';
};

export type DsFormFieldSelectEdgeTreatment<K> = DsFormFieldBase<K> & {
  type: 'select-edge-treatment';
  options: DsArticleEdgeOption[];
  boardType: DsBoardType;
};

export type DsFormFieldRadioGroup<
  K,
  OptionKey extends DsFormValue = DsFormValue,
> =
  | DsFormFieldRadioGroupTile<K, OptionKey>
  | DsFormFieldRadioGroupChipAndDefault<K, OptionKey>;

export type DsFormFieldRadioGroupTile<
  K,
  OptionKey extends DsFormValue = DsFormValue,
> = DsFormFieldBase<K> & {
  type: 'radio-group';
  variant: 'tile';
  options: DsSelectOptionWithIcon<OptionKey>[];
};

export type DsFormFieldRadioGroupChipAndDefault<
  K,
  OptionKey extends DsFormValue = DsFormValue,
> = DsFormFieldBase<K> & {
  type: 'radio-group';
  variant?: 'default' | 'chip';
  options: DsSelectOption<OptionKey>[];
};

export type DsFormFieldCheckbox<K> = DsFormFieldBase<K> & {
  type: 'checkbox';
  innerLabel: string;
  tooltipLabel?: string;
};

export type DsFormFieldToggle<K> = DsFormFieldBase<K> & {
  type: 'toggle';
  innerLabel: string;
};

export type DsFormFieldCalendar<K> = DsFormFieldBase<K> & {
  type: 'calendar';
  calendarMode?: 'date' | 'week';
  minDate?: Date;
  maxDate?: Date;
  buttonLabel: string;
  sidebarHeadline: string;
};

export type DsFormFieldText<K = string> = DsFormFieldBase<K> &
  DsFormFieldTextBase & {
    type: 'text';
    textType?: DsFormFieldTextType;
  };

export type DsFormFieldHidden<K = string> = DsFormFieldBase<K> &
  DsFormFieldTextBase & {
    type: 'hidden';
  };

export type DsFormFieldTextType = 'text' | 'number' | 'date' | 'email';

export type DsFormFieldTextarea<K = string> = DsFormFieldBase<K> &
  DsFormFieldTextBase & {
    type: 'textarea';
  };

export type DsHtmlInputEvent = Event & {
  target: HTMLInputElement & EventTarget;
};

export type DsFormFields<K extends string> = {
  [key in K]: DsFormField<key>;
};

export type DsFormFieldVariant = 'column' | 'row';
