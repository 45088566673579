import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DsFormFieldTextarea } from '@ds-form';
import { ApiService } from '@ds-services';
import {
  DS_API_ENDPOINTS,
  DS_DIALOG_MAX_SIZE,
  DS_DIALOG_SIZE,
  DsApiContactFormResponse,
  DsContactFormDialogInput,
  DsErrorDialogInput,
} from '@ds-types';
import { ContactSuccessDialogComponent } from '../contact-success-dialog/contact-success-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'ds-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormDialogComponent implements OnInit {
  public formGroup = new FormGroup({
    contactMessage: new FormControl('', [Validators.required]),
  });

  public formFieldContactMessage: DsFormFieldTextarea = {
    key: 'contactMessage',
    type: 'textarea',
    label: 'order-form.contact-form-dialog.label',
    required: true,
  };

  public isEmailSending = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsContactFormDialogInput,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DsContactFormDialogInput>
  ) {}

  ngOnInit(): void {}

  public async onSendClick(): Promise<void> {
    this.formGroup.controls.contactMessage.markAsTouched();
    this.formGroup.controls.contactMessage.markAsDirty();
    this.formGroup.controls.contactMessage.updateValueAndValidity();
    this.cdRef.detectChanges();

    if (!this.formGroup.valid || this.isEmailSending) {
      return;
    }

    this.isEmailSending = true;
    this.cdRef.detectChanges();

    const formData = {
      order_number: this.data.order_number,
      comment: this.formGroup.value.contactMessage,
    };

    try {
      const response = await this.apiService.post<DsApiContactFormResponse>(
        DS_API_ENDPOINTS.sendEmail,
        formData
      );
      response.code === '200'
        ? this.openSuccessAfterEmailSent()
        : this.openEmailSentErrorOverlay();
    } catch (error) {
      this.openEmailSentErrorOverlay();
    }

    this.isEmailSending = false;
    this.cdRef.detectChanges();
  }

  private openSuccessAfterEmailSent(): void {
    this.dialogRef.close();
    this.dialog.open<ContactSuccessDialogComponent>(
      ContactSuccessDialogComponent,
      {
        width: DS_DIALOG_SIZE.M,
        maxWidth: DS_DIALOG_MAX_SIZE.M,
      }
    );
  }

  private openEmailSentErrorOverlay(): void {
    this.dialogRef.close();
    this.dialog.open<ErrorDialogComponent, DsErrorDialogInput>(
      ErrorDialogComponent,
      {
        width: DS_DIALOG_SIZE.M,
        maxWidth: DS_DIALOG_MAX_SIZE.M,
        data: {
          title: 'order-form.contact-form-dialog.error-headline',
          text: 'order-form.contact-form-dialog.error-content',
        },
      }
    );
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
