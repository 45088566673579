import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DsDraftSuccessDialogInput,
  DsDraftSuccessDialogOutput,
} from '@ds-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ds-draft-success-dialog',
  templateUrl: './draft-success-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftSuccessDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsDraftSuccessDialogInput,
    private dialogRef: MatDialogRef<
      DsDraftSuccessDialogInput,
      DsDraftSuccessDialogOutput
    >,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public getDraftSuccessDialogText(): string {
    if (this.data.isOverviewButtonVisible) {
      const textLabel = this.translate.instant(
        'order-form.draft-success-dialog.text-with-link'
      );

      return textLabel.replaceAll('{order-overview-url}', '/orders');
    }

    return this.translate.instant('order-form.draft-success-dialog.text');
  }

  public onContinueClick(): void {
    this.dialogRef.close();
  }

  public onCreateNewOrderClick(): void {
    this.dialogRef.close({ action: 'create-new-order' });
  }
}
