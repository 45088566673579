import { Injectable } from '@angular/core';
import { DcuplService } from '@ds-services';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private dcuplService: DcuplService) {}

  public async init(): Promise<void> {
    await this.dcuplService.init();
  }
}
