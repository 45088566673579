<div>
  <div class="relative mt-2 js-autocomplete-container">
    <div class="relative">
      <ds-icon name="search-16" [size]="16" class="absolute left-0 inset-y-0 my-auto ml-4 pointer-events-none"></ds-icon>
      <input #input [id]="htmlId" (focus)="onInputFocus()" [(ngModel)]="inputValue" (ngModelChange)="onInputChange($event)" type="text" class="form-input pl-12" role="combobox" aria-controls="options" [attr.aria-expanded]="isListboxVisible" [ngClass]="getClasses()" aria-autocomplete="none" autocomplete="none" />

      <button (click)="onListboxButtonClick()" type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 mr-2 focus:outline-none" [ngClass]="{ 'pointer-events-none': !isListboxVisible && !selectedOption }">
        <ng-container *ngIf="!isResetAvailable()">
          <ds-icon *ngIf="!isListboxVisible" name="chevron-down-16"></ds-icon>
          <ds-icon *ngIf="isListboxVisible" name="chevron-up-16"></ds-icon>
        </ng-container>
        <ng-container *ngIf="isResetAvailable()">
          <ds-icon name="close-16"></ds-icon>
        </ng-container>
      </button>
    </div>

    <ul *ngIf="isListboxVisible" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="listbox">
      <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 transition-colors duration-200 hover:bg-gray-300" role="option" tabindex="-1" *ngFor="let option of visibleOptions; trackBy: trackByOption" (click)="onOptionClick(option)">
        <span class="truncate mr-2">{{ option.label }}</span>
        <span class="text-gray-600" *ngIf="option.sublabel">({{ option.sublabel }})</span>

        <span *ngIf="option.key === selectedOption?.key" class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
          <ds-icon name="check-16"></ds-icon>
        </span>
      </li>
      <li *ngIf="!visibleOptions.length" class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-600" role="option" tabindex="-1">
        <span class="truncate mr-2">{{ 'autocomplete.no-results' | translate }}</span>
      </li>
    </ul>
  </div>
</div>
