import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const measureDeliveryValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const measure = control.get('measure');
  const measureDelivery = control.get('measure_delivery');

  if (!measure || !measureDelivery) {
    return null;
  }

  if (!measure.value) {
    measure.setErrors({
      required: true,
    });
  }
  if (measure.value === 'template' && measureDelivery.value === null) {
    measureDelivery.setErrors({
      required: true,
    });
  } else {
    measureDelivery.setErrors(null);
  }
  return null;
};
