import { Injectable, NgZone } from '@angular/core';
import { DsUiState } from '@ds-types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private uiState = new BehaviorSubject<DsUiState>({
    visibleFooter: null,
  });

  constructor(private zone: NgZone) {}

  public state$ = this.uiState.asObservable();

  public setState(delta: Partial<DsUiState>): void {
    this.zone.run(() => {
      const state = this.uiState.getValue();
      const newState = { ...state, ...delta };
      this.uiState.next(newState);
    });
  }
}
