import { DsApiConverter, DsApiFile, DsFile } from '@ds-types';

export const fileConverter: DsApiConverter<DsApiFile, DsFile> = {
  toApi: (file) => {
    if (file.status !== 'success') {
      throw new Error('File status is not success');
    }

    return {
      path: file.fullPath,
      size: file.size,
      name: file.name,
    };
  },
  fromApi: (file) => {
    return {
      name: file.name,
      size: file.size,
      status: 'success',
      fullPath: file.path,
    };
  },
};
