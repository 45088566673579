import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@ds-ui/icon';
import { TooltipModule } from '@ds-ui/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TooltipModule,
    IconModule,
  ],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
