<div class="items-center space-x-3 group" [ngClass]="getWrapperClasses()">
  <div class="items-center justify-center shrink-0" [ngClass]="getButtonClasses()">
    <div class="flex items-center text-left" [ngClass]="getContentClasses()">
      <div class="shrink-0" *ngIf="isIconContainerVisible()">
        <div [ngClass]="getIconClasses()">
          <div *ngIf="!isLoading">
            <ds-icon *ngIf="getIcon() as icon" class="fill-current" [size]="getIconSize()" [name]="icon"></ds-icon>
          </div>
          <ds-spinner *ngIf="isLoading" [size]="getIconSize()"></ds-spinner>
        </div>
      </div>
      <div [ngClass]="getTextClasses()" *ngIf="!rounded || !getIcon()">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div *ngIf="rounded && roundedLabel" class="leading-tight hidden lg:block" [ngClass]="getRoundedLabelClasses()" [innerHTML]="roundedLabel"></div>
</div>
