import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService, UserService } from '@ds-services';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard extends KeycloakAuthGuard {
  constructor(
    protected override router: Router,
    protected readonly keycloak: KeycloakService,
    private store: StoreService,
    private userService: UserService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(): Promise<boolean> {
    if (!this.authenticated) {
      await this.router.navigate(['/login']);
      this.store.setUser(null);
      return false;
    }

    const user = await this.userService.getUser();

    this.store.setUser(user);

    return true;
  }
}
