import { Injectable } from '@angular/core';
import { DcuplService, StoreService } from '@ds-services';
import { DsCustomerContact } from '@ds-types';
import { first } from 'lodash-es';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private dcuplService: DcuplService,
    private store: StoreService
  ) {}

  public async getCustomerContactFromDcuplByNumber(
    customerContactNumber: string
  ): Promise<DsCustomerContact | null> {
    const customerContacts = await this.dcuplService.getCustomerContactBySn(
      customerContactNumber
    );
    return first(customerContacts) ?? null;
  }

  public getCustomerEmail$(): Observable<string> {
    // wait for user and salesRefNr changes
    return combineLatest([this.store.user$, this.store.salesRefNr$$]).pipe(
      switchMap(([user, salesRefNr]) => {
        if (user?.type === 'extern') {
          return of(user); // make observable out of user because of observable return type
        }

        if (!salesRefNr) {
          return of(null);
        }

        return this.getCustomerContactFromDcuplByNumber(salesRefNr); // return customer contact as observable
      }),
      map((customerContactOrUser) => customerContactOrUser?.email ?? '') // get email from user or customer contact
    );
  }
}
