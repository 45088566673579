<div [ngClass]="getWrapperClasses()">
  <div class="flex items-end space-x-6 justify-between mb-1 shrink-0" *ngIf="formField.label || variant === 'row'" [ngClass]="getLabelClasses()">
    <label [for]="htmlId" class="typo-label" [ngClass]="getLabelInnerClasses()">
      <span *ngIf="formField.label" class="mr-1" [innerHTML]="formField.label | translate"></span>
      <span *ngIf="!isRequired && formField.label" class="text-gray-600">({{ 'optional' | translate }})</span>
    </label>
    <div class="typo-label text-gray-600" *ngIf="getCounter() as counter">{{ counter }}</div>
  </div>

  <div class="grow" *ngIf="getFormControl() as formControl">
    <ng-container *ngIf="formField.type === 'text'">
      <ds-input [formControl]="formControl" [formField]="formField" [htmlId]="htmlId" [isDisabled]="isDisabled" [theme]="theme"></ds-input>
    </ng-container>

    <ng-container *ngIf="formField.type === 'textarea'">
      <ds-textarea [formControl]="formControl" [formField]="formField" [htmlId]="htmlId" [isDisabled]="isDisabled"></ds-textarea>
    </ng-container>

    <ng-container *ngIf="formField.type === 'select'">
      <ds-select [formControl]="formControl" [formField]="formField" [htmlId]="htmlId" [isDisabled]="isDisabled" [theme]="theme"></ds-select>
    </ng-container>

    <ng-container *ngIf="formField.type === 'checkbox'">
      <ds-checkbox [formControl]="formControl" [formField]="formField" [htmlId]="htmlId" [isReadonly]="isReadonly"></ds-checkbox>
    </ng-container>

    <ng-container *ngIf="formField.type === 'radio-group'">
      <ds-radio-group [formControl]="formControl" [formField]="formField" [htmlId]="htmlId"></ds-radio-group>
    </ng-container>

    <ng-container *ngIf="formField.type === 'toggle'">
      <ds-toggle [formControl]="formControl" [formField]="formField" [htmlId]="htmlId" [isDisabled]="isDisabled"></ds-toggle>
    </ng-container>

    <ng-container *ngIf="formField.type === 'calendar'">
      <div class="flex justify-end">
        <ds-calendar [formControl]="formControl" [htmlId]="htmlId" [minDate]="formField.minDate" [maxDate]="formField.maxDate" [buttonLabel]="formField.buttonLabel" [sidebarHeadline]="formField.sidebarHeadline" [mode]="formField.calendarMode ?? 'date'"></ds-calendar>
      </div>
    </ng-container>

    <ng-container *ngIf="formField.type === 'select-material'">
      <ds-select-material [formControl]="formControl" [formField]="formField" [htmlId]="htmlId"></ds-select-material>
    </ng-container>

    <ng-container *ngIf="formField.type === 'autocomplete-customer'">
      <ds-autocomplete-customer [formControl]="formControl" [formField]="formField" [htmlId]="htmlId"></ds-autocomplete-customer>
    </ng-container>

    <ng-container *ngIf="formField.type === 'select-customer'">
      <ds-select-customer [formControl]="formControl" [formField]="formField" [htmlId]="htmlId"></ds-select-customer>
    </ng-container>

    <ng-container *ngIf="formField.type === 'select-edge-treatment'">
      <ds-select-edge-treatment [formControl]="formControl" [formField]="formField" [htmlId]="htmlId"></ds-select-edge-treatment>
    </ng-container>

    <ng-content></ng-content>

    <div *ngIf="shouldShowErrors()" class="typo-label mt-2">
      <ds-error [errors]="getErrors()"></ds-error>
    </div>

    <div *ngIf="formField.info">
      <div class="mt-4">
        <ds-infobox size="S">{{ formField.info | translate }}</ds-infobox>
      </div>
    </div>
  </div>
</div>
