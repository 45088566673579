import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { FormFieldModule } from './form-field';
import { FormFieldComponent } from './form-field/form-field.component';
import { SelectModule } from './select';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormFieldModule, ButtonModule],
  exports: [FormFieldComponent, SelectModule],
})
export class FormModule {}
