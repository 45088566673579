import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DS_REGEX_EMAIL } from '@ds-helpers';
import {
  DsOrderFormPhotosEmailSendType,
  DsOrderMaterialSelectionType,
} from '@ds-types';

export const photosEmailValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const materialSelectionType = control.get('material_selection_type');
  const photosEmailSend = control.get('photos_email_send');
  const customerEmailDelivery = control.get('customer_email_delivery');

  if (!photosEmailSend || !customerEmailDelivery || !materialSelectionType) {
    return null;
  }

  const materialSelectionTypeValue =
    materialSelectionType.value as DsOrderMaterialSelectionType;
  const photosEmailSendValue =
    photosEmailSend.value as DsOrderFormPhotosEmailSendType;
  const customerEmailDeliveryValue = customerEmailDelivery.value;

  customerEmailDelivery.setErrors(null);

  if (
    materialSelectionTypeValue === 'photos_sent' &&
    photosEmailSendValue === 'customer'
  ) {
    if (
      typeof customerEmailDeliveryValue !== 'string' ||
      !customerEmailDeliveryValue?.length
    ) {
      customerEmailDelivery.setErrors({
        required: true,
      });
    } else if (!DS_REGEX_EMAIL.test(customerEmailDeliveryValue)) {
      customerEmailDelivery.setErrors({
        email: true,
      });
    }
  }
  return null;
};
