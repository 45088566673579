import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export const cutoutSettingsValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const enabled = control.get('enabled');
  const specifyLater = control.get('specify_later');
  const installationType = control.get('installation_type');
  const isInd = control.get('is_ind');
  const indText = control.get('ind_text');
  const brand = control.get('brand');
  const type = control.get('type');
  const orderNumber = control.get('order_number');

  if (
    !enabled ||
    !specifyLater ||
    !installationType ||
    !isInd ||
    !indText ||
    !brand ||
    !type ||
    !orderNumber
  ) {
    return null;
  }

  brand.setErrors(null);
  type.setErrors(null);

  if (enabled.value === true && specifyLater.value !== true) {
    if (!brand.value) {
      brand.setErrors({ required: true });
    }
    if (!type.value) {
      type.setErrors({ required: true });
    }
  }

  const isIndTextValue: string | null = indText.value;

  if (isIndTextValue == null) {
    return null;
  }

  // Calculate the number of rows
  const rows = (isIndTextValue.match(/\n/g) || []).length + 1;

  // Calculate the number of characters in each row
  const charsPerRow = isIndTextValue.split('\n').map((row) => row.length);

  // Check if any row exceeds the maximum character limit (50)
  const exceedsMaxChars = charsPerRow.some((length) => length > 50);

  // Check if the number of rows exceeds the maximum limit (3)
  const exceedsMaxRows = rows > 3;

  if (exceedsMaxChars || exceedsMaxRows) {
    indText.setErrors({
      custom: 'order-form.step2.cutout-specifications-general.comment.error',
    });
  } else {
    indText.setErrors(null);
  }

  return null;
};
