import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { InfoDialogComponent } from './info-dialog.component';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
