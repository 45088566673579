// eslint-disable-next-line @typescript-eslint/naming-convention
export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
    ? T[P]
    : T[P] | undefined;
};

export type DsDcuplConverter<DcuplInterface, ApplicationInterface> = {
  fromDcupl: (
    data: DcuplInterface
  ) => Complete<ApplicationInterface> | undefined;
};

export type DsApiConverter<ApiInterface, ApplicationInterface> = {
  fromApi: (data: ApiInterface) => ApplicationInterface;
  toApi: (data: ApplicationInterface) => ApiInterface;
};

export type DsFormConverter<FormInterface, ApplicationInterface> = {
  fromForm: (data: FormInterface) => ApplicationInterface;
  toForm: (data: ApplicationInterface) => FormInterface;
};
