import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService, UserService } from '@ds-services';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}

  public async canActivate(): Promise<boolean> {
    if (await this.authenticationService.isLoggedIn()) {
      const user = await this.userService.getUser();

      if (user.type === 'extern') {
        await this.router.navigate(['/orders']);
      } else if (user.type === 'intern') {
        await this.router.navigate(['/orders/new']);
      }

      return false;
    }

    return true;
  }
}
