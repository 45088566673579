export type DsArticleExtDcupl = {
  key: string;
  ArtikelNr: string;
  Geraeteart_ID: string;
  Einbauposition: DsArticleInstallationType;
  Herstellerbezeichnung: string;
  Farbbezeichnung: string;
  Hersteller_ID: string;
  Katalog_ID: string;
  bestellnummer: string;
};

// Aufliegend = 0
// Unterbau = 1
// Flächenbündig = 3
// Zwischenbau = 4
export type DsArticleInstallationType = '0' | '1' | '2' | '3' | '4';
export type DsArticleInstallationTypeApi = '' | '(AS)' | '(UB)' | '(FL)';

export type DsCutoutTypeApi =
  | 'Spüle'
  | 'Kochfeld'
  | 'Arbeitsplatten-Abzug'
  | 'Steckdose';
