import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const materialSettingsValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const enabled = control.get('enabled');
  const surfaceType = control.get('surface_type');
  const materialThickness = control.get('material_thickness');
  const edgeTreatment = control.get('edge_treatment');
  const materialNumber = control.get('material_number');

  if (
    !materialThickness ||
    !edgeTreatment ||
    !surfaceType ||
    !materialNumber ||
    !enabled
  ) {
    return null;
  }

  surfaceType.setErrors(null);
  materialThickness.setErrors(null);
  edgeTreatment.setErrors(null);
  materialNumber.setErrors(null);

  if (enabled.value === true) {
    if (!materialNumber.value) {
      materialNumber.setErrors({
        custom: 'order-form.step2.board-type.error-general',
      });
    }

    if (surfaceType.value === null) {
      surfaceType.setErrors({
        required: true,
      });
    }
    if (materialThickness.value === null) {
      materialThickness.setErrors({
        required: true,
      });
    }
    if (edgeTreatment.value === null) {
      edgeTreatment.setErrors({
        required: true,
      });
    }
  }

  return null;
};
