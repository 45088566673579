import { DsOrder, DsOrderFormMeasure } from '@ds-types';

export type DsService = Pick<
  DsOrder,
  | 'delivery_type'
  | 'montage_package_connection'
  | 'measure_delivery'
  | 'montage_package_connection_downdraft'
  | 'montage_package_connection_multi'
  | 'specify_plan_dimension_later'
> & {
  measure: DsOrderFormMeasure;
};
