<ng-container *ngIf="customer; else noCustomer">
  <div class="mb-2 border rounded-md p-4">
    <div class="typo-label-md-bold">{{ customer.billingName }}</div>

    <div>{{ customer.number }}</div>
    <div class="mt-4 text-gray-600">
      <div>{{ customer.billingStreet }}</div>
      <div>{{ customer.billingCountrycode }}-{{ customer.billingZip }} {{ customer.billingCity }}</div>
    </div>
  </div>

  <div class="flex justify-end">
    <button ds-button type="button" icon="edit-16" variant="link" (click)="onChangeClick()">{{ 'actions.change-customer' | translate }}</button>
  </div>
</ng-container>
<ng-template #noCustomer>
  <button ds-button type="button" variant="outline" (click)="onSelectClick()">{{ 'actions.select-customer' | translate }}</button>
</ng-template>
