import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutocompleteCustomerModule } from '@ds-ui/autocomplete-customer';
import { CalendarModule } from '@ds-ui/calendar/calendar.module';
import { InfoboxModule } from '@ds-ui/infobox/infobox.module';
import { SelectCustomerModule } from '@ds-ui/select-customer';
import { SelectMaterialModule } from '@ds-ui/select-material/select-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from '../checkbox';
import { ErrorModule } from '../error';
import { InputModule } from '../input';
import { RadioGroupModule } from '../radio-group';
import { SelectModule } from '../select';
import { TextareaModule } from '../textarea';
import { ToggleModule } from '../toggle';
import { FormFieldComponent } from './form-field.component';
import { SelectEdgeTreatmentModule } from '@ds-ui/select-edge-treatment/select-edge-treatment.module';

@NgModule({
  declarations: [FormFieldComponent],
  exports: [FormFieldComponent],
  imports: [
    CommonModule,
    InputModule,
    ErrorModule,
    SelectModule,
    CheckboxModule,
    RadioGroupModule,
    TranslateModule,
    InfoboxModule,
    ToggleModule,
    AutocompleteCustomerModule,
    SelectCustomerModule,
    SelectMaterialModule,
    SelectEdgeTreatmentModule,
    TextareaModule,
    CalendarModule,
  ],
})
export class FormFieldModule {}
