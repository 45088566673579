import {
  DsCustomer,
  DsCustomerContact,
  DsCustomerContactDcupl,
  DsCustomerDcupl,
  DsDcuplConverter,
} from '@ds-types';

export const customerConverter: DsDcuplConverter<DsCustomerDcupl, DsCustomer> =
  {
    fromDcupl: (data) => {
      return {
        id: data.id,
        number: data.sn,
        billingName: data.billing_fullName,
        billingStreet: data.billing_street,
        billingZip: data.billing_zip,
        billingCity: data.billing_city,
        billingCountry: data.billing_country,
        billingCountrycode: data.billing_countryCode,
        billingPhone: data.billing_phone,
        billingMobile: data.billing_mobile,
        billingEmail: data.billing_mail,
        warehouseName: data.warehouse_fullName,
        warehouseStreet: data.warehouse_street,
        warehouseZip: data.warehouse_zip,
        warehouseCity: data.warehouse_city,
        warehouseCountry: data.warehouse_country,
        warehouseCountrycode: data.warehouse_countryCode,
        warehousePhone: data.warehouse_phone,
        warehouseMobile: data.warehouse_mobile,
        warehouseEmail: data.warehouse_mail,
      };
    },
  };

export const customerContactConverter: DsDcuplConverter<
  DsCustomerContactDcupl,
  DsCustomerContact
> = {
  fromDcupl: (data) => {
    return {
      id: data.key,
      number: data.sn,
      name: data.name,
      email: data.email,
    };
  },
};
