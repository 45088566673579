import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DsFormFieldExtComponent, DsFormFieldSelectMaterial } from '@ds-form';
import { getMaterialImageUrl } from '@ds-helpers';
import { DcuplService, SidebarService } from '@ds-services';
import { DsMaterial } from '@ds-types';
import { OrderFormService } from '@ds-ui/order-form/services';
import { first } from 'lodash-es';
import { Subscription, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'ds-select-material',
  templateUrl: './select-material.component.html',
  styleUrls: ['./select-material.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMaterialComponent<FieldKey extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() formField?: DsFormFieldSelectMaterial<FieldKey>;

  private subscriptions = new Subscription();

  public material: DsMaterial | null = null;

  constructor(
    private dcuplService: DcuplService,
    private cdRef: ChangeDetectorRef,
    private sidebarService: SidebarService,
    private orderFormService: OrderFormService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
    this.listenForDetectMaterialChanges();
  }

  private async init(): Promise<void> {
    if (this.formControl) {
      this.subscriptions.add(
        this.formControl.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe(async (key) => {
            this.material = await this.getMaterialFromDcuplByNumber(key);
            this.cdRef.detectChanges();
          })
      );

      this.material = await this.getMaterialFromDcuplByNumber(
        this.formControl.value
      );

      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForDetectMaterialChanges(): void {
    this.subscriptions.add(
      this.orderFormService.detectSelectMaterialChanges$.subscribe(async () => {
        this.material = await this.getMaterialFromDcuplByNumber(
          this.formControl.value
        );
        this.cdRef.detectChanges();
      })
    );
  }

  public onSelectMaterialClick(): void {
    this.openMaterialSidebar();
  }

  public onChangeMaterialClick(): void {
    this.openMaterialSidebar();
  }

  private openMaterialSidebar(): void {
    if (!this.formControl) {
      return;
    }

    this.sidebarService.setActiveSidebar({
      type: 'material',
      materialFormControl: this.formControl,
    });
  }

  private async getMaterialFromDcuplByNumber(
    materialNumber: string
  ): Promise<DsMaterial | null> {
    const material = await this.dcuplService.getMaterialsByNameOrNumber(
      materialNumber
    );

    return first(material) ?? null;
  }

  public getMaterialImageUrl(materialNumber: string): string {
    return getMaterialImageUrl(materialNumber);
  }
}
