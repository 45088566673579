import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { neverError } from '@ds-helpers';
import { ToastService } from '@ds-services';
import {
  DS_TOAST_DURATION_DEFAULT,
  DsToastDuration,
  DsToastVariant,
} from '@ds-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toastTrigger', [
      state('open', style({ transform: 'translateX(0%)' })),
      state('close', style({ transform: 'translateX(-200%)' })),
      transition('open <=> close', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class ToastComponent implements OnInit, OnDestroy {
  @ViewChild('toastContainer', { static: true })
  private readonly toastContainerRef?: ElementRef<HTMLElement>;

  public variant: DsToastVariant = 'info';
  public isToastVisible: boolean = false;

  public title: string = '';
  public message: string = '';
  private duration: DsToastDuration = DS_TOAST_DURATION_DEFAULT;

  public isCta1Visible: boolean = false;
  public cta1Label: string = '';
  private cta1Callback: () => void = () => {};

  public isCta2Visible: boolean = false;
  public cta2Label: string = '';
  private cta2Callback: () => void = () => {};

  private subscriptions = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.listenForNewToast();
    this.listenForMouseEnter();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForNewToast(): void {
    this.subscriptions.add(
      this.toastService.activeToast$.subscribe((toast) => {
        if (!toast) {
          this.isToastVisible = false;
          this.cdRef.detectChanges();
          return;
        }

        this.variant = toast.variant;
        this.title = toast.title;
        this.message = toast.message;

        this.isCta1Visible = !!toast.cta1;
        this.cta1Label = toast.cta1?.label ?? '';
        this.cta1Callback = toast.cta1?.callback ?? (() => {});
        this.isCta2Visible = !!toast.cta2;
        this.cta2Label = toast.cta2?.label ?? '';
        this.cta2Callback = toast.cta2?.callback ?? (() => {});

        this.isToastVisible = true;
        this.cdRef.detectChanges();
      })
    );
  }

  private listenForMouseEnter(): void {
    const setCloseBlocked = (value: boolean): void => {
      this.toastService.isCloseBlocked = value;
    };

    const addEventListener = (event: string, handler: () => void): void => {
      this.subscriptions.add(
        this.toastContainerRef?.nativeElement?.addEventListener(event, handler)
      );
    };

    addEventListener('mouseenter', () => setCloseBlocked(true));
    addEventListener('mouseleave', () => setCloseBlocked(false));
  }

  public onCloseClick(): void {
    this.toastService.closeActiveToast();
  }

  public onCTA1Click(): void {
    this.cta1Callback();
    this.toastService.closeActiveToast();
  }

  public onCTA2Click(): void {
    this.cta2Callback();
    this.toastService.closeActiveToast();
  }

  public getIconColor(): string {
    switch (this.variant) {
      case 'success':
        return 'text-green-500';
      case 'warning':
        return 'text-warning-500';
      case 'error':
        return 'text-error-500';
      case 'info':
        return 'text-gray-600';
      default:
        throw neverError(this.variant);
    }
  }

  public getProgressBarClasses(): string[] {
    const classes = [];

    // Background color
    switch (this.variant) {
      case 'success':
        classes.push('bg-green-500');
        break;
      case 'warning':
        classes.push('bg-warning-500');
        break;
      case 'error':
        classes.push('bg-error-500');
        break;
      case 'info':
        classes.push('bg-gray-600');
        break;
      default:
        throw neverError(this.variant);
    }

    // Progressbar position
    let durationClass = '';

    switch (this.duration) {
      case 7:
        durationClass = 'duration-[7s]';
        break;
      default:
        throw neverError(this.duration);
    }

    if (this.isToastVisible) {
      classes.push('-translate-x-full', durationClass);
    } else {
      classes.push('translate-x-0', 'duration-0');
    }

    return classes;
  }
}
