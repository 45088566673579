import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { DsDatePipe } from './date.pipe';
import { DsFileSizePipe } from './file-size.pipe';
import { DsHighlightTextPipe } from './highlight-text.pipe';

registerLocaleData(de);

@NgModule({
  exports: [DsHighlightTextPipe, DsDatePipe, DsFileSizePipe],
  declarations: [DsHighlightTextPipe, DsDatePipe, DsFileSizePipe],
  providers: [DsHighlightTextPipe, DsDatePipe, DsFileSizePipe],
})
export class DsPipesModule {}
