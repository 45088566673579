import { Injectable } from '@angular/core';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { DS_DIALOG_MAX_SIZE, DS_DIALOG_SIZE } from '@ds-types';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  public openSendPhotoToMeInfoDialog(userEmail: string | undefined): void {
    const headlineLabel = this.translate.instant(
      'order-form.step5.sidebar-material-inspection.email-info-title'
    );
    let contentLabel = this.translate.instant(
      'order-form.step5.sidebar-material-inspection.email-info'
    );

    contentLabel = contentLabel.replaceAll('{customer-email}', userEmail || '');

    const data = {
      headlineLabel,
      contentLabel,
    };

    this.dialog.open<InfoDialogComponent>(InfoDialogComponent, {
      width: DS_DIALOG_SIZE.M,
      maxWidth: DS_DIALOG_MAX_SIZE.M,
      data,
    });
  }
}
