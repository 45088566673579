import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@ds-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [CommonModule, TranslateModule, IconModule, FormsModule],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
