import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfoboxComponent } from './infobox.component';
import { IconModule } from '@ds-ui/icon';
@NgModule({
  declarations: [InfoboxComponent],
  imports: [CommonModule, FormsModule, IconModule],
  exports: [InfoboxComponent],
})
export class InfoboxModule {}
