import { Injectable } from '@angular/core';
import { DsSelectOption } from '@ds-form';
import { DsCountry } from '@ds-types';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countries: DsCountry[] = [
    { name: 'Österreich' },
    { name: 'Deutschland' },
    { name: 'Schweiz' },
    { name: 'Italien' },
    { name: 'Liechtenstein' },
    { name: 'Luxemburg' },
    { name: 'Slowakei' },
    { name: 'Tschechien' },
    { name: 'Ungarn' },
  ];

  constructor() {}

  public getCountriesAsSelectOptions(): DsSelectOption<string>[] {
    return this.countries.map((country) => {
      return {
        key: country.name,
        label: country.name,
      };
    });
  }
}
