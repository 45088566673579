import { Injectable } from '@angular/core';
import { DsUser } from '@ds-types';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private user$$ = new BehaviorSubject<DsUser | null | undefined>(undefined);

  public salesRefNr$$ = new BehaviorSubject<string | null>(null);

  public user$ = this.user$$.asObservable().pipe(
    filter((user): user is DsUser | null => user !== undefined),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  public setUser(user: DsUser | null): void {
    this.user$$.next(user);
  }

  public getUser(): DsUser | null | undefined {
    return this.user$$.getValue();
  }
}
