<div *ngIf="!material">
  <button [elastic]="true" ds-button type="button" (click)="onSelectMaterialClick()">{{ 'order-form.step2.choose-material' | translate }}</button>
</div>
<div *ngIf="material">
  <div class="md:grid md:grid-cols-3">
    <div class="md:col-span-2 flex md:mr-8">
      <div class="mr-4 w-20 h-14 bg-cover bg-center border rounded shrink-0" [style]="'background-image: url(' + getMaterialImageUrl(material.number) + ')'"></div>
      <div>
        <p class="font-bold text-gray-800 uppercase">{{ material.name }}</p>
        <p>{{ material.number }}</p>
      </div>
    </div>
    <div class="text-left md:text-right mt-4 md:mt-0">
      <button ds-button type="button" variant="link" iconPos="left" icon="edit-16" (click)="onChangeMaterialClick()">{{ 'order-form.step2.edit-material' | translate }}</button>
    </div>
  </div>
</div>
