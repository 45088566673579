import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export type DsCanDeactivateType =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;

export interface DsCanComponentDeactivate {
  canDeactivate: () => DsCanDeactivateType;
}
