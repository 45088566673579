<div #toastContainer [@toastTrigger]="isToastVisible ? 'open' : 'close'" class="shadow-md fixed bottom-6 mx-6 max-w-sm overflow-hidden z-200 bg-white">
  <div class="flex p-4 space-x-2">
    <div [ngClass]="getIconColor()">
      <ds-icon *ngIf="variant === 'success'" name="success-24" [size]="24"></ds-icon>
      <ds-icon *ngIf="variant === 'warning'" name="warning-24" [size]="24"></ds-icon>
      <ds-icon *ngIf="variant === 'error'" name="error-24" [size]="24"></ds-icon>
      <ds-icon *ngIf="variant === 'info'" name="info-24" [size]="24"></ds-icon>
    </div>
    <div class="text-gray-800">
      <p [innerHTML]="title" class="typo-label-sm-bold mt-[0.125rem] mb-2"></p>
      <p [innerHTML]="message" class="text-sm"></p>
      <div *ngIf="isCta1Visible || isCta2Visible" class="flex flex-wrap">
        <div *ngIf="isCta1Visible" class="mt-2 mr-6">
          <button ds-button (click)="onCTA1Click()" type="button" variant="link" size="S">{{ cta1Label }}</button>
        </div>
        <div *ngIf="isCta2Visible" class="mt-2">
          <button ds-button (click)="onCTA2Click()" type="button" variant="link" size="S">{{ cta2Label }}</button>
        </div>
      </div>
    </div>
    <div>
      <button type="button" (click)="onCloseClick()">
        <ds-icon name="close-16" [size]="16"></ds-icon>
      </button>
    </div>
  </div>
  <div [ngClass]="{ 'opacity-0': !isToastVisible }">
    <div class="h-1 w-full transform transition-transform ease-linear" [ngClass]="getProgressBarClasses()"></div>
  </div>
</div>
