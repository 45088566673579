import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DsFormFieldExtComponent,
  DsFormFieldSelectEdgeTreatment,
} from '@ds-form';
import { SidebarService } from '@ds-services';
import { DsArticleEdgeOption } from '@ds-types';
import { EdgeTreatmentService } from '@ds-ui/order-form/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-select-edge-treatment',
  templateUrl: './select-edge-treatment.component.html',
  styleUrls: ['./select-edge-treatment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEdgeTreatmentComponent<FieldKey extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() formField?: DsFormFieldSelectEdgeTreatment<FieldKey>;

  private subscriptions = new Subscription();

  public edgeTreatmentOption: DsArticleEdgeOption | null = null;

  constructor(
    private sidebarService: SidebarService,
    private cdRef: ChangeDetectorRef,
    public edgeTreatmentService: EdgeTreatmentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    if (!this.formControl) {
      return;
    }

    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((value) => {
        this.edgeTreatmentOption =
          this.formField?.options.find((option) => option?.key === value) ??
          null;
        this.cdRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSelectEdgeTreatmentClick(): void {
    this.openEdgeTreatmentSidebar();
  }

  public onChangeEdgeTreatmentClick(): void {
    this.openEdgeTreatmentSidebar();
  }

  private openEdgeTreatmentSidebar(): void {
    if (!this.formControl || !this.formField) {
      return;
    }

    this.sidebarService.setActiveSidebar({
      type: 'edge-treatment',
      edgeTreatmentFormControl: this.formControl,
      boardType: this.formField.boardType,
    });
  }
}
