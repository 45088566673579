<ds-dialog>
  <ng-container id="header">{{ 'order-form.order-delete-dialog.title' | translate }}</ng-container>

  <p class="text-gray-800">{{ 'order-form.order-delete-dialog.text' | translate }}</p>

  <ng-container id="footer">
    <div class="flex items-center justify-end flex-col-reverse sm:flex-row">
      <button ds-button type="button" variant="outline" (click)="onCancelClick()" [elastic]="true" class="mt-2 sm:mt-0 sm:mr-4">{{ 'actions.cancel' | translate }}</button>
      <button ds-button type="button" variant="flat" (click)="onDeleteClick()" [elastic]="true">{{ 'order-form.order-delete-dialog.delete' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
