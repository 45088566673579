export type DsMaterialSettingsFields =
  | 'enabled'
  | 'material_number'
  | 'article_id'
  | 'edge_treatment'
  | 'article_max_length'
  | 'material_thickness'
  | 'surface_type'
  | 'amount'
  | 'position_number';
