/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { DcuplGlobalQueryOptions, escapeRegExp } from '@dcupl/common';
import { Dcupl } from '@dcupl/core';
import { DcuplAppLoader } from '@dcupl/loader';
import {
  customerContactConverter,
  customerConverter,
  materialConverter,
} from '@ds-converters';
import { DS_ENVIRONMENT } from '@ds-environment';
import { AuthenticationService } from '@ds-services';
import {
  DS_ARTICLE_PROJECTION,
  DsArticle,
  DsArticleDcupl,
  DsCustomer,
  DsCustomerContact,
  DsCustomerContactDcupl,
  DsCustomerDcupl,
  DsMaterial,
  DsMaterialDcupl,
} from '@ds-types';
import { compact, first, sortBy } from 'lodash-es';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { articleConverter } from '../converters/article.converter';

@Injectable({
  providedIn: 'root',
})
export class DcuplService {
  public dcupl = new Dcupl();
  public loader: DcuplAppLoader | undefined;
  private isInitialized$$ = new BehaviorSubject<boolean>(false);

  constructor(private authenticationService: AuthenticationService) {}

  // Init

  public async init(): Promise<void> {
    this.loader = new DcuplAppLoader();
    this.dcupl.loaders.add(this.loader);

    const token = await this.authenticationService.getAuthToken();
    this.setGlobalBearerToken(token);
    this.loader.variables.global.set('apiUrl', DS_ENVIRONMENT.dcupl.apiUrl);
    this.loader.variables.global.set('baseUrl', DS_ENVIRONMENT.dcupl.baseUrl);

    await this.loader.config.fetch({
      baseUrl: DS_ENVIRONMENT.dcupl.baseUrl,
      loaderFileName: 'dcupl.lc.json',
      headers: [
        {
          key: 'Authorization',
          value: `Bearer ${token}`,
        },
      ],
    });

    await this.loader.process({
      applicationKey: 'sts_processed_app',
      environmentKeys: ['web-application'],
    });

    await this.dcupl.init();
    this.isInitialized$$.next(true);

    Object.defineProperty(window, 'dcupl', { value: this.dcupl });
  }

  public async waitForInit(): Promise<boolean> {
    return await firstValueFrom(
      this.isInitialized$$.pipe(filter((isInitialized) => isInitialized))
    );
  }

  public async fetchOrderData(): Promise<void> {
    const token = await this.authenticationService.getAuthToken();
    this.setGlobalBearerToken(token);

    await this.loader?.process({
      resourceTags: ['order_overview_data', 'transformer'],
      environmentKeys: ['web-application'],
    });
    await this.dcupl.update();
  }

  public setGlobalBearerToken(token: string): void {
    this.loader?.variables.global.set('bearer-token', token);
  }

  // Customers

  public async getCustomersByNameOrNumber(
    nameOrNumber: string,
    limit = 10
  ): Promise<DsCustomer[]> {
    const escapedNameOrNumber = escapeRegExp(nameOrNumber);
    const result = this.dcupl.query.execute<DsCustomerDcupl>({
      modelKey: 'Client',
      groupKey: 'root',
      groupType: 'or',
      count: limit,
      queries: [
        {
          groupKey: 'billing_fullName',
          groupType: 'or',
          queries: [
            {
              attribute: 'billing_fullName',
              value: `/${escapedNameOrNumber}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
        {
          groupKey: 'sn',
          groupType: 'or',
          queries: [
            {
              attribute: 'sn',
              value: `/${escapedNameOrNumber}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ],
    });

    return compact(
      result.map((customer) => customerConverter.fromDcupl(customer))
    );
  }

  public async getCustomerByNumber(
    sn: string
  ): Promise<DsCustomer | undefined> {
    const result = this.dcupl.query.execute<DsCustomerDcupl>({
      modelKey: 'Client',
      groupKey: 'root',
      groupType: 'and',
      queries: [
        {
          groupKey: 'sn',
          groupType: 'or',
          queries: [
            {
              attribute: 'sn',
              value: sn,
              operator: 'eq',
            },
          ],
        },
      ],
    });

    return first(
      compact(result.map((customer) => customerConverter.fromDcupl(customer)))
    );
  }

  public async getCustomerContactsByCustomerNumberAndName(
    customerNumber: string,
    name: string
  ): Promise<DsCustomerContact[]> {
    const result = this.dcupl.query.execute<DsCustomerContactDcupl>({
      modelKey: 'ClientContact',
      groupKey: 'root',
      groupType: 'and',
      queries: [
        {
          groupKey: 'firma',
          groupType: 'or',
          queries: [
            {
              attribute: 'firma',
              value: {
                key: customerNumber,
              },
              operator: 'eq',
            },
          ],
        },
        {
          groupKey: 'name',
          groupType: 'or',
          queries: [
            {
              attribute: 'name',
              value: `/${name}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ],
    });

    return compact(
      result.map((customerContact) =>
        customerContactConverter.fromDcupl(customerContact)
      )
    );
  }

  public async getCustomerContactBySn(
    number: string
  ): Promise<DsCustomerContact[]> {
    const result = this.dcupl.query.execute<DsCustomerContactDcupl>({
      modelKey: 'ClientContact',
      groupKey: 'root',
      groupType: 'or',
      queries: [
        {
          groupKey: 'sn',
          groupType: 'or',
          queries: [
            {
              queryKey: number,
              attribute: 'sn',
              value: number,
              operator: 'eq',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ],
    });

    return compact(
      result.map((customerContact) =>
        customerContactConverter.fromDcupl(customerContact)
      )
    );
  }

  // Materials

  public async getMaterialsByNameOrNumber(
    nameOrNumber: string,
    limit = 10
  ): Promise<DsMaterial[]> {
    const query: DcuplGlobalQueryOptions<DsMaterialDcupl> = {
      modelKey: 'Material',
      groupKey: 'root',
      groupType: 'or',
      count: limit,
      queries: [],
    };

    if (nameOrNumber !== '') {
      query.queries = [
        {
          groupKey: 'yvbez',
          groupType: 'or',
          queries: [
            {
              attribute: 'yvbez',
              value: `/${nameOrNumber}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
        {
          groupKey: 'sn',
          groupType: 'or',
          queries: [
            {
              attribute: 'sn',
              value: `/${nameOrNumber}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ];
    }

    const result = this.dcupl.query.execute<DsMaterialDcupl>(query);
    const sortedResult = sortBy(result, 'name');

    return compact(
      sortedResult.map((material) => materialConverter.fromDcupl(material))
    );
  }

  public async getDrafts(limit = 10): Promise<any[]> {
    const query: DcuplGlobalQueryOptions<any> = {
      modelKey: 'OrderOverview',
      groupKey: 'root',
      groupType: 'or',
      count: limit,
      queries: [],
    };

    return this.dcupl.query.execute<any>(query);
  }

  public async getArticleById(
    articleId: string
  ): Promise<DsArticle | undefined> {
    const query: DcuplGlobalQueryOptions<DsArticleDcupl> = {
      modelKey: 'Article',
      groupKey: 'root',
      groupType: 'or',
      count: 1,
      projection: DS_ARTICLE_PROJECTION,
      queries: [
        {
          modelKey: 'Article',
          groupKey: 'root',
          groupType: 'and',
          queries: [
            {
              groupKey: 'id',
              groupType: 'or',
              queries: [
                {
                  queryKey: articleId,
                  attribute: 'id',
                  value: articleId,
                  operator: 'find',
                  options: {
                    transform: ['lowercase'],
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    const result = this.dcupl.query.execute<DsArticleDcupl>(query);

    const articleDcupl = first(result);

    return articleDcupl ? articleConverter.fromDcupl(articleDcupl) : undefined;
  }
}
