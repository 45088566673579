<ds-dialog>
  <ng-container id="header">
    <div class="flex">
      <ds-icon name="check-24" [size]="24" class="mr-2 text-green-500"></ds-icon>
      <span>{{ 'order-form.contact-form-dialog.success-headline' | translate }}</span>
    </div>
  </ng-container>

  <p>{{ 'order-form.contact-form-dialog.success-content' | translate }}</p>

  <ng-container id="footer">
    <div class="text-right">
      <button ds-button type="button" (click)="onContinueClick()" [elastic]="true">{{ 'order-form.contact-form-dialog.success-button' | translate }}</button>
    </div>
  </ng-container>
</ds-dialog>
