import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { neverError } from '@ds-helpers';
import {
  DsBoardType,
  DsMaterialApplyDialogInput,
  DsMaterialApplyDialogOutput,
} from '@ds-types';

@Component({
  selector: 'ds-material-apply-dialog',
  templateUrl: './material-apply-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialApplyDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsMaterialApplyDialogInput,
    private dialogRef: MatDialogRef<
      DsMaterialApplyDialogInput,
      DsMaterialApplyDialogOutput
    >
  ) {}

  ngOnInit(): void {}

  public trackByBoardType(index: number, result: DsBoardType): string {
    return result;
  }

  public getBoardTypeLabelKey(boardType: DsBoardType): string {
    switch (boardType) {
      case 'worktop':
        return 'order-form.step2.worktop.label';
      case 'backwall':
        return 'order-form.step2.backwall.label';
      case 'wiper':
        return 'order-form.step2.wiper.label';
      case 'sidepanel':
        return 'order-form.step2.sidepanel.label';
      default:
        throw neverError(boardType);
    }
  }

  public getCancelButtonLabelKey(): string {
    switch (this.data.changedBoardType) {
      case 'worktop':
        return 'order-form.step2.material-apply-dialog.not-apply.worktop';
      case 'backwall':
        return 'order-form.step2.material-apply-dialog.not-apply.backwall';
      case 'wiper':
        return 'order-form.step2.material-apply-dialog.not-apply.wiper';
      case 'sidepanel':
        return 'order-form.step2.material-apply-dialog.not-apply.sidepanel';
      default:
        throw neverError(this.data.changedBoardType);
    }
  }

  public onApplyClick(): void {
    this.dialogRef.close({ action: 'apply' });
  }

  public onCancelClick(): void {
    this.dialogRef.close({ action: 'cancel' });
  }
}
