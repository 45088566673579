import {
  DS_BOARD_TYPES_MAP,
  DsArticle,
  DsArticleDcupl,
  DsDcuplConverter,
} from '@ds-types';
import { isNumber } from 'lodash-es';

export const articleConverter: DsDcuplConverter<DsArticleDcupl, DsArticle> = {
  fromDcupl: (data) => {
    return {
      // Name
      name: data.name,
      // Stärke
      thickness: data.ynarth,
      // Oberfläche
      surface: data.ynoberfl,
      // Materialnummer
      materialNumber: data.yvmaterial.nummer,
      materialName: data.yvmaterial.name,
      amount: isNumber(data.amount) ? data.amount : 1,
      boardType: DS_BOARD_TYPES_MAP[data.yartber] ?? 'worktop',
      edges: [
        data['Kantenoption 1'],
        data['Kantenoption 2'],
        data['Kantenoption 3'],
        data['Kantenoption 4'],
        data['Kantenoption 5'],
        data['Kantenoption 6'],
        data['Kantenoption 7'],
        data['Kantenoption 8'],
        data['Kantenoption 9'],
        data['Kantenoption 10'],
        data['Kantenoption 11'],
        data['Kantenoption 12'],
        data['Kantenoption 52'],
      ].filter((edge) => edge !== undefined),
    };
  },
};
