import { Injectable } from '@angular/core';
import { getEdgeTreatmentImageUrl } from '@ds-helpers';
import { DsSidebarEdgeTreatmentData } from '@ds-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EdgeTreatmentService {
  private edgeTreatmentOptionsSubject: BehaviorSubject<
    DsSidebarEdgeTreatmentData | undefined
  > = new BehaviorSubject<DsSidebarEdgeTreatmentData | undefined>(undefined);
  public edgeTreatmentData$: Observable<
    DsSidebarEdgeTreatmentData | undefined
  > = this.edgeTreatmentOptionsSubject.asObservable();

  constructor() {}

  public setEdgeTreatmentData(options: DsSidebarEdgeTreatmentData): void {
    this.edgeTreatmentOptionsSubject.next(options);
  }

  public getEdgeTreatmentOptionImageURL(edgeTreatmentKey: string): string {
    const sanitizedKey = this.getSanitizedEdgeTreatmentKey(edgeTreatmentKey);
    return getEdgeTreatmentImageUrl(sanitizedKey);
  }

  public getSanitizedEdgeTreatmentKey(edgeTreatmentKey: string): string {
    return edgeTreatmentKey.replace(/[_()]/g, '');
  }
}
