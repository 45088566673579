import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-contact-success-dialog',
  templateUrl: './contact-success-dialog.component.html',
  styleUrls: ['./contact-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSuccessDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ContactSuccessDialogComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public onContinueClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/orders']);
  }
}
