import { Injectable } from '@angular/core';
import { StoreService } from '@ds-services';
import { DS_USER_ROLE } from '@ds-types';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public updatedToken$ = new Subject<string>();

  constructor(
    private keycloakService: KeycloakService,
    private store: StoreService
  ) {}

  public async getAuthToken(): Promise<string> {
    if (this.keycloakService.isTokenExpired()) {
      return this.updateToken();
    }

    return this.keycloakService.getToken();
  }

  public async updateToken(): Promise<string> {
    return this.keycloakService
      .updateToken()
      .then(async (success) => {
        if (!success) {
          this.logout();
          return '';
        }

        const newToken = await this.keycloakService.getToken();
        this.updatedToken$.next(newToken);
        return newToken;
      })
      .catch(() => {
        this.logout();
        return '';
      });
  }

  public async isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public login(): void {
    this.keycloakService.login({
      redirectUri: window.location.origin + '/login',
    });
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin + '/login');
  }

  public hasUserRole(role: DS_USER_ROLE): boolean {
    const user = this.store.getUser();

    if (!user) {
      return false;
    }

    return user.roles.includes(role);
  }
}
