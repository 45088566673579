import { Injectable } from '@angular/core';
import { ApiService } from '@ds-services';
import { DS_API_ENDPOINTS, DsApiMaintenanceResponse } from '@ds-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeService {
  private maintenanceData$$ =
    new BehaviorSubject<DsApiMaintenanceResponse | null>(null);

  get maintenanceData$(): Observable<DsApiMaintenanceResponse | null> {
    return this.maintenanceData$$.asObservable();
  }
  constructor(private apiService: ApiService) {}

  public async setMaintenanceData(): Promise<void> {
    const response = (await this.apiService.get(
      DS_API_ENDPOINTS.maintenance,
      undefined,
      true
    )) as DsApiMaintenanceResponse;
    this.maintenanceData$$.next(response);
  }
}
