/* 🤖 this file was generated by svg-to-ts */
export const iconAdd16: {
  name: 'add-16';
  data: string;
} = {
  name: 'add-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M7.3 11.46h1.4v-2.8h2.8v-1.4H8.7v-2.8H7.3v2.8H4.5v1.4h2.8zm.7 3.5a6.8 6.8 0 0 1-2.73-.552 7.1 7.1 0 0 1-2.222-1.495 7.1 7.1 0 0 1-1.496-2.223A6.8 6.8 0 0 1 1 7.96q0-1.452.552-2.73a7.1 7.1 0 0 1 1.495-2.222A7.1 7.1 0 0 1 5.27 1.512 6.8 6.8 0 0 1 8 .96a6.8 6.8 0 0 1 2.73.552 7.1 7.1 0 0 1 2.223 1.496 7.1 7.1 0 0 1 1.496 2.222q.552 1.278.551 2.73a6.8 6.8 0 0 1-.552 2.73 7.1 7.1 0 0 1-1.495 2.223 7.1 7.1 0 0 1-2.223 1.496A6.8 6.8 0 0 1 8 14.96m0-1.4q2.344 0 3.973-1.627 1.626-1.628 1.627-3.973 0-2.344-1.627-3.972Q10.343 2.36 8 2.36q-2.345 0-3.973 1.628C2.399 5.616 2.4 6.397 2.4 7.96q0 2.345 1.627 3.973C5.654 13.561 6.438 13.56 8 13.56"/></svg>`
};
export const iconArrowElbowDownRight24: {
  name: 'arrow-elbow-down-right-24';
  data: string;
} = {
  name: 'arrow-elbow-down-right-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m20.03 17.03-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H6a.75.75 0 0 1-.75-.75V3a.75.75 0 0 1 1.5 0v12.75h10.94l-3.22-3.22a.75.75 0 1 1 1.06-1.06l4.5 4.5a.747.747 0 0 1 0 1.06"/></svg>`
};
export const iconAuftraege36: {
  name: 'auftraege-36';
  data: string;
} = {
  name: 'auftraege-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M26 6.298a2.34 2.34 0 0 0-2.33-2.33h-1.8a4 4 0 0 0-7.75 0h-1.79A2.34 2.34 0 0 0 10 6.298v3.67h16zm-2 1.67H12v-1.67a.33.33 0 0 1 .33-.33H16v-1a2 2 0 1 1 4 0v1h3.67a.33.33 0 0 1 .33.33zm5.29-2.2H27v2h2v25H7v-25h2v-2H7a1.75 1.75 0 0 0-2 1.69v25.62a1.7 1.7 0 0 0 1.71 1.69h22.58a1.7 1.7 0 0 0 1.71-1.69V7.458a1.7 1.7 0 0 0-1.71-1.69M11 14.96a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zm-1 5a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1"/></svg>`
};
export const iconCalendar16: {
  name: 'calendar-16';
  data: string;
} = {
  name: 'calendar-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.833 1.614h-1.75v-.577c0-.153-.061-.3-.17-.408a.587.587 0 0 0-.825 0 .57.57 0 0 0-.171.408v.577H5.083v-.577c0-.153-.061-.3-.17-.408a.587.587 0 0 0-.825 0 .57.57 0 0 0-.171.408v.577h-1.75c-.31 0-.606.121-.825.338-.22.216-.342.51-.342.816v11.538c0 .306.123.6.342.816.218.216.515.338.825.338h11.666c.31 0 .607-.122.825-.338.22-.216.342-.51.342-.816V2.768c0-.306-.123-.6-.342-.816a1.17 1.17 0 0 0-.825-.338M3.917 2.768v.577c0 .153.061.3.17.408a.587.587 0 0 0 .825 0 .57.57 0 0 0 .171-.408v-.577h5.834v.577c0 .153.061.3.17.408a.587.587 0 0 0 .825 0 .57.57 0 0 0 .171-.408v-.577h1.75v2.307H2.167V2.768zm9.916 11.538H2.167V6.23h11.666v8.077Z"/></svg>`
};
export const iconCalendarBlank24: {
  name: 'calendar-blank-24';
  data: string;
} = {
  name: 'calendar-blank-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 3h-2.25v-.75a.75.75 0 1 0-1.5 0V3h-7.5v-.75a.75.75 0 0 0-1.5 0V3H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3M6.75 4.5v.75a.75.75 0 0 0 1.5 0V4.5h7.5v.75a.75.75 0 1 0 1.5 0V4.5h2.25v3h-15v-3zm12.75 15h-15V9h15z"/></svg>`
};
export const iconCaretUpDown16: {
  name: 'caret-up-down-16';
  data: string;
} = {
  name: 'caret-up-down-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M11 10.5H5a.5.5 0 0 0-.354.854l3 3a.5.5 0 0 0 .708 0l3-3A.5.5 0 0 0 11 10.5M4.538 5.191a.5.5 0 0 1 .108-.545l3-3a.5.5 0 0 1 .708 0l3 3A.5.5 0 0 1 11 5.5H5a.5.5 0 0 1-.462-.309"/></svg>`
};
export const iconCheck16: {
  name: 'check-16';
  data: string;
} = {
  name: 'check-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M5.631 11.39 2.55 8.28 1.5 9.331 5.631 13.5 14.5 4.551 13.458 3.5 5.63 11.39Z"/></svg>`
};
export const iconCheck24: {
  name: 'check-24';
  data: string;
} = {
  name: 'check-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m21.53 7.28-12 12a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L9 17.69 20.47 6.22a.75.75 0 1 1 1.06 1.06"/></svg>`
};
export const iconCheckList16: {
  name: 'check-list-16';
  data: string;
} = {
  name: 'check-list-16',
  data: `<svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><path d="m5.631 14.416-3.082-3.11L1.5 12.359l4.131 4.169 8.869-8.95-1.042-1.051z"/></svg>`
};
export const iconChevronDown16: {
  name: 'chevron-down-16';
  data: string;
} = {
  name: 'chevron-down-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.97 5.22a.75.75 0 0 1 1.06 0L8 9.19l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 0-1.06"/></svg>`
};
export const iconChevronDown24: {
  name: 'chevron-down-24';
  data: string;
} = {
  name: 'chevron-down-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.47 16.281-7.5-7.5A.75.75 0 0 1 5.03 7.72L12 14.69l6.969-6.97a.75.75 0 1 1 1.061 1.06l-7.5 7.5a.747.747 0 0 1-1.061 0Z"/></svg>`
};
export const iconChevronLeft16: {
  name: 'chevron-left-16';
  data: string;
} = {
  name: 'chevron-left-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.78 2.97a.75.75 0 0 1 0 1.06L6.81 8l3.97 3.97a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0"/></svg>`
};
export const iconChevronLeft24: {
  name: 'chevron-left-24';
  data: string;
} = {
  name: 'chevron-left-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M15.53 18.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L8.56 12z"/></svg>`
};
export const iconChevronRight16: {
  name: 'chevron-right-16';
  data: string;
} = {
  name: 'chevron-right-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.22 2.97a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06L9.19 8 5.22 4.03a.75.75 0 0 1 0-1.06"/></svg>`
};
export const iconChevronRight24: {
  name: 'chevron-right-24';
  data: string;
} = {
  name: 'chevron-right-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m17.03 12.53-7.5 7.5a.75.75 0 1 1-1.06-1.06L15.44 12 8.47 5.03a.75.75 0 1 1 1.06-1.06l7.5 7.5a.747.747 0 0 1 0 1.06"/></svg>`
};
export const iconChevronUp16: {
  name: 'chevron-up-16';
  data: string;
} = {
  name: 'chevron-up-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.47 5.22a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 1 1-1.06 1.06L8 6.81l-3.97 3.97a.75.75 0 1 1-1.06-1.06z"/></svg>`
};
export const iconChevronUp24: {
  name: 'chevron-up-24';
  data: string;
} = {
  name: 'chevron-up-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m12.531 6.719 7.5 7.5a.75.75 0 1 1-1.061 1.061L12 8.31l-6.969 6.97a.75.75 0 0 1-1.061-1.06l7.5-7.5a.75.75 0 0 1 1.061 0Z"/></svg>`
};
export const iconClipboard36: {
  name: 'clipboard-36';
  data: string;
} = {
  name: 'clipboard-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M26 5.53a2.34 2.34 0 0 0-2.33-2.33h-1.8a4 4 0 0 0-7.75 0h-1.79A2.34 2.34 0 0 0 10 5.53V9.2h16zM24 7.2H12V5.53a.33.33 0 0 1 .33-.33H16v-1a2 2 0 1 1 4 0v1h3.67a.33.33 0 0 1 .33.33zM29.29 5H27v2h2v25H7V7h2V5H7a1.75 1.75 0 0 0-2 1.69v25.62A1.7 1.7 0 0 0 6.71 34h22.58A1.7 1.7 0 0 0 31 32.31V6.69A1.7 1.7 0 0 0 29.29 5m-5.526 11.542a3.93 3.93 0 0 1-.638 2.7c-.683 1.018-1.991 1.999-4.4 2.58v2.034a1.047 1.047 0 1 1-2.094 0v-3.773l.858-.156c.868-.16 1.998-.452 2.872-.966.872-.513 1.439-1.21 1.32-2.188a2.4 2.4 0 0 0-1.014-1.643 4.12 4.12 0 0 0-3.241-.576l-.012.003a7.44 7.44 0 0 0-3.142 1.37 1.05 1.05 0 0 1-1.315-1.636l.006-.005a9.55 9.55 0 0 1 4.062-1.758 6.2 6.2 0 0 1 4.866.93l.002.002a4.48 4.48 0 0 1 1.87 3.082m-4.7 10.907a1.376 1.376 0 1 1-2.753 0 1.376 1.376 0 0 1 2.752 0Z"/></svg>`
};
export const iconClose16: {
  name: 'close-16';
  data: string;
} = {
  name: 'close-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.03 11.97a.751.751 0 1 1-1.062 1.062L8 9.062l-3.97 3.969a.751.751 0 1 1-1.062-1.063L6.938 8 2.969 4.03a.751.751 0 1 1 1.063-1.062L8 6.938l3.97-3.97a.751.751 0 1 1 1.062 1.062L9.062 8z"/></svg>`
};
export const iconClose24: {
  name: 'close-24';
  data: string;
} = {
  name: 'close-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M19.28 18.22a.75.75 0 1 1-1.06 1.06L12 13.06l-6.22 6.22a.75.75 0 0 1-1.06-1.06L10.94 12 4.72 5.78a.75.75 0 1 1 1.06-1.06L12 10.94l6.22-6.22a.75.75 0 1 1 1.06 1.06L13.06 12z"/></svg>`
};
export const iconClose36: {
  name: 'close-36';
  data: string;
} = {
  name: 'close-36',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"><path d="M19.47 17.973 30.76 6.705a.995.995 0 0 0-.721-1.645 1 1 0 0 0-.69.24L18.06 16.566 6.77 5.289a1.005 1.005 0 0 0-1.714.708 1 1 0 0 0 .295.708l11.3 11.268-11.3 11.268a.998.998 0 1 0 1.41 1.406l11.3-11.267 11.29 11.267a1.002 1.002 0 0 0 1.65-.719 1 1 0 0 0-.24-.687z"/></svg>`
};
export const iconCloseCircle16: {
  name: 'close-circle-16';
  data: string;
} = {
  name: 'close-circle-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M10.716 6.1 8.816 8l1.9 1.9a.577.577 0 0 1-.816.816L8 8.816l-1.9 1.9a.577.577 0 0 1-.816-.816l1.9-1.9-1.9-1.9a.577.577 0 1 1 .816-.816l1.9 1.9 1.9-1.9a.578.578 0 0 1 .816.816M15.5 8A7.5 7.5 0 1 1 8 .5 7.51 7.51 0 0 1 15.5 8m-1.154 0A6.346 6.346 0 1 0 8 14.346 6.354 6.354 0 0 0 14.346 8"/></svg>`
};
export const iconCloseCircle24: {
  name: 'close-circle-24';
  data: string;
} = {
  name: 'close-circle-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M15.53 9.53 13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 1 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 1.06-1.06L12 10.94l2.47-2.47a.751.751 0 0 1 1.06 1.06M21.75 12A9.75 9.75 0 1 1 12 2.25 9.76 9.76 0 0 1 21.75 12m-1.5 0A8.25 8.25 0 1 0 12 20.25 8.26 8.26 0 0 0 20.25 12"/></svg>`
};
export const iconDelete16: {
  name: 'delete-16';
  data: string;
} = {
  name: 'delete-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 14.46q-.62 0-1.06-.44a1.44 1.44 0 0 1-.44-1.06V3.21H2v-1.5h3.75V.96h4.5v.75H14v1.5h-.75v9.75q0 .62-.441 1.06t-1.059.44zm7.5-11.25h-7.5v9.75h7.5zm-6 8.25h1.5V4.71h-1.5zm3 0h1.5V4.71h-1.5z"/></svg>`
};
export const iconDelete24: {
  name: 'delete-24';
  data: string;
} = {
  name: 'delete-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M20.25 4.5H16.5v-.75a2.25 2.25 0 0 0-2.25-2.25h-4.5A2.25 2.25 0 0 0 7.5 3.75v.75H3.75a.75.75 0 0 0 0 1.5h.75v13.5A1.5 1.5 0 0 0 6 21h12a1.5 1.5 0 0 0 1.5-1.5V6h.75a.75.75 0 1 0 0-1.5M9 3.75A.75.75 0 0 1 9.75 3h4.5a.75.75 0 0 1 .75.75v.75H9zm9 15.75H6V6h12zm-7.5-9.75v6a.75.75 0 1 1-1.5 0v-6a.75.75 0 0 1 1.5 0m4.5 0v6a.75.75 0 1 1-1.5 0v-6a.75.75 0 1 1 1.5 0"/></svg>`
};
export const iconDocumentAdd16: {
  name: 'document-add-16';
  data: string;
} = {
  name: 'document-add-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 13.857a.357.357 0 0 1-.357.357H9.049a4.7 4.7 0 0 1-.77 1.072h4.864a1.43 1.43 0 0 0 1.428-1.429V6.591c0-.378-.15-.742-.418-1.01L9.989 1.42l-.035-.029a1.5 1.5 0 0 0-.182-.15l-.057-.031-.034-.018-.036-.02q-.058-.035-.119-.062a1.4 1.4 0 0 0-.445-.099l-.042-.005A.4.4 0 0 0 8.98 1H4.571a1.43 1.43 0 0 0-1.428 1.429v5.355a4.6 4.6 0 0 1 1.071-.301V2.429a.357.357 0 0 1 .357-.358h4.286v3.215a1.43 1.43 0 0 0 1.429 1.428H13.5zM9.929 2.872l2.77 2.77h-2.413a.357.357 0 0 1-.357-.356zm-1.072 9.2a3.929 3.929 0 1 0-7.858 0 3.929 3.929 0 0 0 7.858 0m-3.571.357v1.787a.357.357 0 0 1-.714 0V12.43h-1.79a.357.357 0 0 1 0-.715h1.79V9.93a.357.357 0 0 1 .714 0v1.785h1.783a.357.357 0 1 1 0 .715z"/></svg>`
};
export const iconDocumentAdd36: {
  name: 'document-add-36';
  data: string;
} = {
  name: 'document-add-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M28.864 30.343a1.157 1.157 0 0 1-1.157 1.157h-9.905a9.7 9.7 0 0 1-1.602 1.929h11.507a3.086 3.086 0 0 0 3.086-3.086V14.65c0-.819-.325-1.604-.904-2.182l-8.992-8.992c-.067-.067-.142-.124-.215-.18l-.072-.056a3.1 3.1 0 0 0-1.29-.61 3 3 0 0 0-.606-.06H8.229a3.086 3.086 0 0 0-3.086 3.087v12.17a9.6 9.6 0 0 1 1.928-.767V5.658c0-.639.52-1.158 1.158-1.158h9.707v8.357a2.57 2.57 0 0 0 2.571 2.572l8.357-.002zm-9-25.17 8.33 8.326-7.687.001a.64.64 0 0 1-.643-.643V5.172ZM18 26.356a8.357 8.357 0 1 1-16.714 0 8.357 8.357 0 0 1 16.714 0m-7.714-5.143a.643.643 0 1 0-1.286 0v4.5H4.5A.643.643 0 1 0 4.5 27H9v4.5a.643.643 0 0 0 1.286 0V27h4.5a.643.643 0 1 0 0-1.286h-4.5z"/></svg>`
};
export const iconDownload24: {
  name: 'download-24';
  data: string;
} = {
  name: 'download-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21 14.25v5.25a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-5.25a.75.75 0 1 1 1.5 0v5.25h15v-5.25a.75.75 0 1 1 1.5 0m-9.53.53a.747.747 0 0 0 1.06 0l3.75-3.75a.75.75 0 1 0-1.06-1.06l-2.47 2.47V3.75a.75.75 0 1 0-1.5 0v8.69L8.78 9.97a.75.75 0 0 0-1.06 1.06z"/></svg>`
};
export const iconEdit16: {
  name: 'edit-16';
  data: string;
} = {
  name: 'edit-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="m9.487 5.847.664.665-6.542 6.542h-.665v-.664zm2.6-4.347a.72.72 0 0 0-.506.21l-1.322 1.32 2.708 2.709 1.322-1.322a.72.72 0 0 0 0-1.018l-1.69-1.69a.7.7 0 0 0-.513-.209Zm-2.6 2.304L1.5 11.79v2.708h2.708l7.987-7.986z"/></svg>`
};
export const iconEdit24: {
  name: 'edit-24';
  data: string;
} = {
  name: 'edit-24',
  data: `<svg width="24" height="25" xmlns="http://www.w3.org/2000/svg"><path d="m21.31 6.905-4.188-4.19a1.5 1.5 0 0 0-2.122 0L3.44 14.277a1.5 1.5 0 0 0-.44 1.06v4.19a1.5 1.5 0 0 0 1.5 1.5h4.19a1.5 1.5 0 0 0 1.06-.44l11.56-11.56a1.5 1.5 0 0 0 0-2.122m-16.5 8.122 7.94-7.94 1.565 1.565-7.94 7.938zm-.31 1.81 2.69 2.69H4.5zm4.5 2.38-1.565-1.565 7.94-7.94 1.565 1.565zm9-9-4.19-4.19 2.25-2.25 4.19 4.188L18 10.216Z"/></svg>`
};
export const iconEmail16: {
  name: 'email-16';
  data: string;
} = {
  name: 'email-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M14.667 4c0-.733-.6-1.333-1.334-1.333H2.667c-.734 0-1.334.6-1.334 1.333v8c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333zm-1.334 0L8 7.333 2.667 4zm0 8H2.667V5.333L8 8.667l5.333-3.334z"/></svg>`
};
export const iconError16: {
  name: 'error-16';
  data: string;
} = {
  name: 'error-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M14.8 11.756 9.334 2.264a1.548 1.548 0 0 0-2.668 0L1.2 11.756a1.47 1.47 0 0 0 0 1.482A1.52 1.52 0 0 0 2.534 14h10.932A1.52 1.52 0 0 0 15 12.497c0-.26-.069-.516-.2-.741m-.867.982a.53.53 0 0 1-.467.262H2.534a.53.53 0 0 1-.467-.262.48.48 0 0 1 0-.483l5.465-9.492a.547.547 0 0 1 .938 0l5.466 9.492a.48.48 0 0 1-.003.482ZM7.5 9V6.5a.5.5 0 1 1 1 0V9a.5.5 0 1 1-1 0m1.25 2.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0"/></svg>`
};
export const iconError24: {
  name: 'error-24';
  data: string;
} = {
  name: 'error-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M22.2 17.633 14.002 3.396a2.322 2.322 0 0 0-4.004 0L1.8 17.633a2.2 2.2 0 0 0 0 2.224A2.28 2.28 0 0 0 3.802 21h16.396a2.28 2.28 0 0 0 2-1.143 2.2 2.2 0 0 0 .002-2.224m-1.3 1.473a.8.8 0 0 1-.702.394H3.802a.8.8 0 0 1-.702-.394.71.71 0 0 1 0-.724l8.2-14.236a.82.82 0 0 1 1.406 0l8.198 14.238a.71.71 0 0 1-.003.723ZM11.25 13.5V9.75a.75.75 0 1 1 1.5 0v3.75a.75.75 0 1 1-1.5 0m1.875 3.375a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0"/></svg>`
};
export const iconExternal24: {
  name: 'external-24';
  data: string;
} = {
  name: 'external-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M21 9.75a.75.75 0 1 1-1.5 0V5.561l-6.218 6.22a.75.75 0 1 1-1.062-1.062L18.44 4.5h-4.19a.75.75 0 1 1 0-1.5h6a.75.75 0 0 1 .75.75zM17.25 12a.75.75 0 0 0-.75.75v6.75h-12v-12h6.75a.75.75 0 1 0 0-1.5H4.5A1.5 1.5 0 0 0 3 7.5v12A1.5 1.5 0 0 0 4.5 21h12a1.5 1.5 0 0 0 1.5-1.5v-6.75a.75.75 0 0 0-.75-.75"/></svg>`
};
export const iconExternalLink16: {
  name: 'external-link-16';
  data: string;
} = {
  name: 'external-link-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 5.875a.542.542 0 0 1-1.083 0V2.85L8.926 7.342a.542.542 0 1 1-.767-.767l4.491-4.492H9.625a.542.542 0 0 1 0-1.083h4.333a.54.54 0 0 1 .542.542zM11.792 7.5a.54.54 0 0 0-.542.542v4.875H2.583V4.25h4.875a.542.542 0 0 0 0-1.083H2.583A1.083 1.083 0 0 0 1.5 4.25v8.667A1.083 1.083 0 0 0 2.583 14h8.667a1.083 1.083 0 0 0 1.083-1.083V8.042a.54.54 0 0 0-.541-.542"/></svg>`
};
export const iconFaq36: {
  name: 'faq-36';
  data: string;
} = {
  name: 'faq-36',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"><path d="M4.5 15.428a9.643 9.643 0 1 1 5.027 8.468.96.96 0 0 0-.713-.085L4.635 24.93l1.121-4.181a.96.96 0 0 0-.085-.711 9.6 9.6 0 0 1-1.171-4.61m9.643-11.57A11.57 11.57 0 0 0 3.795 20.612L2.628 24.97a1.607 1.607 0 0 0 1.967 1.967l4.355-1.165a11.571 11.571 0 1 0 5.193-21.915Zm7.714 28.285a11.54 11.54 0 0 1-8.655-3.89c.904.065 1.813.036 2.711-.088a9.6 9.6 0 0 0 5.944 2.05 9.6 9.6 0 0 0 4.616-1.176.96.96 0 0 1 .713-.085l4.179 1.119-1.121-4.181a.96.96 0 0 1 .085-.711 9.6 9.6 0 0 0 1.171-4.61 9.64 9.64 0 0 0-4.918-8.408 12.8 12.8 0 0 0-.964-2.538 11.578 11.578 0 0 1 6.587 16.132l1.167 4.356a1.606 1.606 0 0 1-1.968 1.967l-4.354-1.165c-1.611.81-3.39 1.23-5.193 1.228M15.43 21.214a1.286 1.286 0 1 1-2.572 0 1.286 1.286 0 0 1 2.572 0m-3.215-9.312.003-.041a1.95 1.95 0 0 1 .235-.78c.196-.348.602-.795 1.69-.795 1.09 0 1.496.447 1.692.794.138.252.22.53.237.817v.003c-.002.672-.392 1.104-1.175 1.973l-.023.027c-.746.826-1.694 1.946-1.694 3.778a.964.964 0 1 0 1.928 0c0-1.06.496-1.708 1.198-2.487l.161-.177C17.11 14.31 18 13.338 18 11.893v-.003l-.001-.063a3.9 3.9 0 0 0-.485-1.693c-.527-.939-1.569-1.777-3.371-1.777-1.804 0-2.844.838-3.371 1.777a3.9 3.9 0 0 0-.486 1.734v.025a.964.964 0 0 0 1.928.009Z"/></svg>`
};
export const iconInfo16: {
  name: 'info-16';
  data: string;
} = {
  name: 'info-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5m0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5M9 11a.5.5 0 0 1-.5.5 1 1 0 0 1-1-1V8a.5.5 0 0 1 0-1 1 1 0 0 1 1 1v2.5a.5.5 0 0 1 .5.5M7 5.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0"/></svg>`
};
export const iconInfo24: {
  name: 'info-24';
  data: string;
} = {
  name: 'info-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25m0 18A8.25 8.25 0 1 1 20.25 12 8.26 8.26 0 0 1 12 20.25m1.5-3.75a.75.75 0 0 1-.75.75 1.5 1.5 0 0 1-1.5-1.5V12a.75.75 0 1 1 0-1.5 1.5 1.5 0 0 1 1.5 1.5v3.75a.75.75 0 0 1 .75.75m-3-8.625a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0"/></svg>`
};
export const iconListChecks24: {
  name: 'list-checks-24';
  data: string;
} = {
  name: 'list-checks-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21 12a.75.75 0 0 1-.75.75H12a.75.75 0 1 1 0-1.5h8.25A.75.75 0 0 1 21 12m-9-5.25h8.25a.75.75 0 1 0 0-1.5H12a.75.75 0 1 0 0 1.5m8.25 10.5H12a.75.75 0 1 0 0 1.5h8.25a.75.75 0 1 0 0-1.5M7.72 3.97 5.25 6.44l-.97-.97a.75.75 0 1 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06m0 6-2.47 2.47-.97-.97a.75.75 0 1 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06m0 6-2.47 2.47-.97-.97a.75.75 0 1 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06"/></svg>`
};
export const iconLogout36: {
  name: 'logout-36';
  data: string;
} = {
  name: 'logout-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.204 4.164a4.1 4.1 0 0 1 2.907-1.204h12.445a1 1 0 0 1 0 2H10.11A2.11 2.11 0 0 0 8 7.071V28.85a2.11 2.11 0 0 0 2.111 2.111h12.445a1 1 0 0 1 0 2H10.11A4.11 4.11 0 0 1 6 28.85V7.071c0-1.09.433-2.136 1.204-2.907m14.644 8.281a1 1 0 0 1 1.415-1.414l6.222 6.222a1 1 0 0 1 0 1.414l-6.222 6.222a1 1 0 0 1-1.415-1.414l4.516-4.515H13.222a1 1 0 1 1 0-2h13.142z"/></svg>`
};
export const iconMaintenance36: {
  name: 'maintenance-36';
  data: string;
} = {
  name: 'maintenance-36',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"><path fill-rule="evenodd" d="M20.7 22.11a1.7 1.7 0 0 0-.064-.694l-.526-1.703a8.4 8.4 0 0 1 3.08-1.84l1.39 1.43a1.72 1.72 0 0 0 2.467 0l1.418-1.462a8.4 8.4 0 0 1 3.093 1.797L31 21.578a1.71 1.71 0 0 0 1.233 2.128l1.91.476a8.4 8.4 0 0 1-.044 3.589l-1.728.393a1.71 1.71 0 0 0-1.257 2.17l.526 1.701a8.4 8.4 0 0 1-3.08 1.84l-1.39-1.431a1.72 1.72 0 0 0-2.467 0l-1.418 1.462a8.4 8.4 0 0 1-3.093-1.795l.558-1.94a1.71 1.71 0 0 0-1.233-2.13l-1.91-.475a8.4 8.4 0 0 1 .044-3.588l1.728-.395a1.71 1.71 0 0 0 1.32-1.472Zm6.766 5.355a2.25 2.25 0 1 0-3.182-3.182 2.25 2.25 0 0 0 3.182 3.182"/><path fill-rule="evenodd" d="M30.525 12.225H5.475v15.338a2.96 2.96 0 0 0 2.963 2.962h8.271c.355.697.788 1.351 1.29 1.95h-9.56a4.91 4.91 0 0 1-4.913-4.913V8.439a4.913 4.913 0 0 1 4.913-4.913h19.124a4.913 4.913 0 0 1 4.913 4.913V18a10.3 10.3 0 0 0-1.95-1.29v-4.485Zm-25.05-1.95h25.05V8.438a2.96 2.96 0 0 0-2.962-2.963H8.438a2.96 2.96 0 0 0-2.963 2.963z"/></svg>`
};
export const iconMenu36: {
  name: 'menu-36';
  data: string;
} = {
  name: 'menu-36',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"><path d="M4.933 10h26.134a.9.9 0 0 0 .66-.293C31.902 9.52 32 9.265 32 9s-.098-.52-.273-.707a.9.9 0 0 0-.66-.293H4.933a.9.9 0 0 0-.66.293A1.04 1.04 0 0 0 4 9c0 .265.098.52.273.707a.9.9 0 0 0 .66.293m26.134 9H4.933a.9.9 0 0 1-.66-.293A1.04 1.04 0 0 1 4 18c0-.265.098-.52.273-.707a.9.9 0 0 1 .66-.293h26.134c.247 0 .485.105.66.293.175.187.273.442.273.707s-.098.52-.273.707a.9.9 0 0 1-.66.293m0 9H4.933a.9.9 0 0 1-.66-.293A1.04 1.04 0 0 1 4 27c0-.265.098-.52.273-.707a.9.9 0 0 1 .66-.293h26.134c.247 0 .485.105.66.293.175.187.273.442.273.707s-.098.52-.273.707a.9.9 0 0 1-.66.293"/></svg>`
};
export const iconPhone16: {
  name: 'phone-16';
  data: string;
} = {
  name: 'phone-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M4.36 3.333a8.2 8.2 0 0 0 .3 1.727l-.8.8a10 10 0 0 1-.507-2.527zm6.573 8.014q.852.24 1.734.3v.993c-.88-.06-1.727-.233-2.534-.5zM5 2H2.667C2.3 2 2 2.3 2 2.667 2 8.927 7.073 14 13.333 14c.367 0 .667-.3.667-.667v-2.326c0-.367-.3-.667-.667-.667a7.6 7.6 0 0 1-2.38-.38.68.68 0 0 0-.68.16l-1.466 1.467a10.1 10.1 0 0 1-4.394-4.394L5.88 5.727a.67.67 0 0 0 .167-.68 7.6 7.6 0 0 1-.38-2.38C5.667 2.3 5.367 2 5 2"/></svg>`
};
export const iconReset24: {
  name: 'reset-24';
  data: string;
} = {
  name: 'reset-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21 12a9 9 0 0 1-8.88 9H12a8.94 8.94 0 0 1-6.178-2.456.751.751 0 0 1 1.031-1.09 7.5 7.5 0 1 0-.18-10.738L4.18 9h2.57a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.794l2.648-2.419A9 9 0 0 1 21 12"/></svg>`
};
export const iconSave16: {
  name: 'save-16';
  data: string;
} = {
  name: 'save-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M14.658 4.5 11.5 1.342A1.16 1.16 0 0 0 10.675 1H2.167A1.167 1.167 0 0 0 1 2.167v11.666A1.167 1.167 0 0 0 2.167 15h11.666A1.167 1.167 0 0 0 15 13.833V5.325a1.16 1.16 0 0 0-.342-.825m-3.741 9.333H5.083V9.75h5.834zm2.916 0h-1.75V9.75a1.167 1.167 0 0 0-1.166-1.167H5.083A1.167 1.167 0 0 0 3.917 9.75v4.083h-1.75V2.167h8.508l3.158 3.158zm-3.5-9.916a.583.583 0 0 1-.583.583H5.667a.583.583 0 1 1 0-1.167H9.75a.583.583 0 0 1 .583.584"/></svg>`
};
export const iconSave24: {
  name: 'save-24';
  data: string;
} = {
  name: 'save-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M20.56 7.5 16.5 3.44A1.5 1.5 0 0 0 15.44 3H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5V8.56a1.5 1.5 0 0 0-.44-1.06m-4.81 12h-7.5v-5.25h7.5zm3.75 0h-2.25v-5.25a1.5 1.5 0 0 0-1.5-1.5h-7.5a1.5 1.5 0 0 0-1.5 1.5v5.25H4.5v-15h10.94l4.06 4.06zM15 6.75a.75.75 0 0 1-.75.75H9A.75.75 0 0 1 9 6h5.25a.75.75 0 0 1 .75.75"/></svg>`
};
export const iconSearch16: {
  name: 'search-16';
  data: string;
} = {
  name: 'search-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="m14.842 14.081-3.366-3.365a5.924 5.924 0 1 0-.76.76l3.365 3.366a.538.538 0 0 0 .761-.76ZM2.091 6.931a4.84 4.84 0 1 1 9.681 0 4.84 4.84 0 0 1-9.681 0"/></svg>`
};
export const iconSearch24: {
  name: 'search-24';
  data: string;
} = {
  name: 'search-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m21.53 20.47-4.693-4.694a8.26 8.26 0 1 0-1.06 1.06l4.692 4.695a.75.75 0 1 0 1.062-1.062ZM3.75 10.5a6.75 6.75 0 1 1 6.75 6.75 6.76 6.76 0 0 1-6.75-6.75"/></svg>`
};
export const iconShoppingBag36: {
  name: 'shopping-bag-36';
  data: string;
} = {
  name: 'shopping-bag-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M25 12V9.05A7.001 7.001 0 0 0 13.05 4.1 7 7 0 0 0 11 9.05v7a1 1 0 0 0 2 0V14h8v-2h-8V9.05a5 5 0 0 1 10 0V16a1 1 0 0 0 2 0v-2h5v18H6V14h3v-2H4v20.09A1.91 1.91 0 0 0 5.91 34h24.18A1.91 1.91 0 0 0 32 32.09V12z"/></svg>`
};
export const iconShoppingCart16: {
  name: 'shopping-cart-16';
  data: string;
} = {
  name: 'shopping-cart-16',
  data: `<svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M14.878 4.305a.52.52 0 0 0-.398-.186H4.025l-.317-1.74a1.04 1.04 0 0 0-1.02-.852h-1.17a.518.518 0 0 0 0 1.037h1.167l1.656 9.091c.05.27.168.522.346.73a1.814 1.814 0 1 0 2.877.548h2.944a1.815 1.815 0 1 0 1.639-1.037H5.872a.52.52 0 0 1-.51-.426l-.206-1.13h7.518a1.555 1.555 0 0 0 1.53-1.277l.788-4.333a.52.52 0 0 0-.114-.425M6.703 13.71a.778.778 0 1 1-1.556 0 .778.778 0 0 1 1.556 0m6.222 0a.778.778 0 1 1-1.555 0 .778.778 0 0 1 1.555 0m.259-4.832a.52.52 0 0 1-.512.426H4.968l-.754-4.148h9.645z"/></svg>`
};
export const iconShoppingCart24: {
  name: 'shopping-cart-24';
  data: string;
} = {
  name: 'shopping-cart-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M20.826 5.52a.75.75 0 0 0-.576-.27H5.126l-.458-2.518A1.5 1.5 0 0 0 3.192 1.5H1.5a.75.75 0 0 0 0 1.5h1.688l2.396 13.152c.07.39.243.755.5 1.057a2.625 2.625 0 1 0 4.162.791h4.258a2.625 2.625 0 1 0 2.37-1.5H7.798a.75.75 0 0 1-.738-.616l-.297-1.634h10.875a2.25 2.25 0 0 0 2.214-1.848l1.14-6.268a.75.75 0 0 0-.165-.615ZM9 19.124a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0m9 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0m.375-6.99a.75.75 0 0 1-.74.615H6.488l-1.09-6H19.35l-.976 5.384Z"/></svg>`
};
export const iconShoppingCart36: {
  name: 'shopping-cart-36';
  data: string;
} = {
  name: 'shopping-cart-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M13.33 32a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5M27 32a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5m6.08-26.63a1 1 0 0 0-.77-.37H11.49l.65 2H31l-2.67 12h-15L8.76 4.53a1 1 0 0 0-.66-.65L4 2.62a1.004 1.004 0 0 0-.59 1.92L7 5.64l4.59 14.5-1.64 1.34-.13.13A2.66 2.66 0 0 0 9.74 25 2.75 2.75 0 0 0 12 26h16.69a1 1 0 0 0 0-2H11.84a.67.67 0 0 1-.56-1l2.41-2h15.44a1 1 0 0 0 1-.78l3.17-14a1 1 0 0 0-.22-.85"/></svg>`
};
export const iconSortDefault16: {
  name: 'sort-default-16';
  data: string;
} = {
  name: 'sort-default-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M11 10.5H5a.5.5 0 0 0-.354.854l3 3a.5.5 0 0 0 .708 0l3-3A.5.5 0 0 0 11 10.5M4.538 5.191a.5.5 0 0 1 .108-.545l3-3a.5.5 0 0 1 .708 0l3 3A.5.5 0 0 1 11 5.5H5a.5.5 0 0 1-.462-.309"/></svg>`
};
export const iconSuccess16: {
  name: 'success-16';
  data: string;
} = {
  name: 'success-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M10.854 6.146a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 9.293l3.146-3.147a.5.5 0 0 1 .708 0M14.5 8A6.5 6.5 0 1 1 8 1.5 6.507 6.507 0 0 1 14.5 8m-1 0A5.5 5.5 0 1 0 8 13.5 5.506 5.506 0 0 0 13.5 8"/></svg>`
};
export const iconSuccess24: {
  name: 'success-24';
  data: string;
} = {
  name: 'success-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.28 9.22a.75.75 0 0 1 0 1.06l-5.25 5.25a.747.747 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 4.72-4.72a.75.75 0 0 1 1.06 0M21.75 12A9.75 9.75 0 1 1 12 2.25 9.76 9.76 0 0 1 21.75 12m-1.5 0A8.25 8.25 0 1 0 12 20.25 8.26 8.26 0 0 0 20.25 12"/></svg>`
};
export const iconSuccess56: {
  name: 'success-56';
  data: string;
} = {
  name: 'success-56',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56"><path fill-rule="evenodd" d="M54.25 28c0 14.498-11.752 26.25-26.25 26.25S1.75 42.498 1.75 28 13.502 1.75 28 1.75 54.25 13.502 54.25 28M56 28c0 15.464-12.536 28-28 28S0 43.464 0 28 12.536 0 28 0s28 12.536 28 28m-31.292 8.708 16-16a1 1 0 0 0-1.415-1.415L24.001 34.587l-6.293-6.294a1 1 0 1 0-1.415 1.415l7 7a1 1 0 0 0 1.415 0"/></svg>`
};
export const iconTagLine36: {
  name: 'tag-line-36';
  data: string;
} = {
  name: 'tag-line-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M10.52 11.95a1.43 1.43 0 1 0 0-2.86 1.43 1.43 0 0 0 0 2.86"/><path d="M31.93 19.2 17.33 4.6a2 2 0 0 0-1.41-.6H6a2 2 0 0 0-2 2v9.92a2 2 0 0 0 .59 1.41l14.6 14.6a2 2 0 0 0 2.83 0l9.9-9.9a2 2 0 0 0 .01-2.83M20.62 30.52 6 15.91V6h9.92l14.6 14.62z"/></svg>`
};
export const iconUpload36: {
  name: 'upload-36';
  data: string;
} = {
  name: 'upload-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M33.75 19.125v9a2.25 2.25 0 0 1-2.25 2.25h-27a2.25 2.25 0 0 1-2.25-2.25v-9a2.25 2.25 0 0 1 2.25-2.25h6.75a1.125 1.125 0 1 1 0 2.25H4.5v9h27v-9h-6.75a1.125 1.125 0 1 1 0-2.25h6.75a2.25 2.25 0 0 1 2.25 2.25m-21.704-8.204 4.829-4.83V18a1.125 1.125 0 1 0 2.25 0V6.09l4.83 4.83a1.126 1.126 0 1 0 1.59-1.59l-6.75-6.75a1.124 1.124 0 0 0-1.59 0l-6.75 6.75a1.126 1.126 0 0 0 1.59 1.59Zm16.079 12.704a1.687 1.687 0 1 0-3.375 0 1.687 1.687 0 0 0 3.375 0"/></svg>`
};
export const iconUser36: {
  name: 'user-36';
  data: string;
} = {
  name: 'user-36',
  data: `<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M17.98 1.96a15.98 15.98 0 1 0 15.98 15.98A16 16 0 0 0 17.98 1.96M9.119 28.907a10.34 10.34 0 0 1 17.722 0 14.07 14.07 0 0 1-17.722 0m3.534-12.22a5.327 5.327 0 1 1 10.654 0 5.327 5.327 0 0 1-10.654 0m15.59 10.91a12.15 12.15 0 0 0-6.266-4.916 7.206 7.206 0 1 0-7.99 0 12.15 12.15 0 0 0-6.267 4.916 14.1 14.1 0 1 1 20.523 0"/></svg>`
};
export const iconWarning16: {
  name: 'warning-16';
  data: string;
} = {
  name: 'warning-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M8 4.5a.5.5 0 0 1 .5.5v3.5a.5.5 0 1 1-1 0V5a.5.5 0 0 1 .5-.5m-.75 6.25a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0M15 8a1 1 0 0 1-.292.705l-6.003 6.004a1 1 0 0 1-1.41 0l-6-6.004a1 1 0 0 1 0-1.41l6.003-6.004a1 1 0 0 1 1.41 0l6.003 6.004A1 1 0 0 1 15 8m-1 0L8 2 2 8l6 6z"/></svg>`
};
export const iconWarning24: {
  name: 'warning-24';
  data: string;
} = {
  name: 'warning-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 6.75a.75.75 0 0 1 .75.75v5.25a.75.75 0 1 1-1.5 0V7.5a.75.75 0 0 1 .75-.75m-1.125 9.375a1.125 1.125 0 1 0 2.25 0 1.125 1.125 0 0 0-2.25 0M22.5 12a1.48 1.48 0 0 1-.438 1.057l-9.005 9.006a1.5 1.5 0 0 1-2.114 0l-9-9.005a1.5 1.5 0 0 1 0-2.115l9.004-9.006a1.5 1.5 0 0 1 2.115 0l9.005 9.006A1.5 1.5 0 0 1 22.5 12M21 12l-9-9-9 9 9 9z"/></svg>`
};
export type DsIcon =
  | 'add-16'
  | 'arrow-elbow-down-right-24'
  | 'auftraege-36'
  | 'calendar-16'
  | 'calendar-blank-24'
  | 'caret-up-down-16'
  | 'check-16'
  | 'check-24'
  | 'check-list-16'
  | 'chevron-down-16'
  | 'chevron-down-24'
  | 'chevron-left-16'
  | 'chevron-left-24'
  | 'chevron-right-16'
  | 'chevron-right-24'
  | 'chevron-up-16'
  | 'chevron-up-24'
  | 'clipboard-36'
  | 'close-16'
  | 'close-24'
  | 'close-36'
  | 'close-circle-16'
  | 'close-circle-24'
  | 'delete-16'
  | 'delete-24'
  | 'document-add-16'
  | 'document-add-36'
  | 'download-24'
  | 'edit-16'
  | 'edit-24'
  | 'email-16'
  | 'error-16'
  | 'error-24'
  | 'external-24'
  | 'external-link-16'
  | 'faq-36'
  | 'info-16'
  | 'info-24'
  | 'list-checks-24'
  | 'logout-36'
  | 'maintenance-36'
  | 'menu-36'
  | 'phone-16'
  | 'reset-24'
  | 'save-16'
  | 'save-24'
  | 'search-16'
  | 'search-24'
  | 'shopping-bag-36'
  | 'shopping-cart-16'
  | 'shopping-cart-24'
  | 'shopping-cart-36'
  | 'sort-default-16'
  | 'success-16'
  | 'success-24'
  | 'success-56'
  | 'tag-line-36'
  | 'upload-36'
  | 'user-36'
  | 'warning-16'
  | 'warning-24';
export interface DsIconInterface {
  name: DsIcon;
  data: string;
}
export type DsIconInterfaceNameSubset<
  T extends Readonly<DsIconInterface[]>
> = T[number]['name'];
export const completeIconSet = [
  iconAdd16,
  iconArrowElbowDownRight24,
  iconAuftraege36,
  iconCalendar16,
  iconCalendarBlank24,
  iconCaretUpDown16,
  iconCheck16,
  iconCheck24,
  iconCheckList16,
  iconChevronDown16,
  iconChevronDown24,
  iconChevronLeft16,
  iconChevronLeft24,
  iconChevronRight16,
  iconChevronRight24,
  iconChevronUp16,
  iconChevronUp24,
  iconClipboard36,
  iconClose16,
  iconClose24,
  iconClose36,
  iconCloseCircle16,
  iconCloseCircle24,
  iconDelete16,
  iconDelete24,
  iconDocumentAdd16,
  iconDocumentAdd36,
  iconDownload24,
  iconEdit16,
  iconEdit24,
  iconEmail16,
  iconError16,
  iconError24,
  iconExternal24,
  iconExternalLink16,
  iconFaq36,
  iconInfo16,
  iconInfo24,
  iconListChecks24,
  iconLogout36,
  iconMaintenance36,
  iconMenu36,
  iconPhone16,
  iconReset24,
  iconSave16,
  iconSave24,
  iconSearch16,
  iconSearch24,
  iconShoppingBag36,
  iconShoppingCart16,
  iconShoppingCart24,
  iconShoppingCart36,
  iconSortDefault16,
  iconSuccess16,
  iconSuccess24,
  iconSuccess56,
  iconTagLine36,
  iconUpload36,
  iconUser36,
  iconWarning16,
  iconWarning24
];
