import { DcuplQuery } from '@dcupl/common';
import { DsFormValue, DsSelectOption } from '@ds-form';

export type DsTab<Key extends DsFormValue = DsFormValue> =
  DsSelectOption<Key> & {
    label: string;
    labelTotal?: number;
    disabled?: boolean;
    canDeactivate?: () => boolean;
  };

export type DsFilterTab<
  ValueType,
  TabType extends DsFormValue = string,
> = DsTab<TabType> & {
  queries: DcuplQuery[];
};
