<div class="relative z-20" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 transition-opacity duration-300 backdrop-brightness-50" [ngClass]="{ 'opacity-0 invisible': !isOpen }"></div>

  <div class="js-sidebar fixed top-0 h-screen bg-white pt-6 w-full xs:w-114 right-0 shadow-md z-10 transition-transform duration-300 ease-in-out flex flex-col" [ngClass]="{ 'translate-x-0': isOpen, 'translate-x-[120%]': !isOpen }">
    <div class="flex items-center justify-between pb-6 px-8">
      <h4 class="typo-h4" id="slide-over-title">
        <ng-content select="#headline"></ng-content>
      </h4>
      <button type="button" class="close-button" (click)="onCloseClick()">
        <ds-icon [size]="24" name="close-24"></ds-icon>
      </button>
    </div>
    <div class="overflow-y-auto grow">
      <ng-content></ng-content>
    </div>
  </div>
</div>
