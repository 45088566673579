import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { IconModule } from '@ds-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DsPipesModule } from '@ds-pipes';
import { FileErrorDialogComponent } from './file-error-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    IconModule,
    DsPipesModule,
  ],
  declarations: [FileErrorDialogComponent],
  exports: [FileErrorDialogComponent],
})
export class FileErrorDialogModule {}
