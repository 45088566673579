<div class="flex items-start">
  <label class="flex items-start text-black transition-colors duration-150" [ngClass]="{ 'cursor-pointer': !isReadonly }">
    <input *ngIf="!isReadonly" type="checkbox" class="h-4.5 w-4.5 mt-px cursor-pointer rounded text-green-500 outline-none border-gray-800 border focus:ring-transparent" [checked]="isChecked" (change)="onChange()" />
    <ds-icon *ngIf="isReadonly && isChecked" class="mt-1" name="check-16"></ds-icon>
    <ds-icon *ngIf="isReadonly && !isChecked" class="mt-1" name="close-16"></ds-icon>
    <span [innerHTML]="formField.innerLabel | translate" class="pl-2.5 pr-1 text-base text-gray-800" [ngClass]="getLabelClasses()"></span>
  </label>
  <ds-tooltip placement="auto" *ngIf="formField.tooltipLabel">
    <ng-container id="target">
      <ds-icon class="p-0.5 mt-px" name="info-16"></ds-icon>
    </ng-container>
    <div [innerHTML]="formField.tooltipLabel | translate"></div>
  </ds-tooltip>
</div>
