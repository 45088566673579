import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DsFormFieldExtComponent,
  DsFormFieldRadioGroup,
  DsFormValue,
  DsRadioOption,
} from '@ds-form';
import { getUniqueId } from '@ds-helpers';
import { DsIcon, DsRadioVariant } from '@ds-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<K extends string, OK extends DsFormValue>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) option!: DsRadioOption<OK>;
  @Input({ required: true }) groupId!: string;
  @Input({ required: true }) formField!: DsFormFieldRadioGroup<K, OK>;
  public icon?: DsIcon;
  public isDisabled = false;
  private variant!: DsRadioVariant;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = getUniqueId();
  }

  ngOnInit(): void {
    this.variant = this.formField.variant || 'default';
    this.icon = this.getIcon();
    this.listenForFormChange();
  }

  ngOnDestroy(): void {}

  private listenForFormChange(): void {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public isChecked(): boolean {
    return this.formControl.value === this.option.key;
  }

  public getRadioClasses(): string[] {
    const classes = [];

    if (this.variant === 'default') {
      classes.push('ds-radio-default');
    } else if (this.variant === 'chip') {
      classes.push('ds-radio-chip');
    } else if (this.variant === 'tile') {
      classes.push('ds-radio-tile');
    }

    if (this.isChecked()) {
      classes.push('is-active');
    }
    return classes;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(event: any): void {
    const value = event.target.value;

    if (value === 'true') {
      this.formControl.patchValue(true);
    } else if (value === 'false') {
      this.formControl.patchValue(false);
    } else {
      this.formControl.patchValue(value);
    }

    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  private getIcon(): DsIcon | undefined {
    // check if prop icon exists
    if ('icon' in this.option) {
      return this.option.icon;
    }
    return undefined;
  }
}
