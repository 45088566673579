<ds-dialog>
  <ng-container id="header">{{ 'order-form.form-deactivate-dialog.title' | translate }}</ng-container>

  <p class="text-gray-800">{{ 'order-form.form-deactivate-dialog.text' | translate }}</p>

  <div class="mt-4">
    <ds-infobox [variant]="'warning'" [size]="'S'">
      <p [innerHTML]="'order-form.form-deactivate-dialog.infobox' | translate"></p>
    </ds-infobox>
  </div>

  <ng-container id="footer">
    <div class="flex items-center justify-between flex-col-reverse sm:flex-row">
      <div>
        <button ds-button type="button" variant="link" (click)="onCancelClick()" [elastic]="true" [isDisabled]="status !== 'pending'">{{ 'order-form.form-deactivate-dialog.cancel' | translate }}</button>
      </div>
      <div>
        <button ds-button type="button" variant="outline" (click)="onSaveClick()" [elastic]="true" [isLoading]="status === 'saving'" [isDisabled]="status !== 'pending'" class="mt-2 sm:mt-0 sm:mr-4">
          <span *ngIf="data.orderType === 'order'">{{ 'order-form.form-deactivate-dialog.save-order' | translate }}</span>
          <span *ngIf="data.orderType === 'offer'">{{ 'order-form.form-deactivate-dialog.save-offer' | translate }}</span>
        </button>
        <button ds-button type="button" variant="flat" (click)="onDiscardClick()" [elastic]="true" [isDisabled]="status !== 'pending'">{{ 'order-form.form-deactivate-dialog.discard' | translate }}</button>
      </div>
    </div>
  </ng-container>
</ds-dialog>
