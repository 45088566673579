import { FormControl } from '@angular/forms';
import { DsArticleEdgeOption, DsBoardType } from '@ds-types';

export type DsSidebarCustomer = {
  type: 'customer';
};

export type DsSidebarMaterial = {
  type: 'material';
  materialFormControl: FormControl<string | undefined>;
};

export type DsSidebarMaterialInspection = {
  type: 'material-inspection';
};

export type DsSidebarEdgeTreatment = {
  type: 'edge-treatment';
  edgeTreatmentFormControl: FormControl<string | undefined>;
  boardType: DsBoardType;
};

export type DsSidebarWarehouseAddresses = {
  type: 'warehouse-addresses';
};

export type DsCalendar = {
  type: 'calendar';
  headlineLabel: string;
  date?: Date;
};

export type DsSidebar = { htmlId?: string } & (
  | DsSidebarCustomer
  | DsSidebarMaterial
  | DsSidebarMaterialInspection
  | DsSidebarEdgeTreatment
  | DsCalendar
  | DsSidebarWarehouseAddresses
);

export type DsSidebarType = DsSidebar['type'];

export type DsSidebarEdgeTreatmentData = {
  options: DsArticleEdgeOption[];
  boardType: DsBoardType;
};
