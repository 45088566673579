export type DsUser = {
  customerName: string;
  sales_RefNr: string;
  customerRefNr: string;
  firstname: string;
  lastname: string;
  type: DsUserType;
  username: string;
  warehouseAddress: string;
  warehouseCity: string;
  warehouseZip: string;
  warehouseCountry: string;
  warehouseEmailRetail: string;
  warehouseMobileRetail: string;
  warehouseTelRetail: string;
  isPriceAllowed: boolean;
  email: string;
  roles: DS_USER_ROLE[];
};

export type DsUserType = 'intern' | 'extern';

export enum DS_USER_ROLE {
  SHOW_PRICE = 'show:price',
  SHOW_OVERVIEW = 'show:overview',
  CREATE_ORDER = 'create:order',
  ORDERS_ALL = 'orders:all',
  ORDERS_MY = 'orders:my',
  MATERIAL_INSPECTION_LOCAL = 'material_inspection:local',
  MATERIAL_INSPECTION_IMAGE = 'material_inspection:image',
  SERVICE_DELIVERY_PICKUP = 'service:LASELBSTABHOLUNG',
  SERVICE_DELIVERY_CUSTOMER = 'service:LALIEFZEK',
  SERVICE_DELIVERY_CUSTOMER_MONTAGE = 'service:LAMONTAGE',
}

export type DsUserToken = {
  'ID Abas Kunde': string;
  'ID Abas Kundenkontakt': string;
  family_name: string;
  given_name: string;
  groups: string[];
  name: string;
  username: string;
  warehouse_address: string;
  warehouse_zip: string;
  warehouse_city: string;
  warehouse_country: string;
  warehouse_email_retail: string;
  warehouse_mobile_retail: string;
  warehouse_tel_retail: string;
  preis: boolean;
  email: string;
  resource_access: {
    'DigiSTONE-frontend': {
      roles: DS_USER_ROLE[];
    };
  };
};
