import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fileUploadValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const files = control.get('files');

  if (!files) {
    return null;
  }

  const filesCount = files.value?.length || 0;

  if (filesCount === 0) {
    files.setErrors({
      custom: 'order-form.step3.file-upload-step-error',
    });
  } else {
    files.setErrors(null);
  }

  return null;
};
