import { DsApiConverter, DsDateApi } from '@ds-types';
import { format } from 'date-fns';

export function formatDate(date: Date, formatString: string): string {
  return format(date, formatString);
}

export const dateConverter: DsApiConverter<DsDateApi, Date> = {
  toApi(date) {
    return formatDate(date, 'yyyy-MM-dd') as DsDateApi;
  },
  fromApi(date) {
    return new Date(date);
  },
};
