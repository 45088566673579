import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ds-ui/button';
import { DialogModule } from '@ds-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DeliveryInfoDialogComponent } from './delivery-info-dialog.component';

@NgModule({
  declarations: [DeliveryInfoDialogComponent],
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  exports: [DeliveryInfoDialogComponent],
})
export class DeliveryInfoDialogModule {}
