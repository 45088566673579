import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { neverError } from '@ds-helpers';
import { DsFile, DsFileError, DsFileErrorDialogInput } from '@ds-types';
import { uniq } from 'lodash-es';

@Component({
  selector: 'ds-file-error-dialog',
  templateUrl: './file-error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileErrorDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsFileErrorDialogInput,
    private dialogRef: MatDialogRef<DsFileErrorDialogInput>
  ) {}

  ngOnInit(): void {}

  public onContinueClick(): void {
    this.dialogRef.close();
  }

  public trackByFile(index: number, item: DsFile): string {
    return item.name;
  }

  public trackByFileError(index: number, item: DsFileError): string {
    return item;
  }

  public getVisibleFileErrors(): DsFileError[] {
    if (this.data.type !== 'files') {
      return [];
    }

    return uniq(this.data.files.map((file) => file.error));
  }

  public getFileErrorLabelKey(error: DsFileError): string {
    switch (error) {
      case 'duplicate':
        return 'order-form.step3.file-error-dialog.errors.file-duplicated';
      case 'fileSize':
        return 'order-form.step3.file-error-dialog.errors.file-too-large';
      case 'fileFormat':
        return 'order-form.step3.file-error-dialog.errors.wrong-file-format';
      case 'other':
        return 'order-form.step3.file-error-dialog.errors.other';
      default:
        throw neverError(error);
    }
  }

  public getFilesByError(error: DsFileError): DsFile[] {
    if (this.data.type !== 'files') {
      return [];
    }

    return this.data.files.filter((file) => file.error === error);
  }
}
