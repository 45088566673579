import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DsFormFieldExtComponent, DsFormFieldTextarea } from '@ds-form';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'ds-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent<K extends string>
  extends DsFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() formField?: DsFormFieldTextarea<K>;
  @Input() isDisabled = false;
  @Input() rows = 3;
  @Input() value = '';

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('textarea') textareaRef?: ElementRef<HTMLInputElement>;

  private subscriptions: Subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.subscriptions.add(
        this.formControl.valueChanges.subscribe((value) => {
          this.value = value;
        })
      );
    }

    this.value = this.formControl.value;

    this.subscriptions.add(
      this.formControl.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public onBlur(): void {
    this.formControl.markAsTouched();
  }

  public onChange(value: string): void {
    this.formControl.patchValue(value);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.valueChange.emit(value);
  }
}
