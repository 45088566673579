import { Injectable } from '@angular/core';
import {
  DS_TOAST_DURATION_DEFAULT,
  DsToastDuration,
  DsToastPayload,
} from '@ds-types';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  distinctUntilChanged,
  interval,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private activeToast$$ = new BehaviorSubject<DsToastPayload | null>(null);
  private timer$: Subscription | null = null;
  private duration: DsToastDuration = DS_TOAST_DURATION_DEFAULT;
  public isCloseBlocked: boolean = false;

  constructor() {}

  get activeToast$(): Observable<DsToastPayload | null> {
    return this.activeToast$$.asObservable().pipe(distinctUntilChanged());
  }

  private resetActiveToast(): void {
    this.activeToast$$.next(null);
    this.timer$?.unsubscribe();
    this.isCloseBlocked = false;
  }

  public showToastMessage(payload: DsToastPayload): void {
    if (this.activeToast$$.value !== null) {
      this.resetActiveToast();
    }

    setTimeout(() => {
      this.activeToast$$.next(payload);
      this.startTimer();
    }, 0);
  }

  public closeActiveToast(): void {
    this.resetActiveToast();
  }

  private startTimer(): void {
    this.timer$ = interval(1000).subscribe((iteration) => {
      const seconds = iteration + 1;

      if (!this.isCloseBlocked && seconds >= this.duration) {
        this.closeActiveToast();
      }
    });
  }
}
