import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DsFormCancelDialogInput, DsFormCancelDialogOutput } from '@ds-types';

@Component({
  selector: 'ds-form-cancel-dialog',
  templateUrl: './form-cancel-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCancelDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DsFormCancelDialogInput,
    private dialogRef: MatDialogRef<
      DsFormCancelDialogInput,
      DsFormCancelDialogOutput
    >
  ) {}

  ngOnInit(): void {}

  public onSaveDraftClick(): void {
    this.dialogRef.close({ action: 'save-draft' });
  }

  public onCancelWithoutSaveClick(): void {
    this.dialogRef.close({ action: 'cancel-without-save' });
  }
}
